import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import IcoHeadset from '../img/ico_duda_new.svg';
import IcoSignIn from '../img/ico_user_new.svg';
import IcoShield from '../img/ico_contratar_new.svg';
import IcoContact from '../img/ico_contacto_new.svg';
import IcoHorario from '../img/ico_horario_new.svg';
import ZurichLogo from '../img/logo-zurich_new.svg';
import TelefonicaLogo from '../img/logo-telefonica_new.png';
import FiveGLogo from '../img/logo-5g_purpura.png';
import IcoDiLogo from '../img/logo-icodi_new.png';

class Footer_new extends Component {

    render() {
        return (
            <footer id="footer">
                <section className="row vivo-links">
                    <div className="col-xs-12">
                        <div className="container">
                            <div className="row row-text">
                                <div className="col-xs-12">
                                    <h2>Links Rápidos</h2>
                                </div>
                            </div>
                            <div className="row vivo-links__lista">
                                <div className="col col-sm-4 col-xs-12">
                                    <a onClick={this.onClickComoUsar} style={{cursor: 'pointer'}}>
                                        <img src={IcoHeadset}/>
                                        <span>Como usar o seguro</span>
                                    </a>
                                </div>
                                <div className="col col-sm-4 col-xs-12">
                                    <a onClick={this.onClickGoTop} style={{cursor: 'pointer'}} className="login-show">
                                        <img src={IcoSignIn}/>
                                        <span>Acessar a área do cliente</span>
                                    </a>
                                </div>
                                <div className="col col-sm-4 col-xs-12">
                                    <a onClick={this.onClickContratation} style={{cursor: 'pointer'}}>
                                        <img src={IcoShield}/>
                                        <span>Contratar um seguro celular</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="row vivo-serveis">
                    <div className="col-xs-12">
                        <div className="container">
                            <div className="row vivo-serveis__lista">
                                <div className="col contacto col-lg-4 col-sm-6 col-xs-12">
                                    <div className="item">
                                        <div className="item-txt">
                                            <p>Serviço de atendimento<br/>
                                            ao segurado</p>
                                            <p><a href="tel:0800 580 0277" onClick={this.onClickPhoneNumber} target="_blank">0800 580 0277</a></p>
                                            <p>ou WhatsApp:<br/>
                                                <a href="https://api.whatsapp.com/send?phone=+5511972878201&text=Ol%C3%A1!%20Necessito%20de%20ajuda!" onClick={this.onClickMovilePhoneNumber} target="_blank">(11) 972878201</a>
                                            </p>
                                        </div>
                                        <div className="item-img">
                                            <img src={IcoContact}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col horario col-lg-4 col-sm-6 col-xs-12">
                                    <div className="item">
                                        <div className="item-txt">
                                            <p>Horário<br/>
                                            de atendimento</p>
                                            <p>Segunda à sexta<br/>
                                            das <strong>8h às 20h</strong> e<br/>
                                            sábado das <strong>8h às 15h</strong></p>
                                        </div>
                                        <div className="item-img">
                                            <img src={IcoHorario}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col zurich col-lg-4 col-sm-12 col-xs-12">
                                    <div className="item">
                                        <div className="item-txt">
                                            <p>Em parceria com</p>
                                        </div>
                                        <div className="item-img">
                                            <a href="https://www.zurich.com.br/pt-br" onClick={this.onClickLogoZurich} target="_blank"><img src={ZurichLogo}/></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="row vivo-legales">
                    <div className="col-xs-12">
                        <div className="container">
                            <div className="row row-text">
                                <div className="col-xs-12">
                                    <p>Este site utiliza cookies próprios e de terceiros para compilar informações sobre seus hábitos de navegação, e assim poder melhorar e personalizar sua experiência. Caso continue a navegar neste site, entendemos que está de acordo com a <a href=" https://www.vivo.com.br/a-vivo/informacoes-aos-clientes/centro-de-privacidade">Política de Privacidade</a>.</p>
                                    <p>Seguros Garantidos por Zurich Minas Brasil Seguros S/A - Código SUSEP da Seguradora: 05495 CNPJ 17.197.385/0001-21. As condições contratuais do Plano de Seguro encontram-se registradas na SUSEP sob os números 15414.005122/2011-69 (Roubo e Furto Qualificado), 15.414.005123-2011/11 (Danos Materiais). Fale Conosco: Ouvidoria 0800 770 1061 de segunda a sexta-feira, das 8h30 às 17h (exceto feriados) e SAC 24 horas: 0800 284 4848 e Deficiente Auditivo: 0800 275 8585. A comercialização do seguro é fiscalizada pela SUSEP-Superintendência Nacional de Seguros Privados Serviço de Atendimento ao Público SUSEP: 0800-0218484, site www.susep.gov.br . O registro deste plano na SUSEP não implica, por parte da Autarquia, incentivo ou recomendação a sua comercialização.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="row vivo-logos">
                    <div className="col-xs-12">
                        <div className="container">
                            <div className="row vivo-logos__lista">
                                <div className="col col-sm-4 col-xs-12">
                                    <img src={TelefonicaLogo}/>
                                </div>
                                <div className="col col-sm-4 col-xs-12">
                                    <img src={FiveGLogo}/>
                                </div>
                                <div className="col col-sm-4 col-xs-12">
                                    <img src={IcoDiLogo}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </footer>
        )
    }

    onClickComoUsar = () => {
        this.props.history.push('/como-usar-o-seguro');
        window.scrollTo(0,0);
        window.dataLayer.push ({
            'event' : 'asyncGTM',
            'GAcategory' : 'vivo:br:seguro movil',
            'GAaction' : 'elementos fijos:links rapidos:como usar o seguro',
            'GAlabel' :'vivo:br:seguro movil:'});
    }

    onClickGoTop = () => {
        window.scrollTo(0,0);
        window.dataLayer.push ({
            'event' : 'asyncGTM',
            'GAcategory' : 'vivo:br:seguro movil',
            'GAaction' : 'elementos fijos:links rapidos:acceso area cliente',
            'GAlabel' :'vivo:br:seguro movil:'});
        //this.props.activateHeaderTop();
    }

    onClickContratation = () =>{
        this.props.history.push('/Contratation');
        window.dataLayer.push ({
            'event' : 'asyncGTM',
            'GAcategory' : 'vivo:br:seguro movil',
            'GAaction' : 'elementos fijos:links rapidos:contratar seguro',
             'GAlabel' :'vivo:br:seguro movil:'});
    }
    
    onClickPhoneNumber = () =>{
        window.dataLayer.push ({
            'event' : 'asyncGTM',
            'GAcategory' : 'vivo:br:seguro movil',
            'GAaction' : 'elementos fijos:links rapidos:telefono',
            'GAlabel' :'vivo:br:seguro movil:'});
    }

    onClickMovilePhoneNumber = () =>{
        window.dataLayer.push ({
            'event' : 'asyncGTM',
            'GAcategory' : 'vivo:br:seguro movil',
            'GAaction' : 'elementos fijos:links rapidos:whatsapp',
            'GAlabel' :'vivo:br:seguro movil:'});
    }
    
    onClickLogoZurich = () =>{
        window.dataLayer.push ({
            'event' : 'asyncGTM',
            'GAcategory' : 'vivo:br:seguro movil',
            'GAaction' : 'elementos fijos:links rapidos:link zurich',
            'GAlabel' :'vivo:br:seguro movil:'});
    }
}

export default withRouter(Footer_new);