import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import IcoHeadset from '../img/ico-headset.svg';
import IcoSignIn from '../img/ico-sign-in.svg';
import IcoShield from '../img/ico-shield.svg';
//import IcoContact from '../img/ico-contact.svg';
import IcoJogueJunto from '../img/Logo_joguejunto_Horizontal-03.svg';
import IcoLogo5G from '../img/logo-5g-fibra-horizontal.png';
import IcoFooter1 from '../img/ico-footer-1.svg';
import IcoFooter2 from '../img/ico-footer-2.svg';
import IcoFooter3 from '../img/ico-footer-3.svg';
import ZurichSeguros from '../img/zurich-seguros.svg';
import LogoTelefonica from '../img/logo_insurance.svg';
import Foot2 from '../img/foot_2.png';

class Footer extends Component {

    render() {
        return (
            <div>
                <footer id="footer">
                <div className="container-fluid box-shadow-bottom">
                    <div className="container footer-main">

                        <div className="row row-equal-height d-block-mobile mb-30 pl-15 pr-15" >

                            <h4 className="robot-font">Links Rápidos</h4>

                        </div>

                        <div className="row row-equal-height ml-0 mr-0 d-block-mobile firstfooter-link-row">

                            <div className="col-sm-3 col-xs-6 mb-20-mobile firstfooter-link-col pl-0-mobile">

                                <a onClick={this.onClickComoUsar} className="firstfooter-link" style={{cursor: 'pointer'}}><img src={IcoHeadset} className="svg-inject firstfooter-link-ico" /> Como usar o seguro</a>

                            </div>

                            <div className="col-sm-3 col-xs-6 mb-20-mobile firstfooter-link-col pr-0-mobile">
                                <a onClick={this.onClickGoTop} className="firstfooter-link hidden-xs hidden-sm" style={{cursor: 'pointer'}}><img src={IcoSignIn} className="svg-inject firstfooter-link-ico" /> Acessar a área do cliente</a>
                                <a data-toggle="collapse" onClick={this.onClickGoTop} className="firstfooter-link hidden-md hidden-lg" style={{cursor: 'pointer'}}><img src={IcoSignIn} className="svg-inject firstfooter-link-ico" /> Acessar a área do cliente</a>

                            </div>

                            <div className="col-sm-3 col-xs-6 mb-20-mobile firstfooter-link-col pl-0-mobile">

                                <a onClick={this.onClickContratation} className="firstfooter-link" style={{cursor: 'pointer'}}><img src={IcoShield}  className="svg-inject firstfooter-link-ico" /> Contratar um seguro celular</a>

                            </div>

                           {/*{ <div className="col-sm-3 col-xs-6 mb-20-mobile firstfooter-link-col pr-0-mobile">

                                <a className="firstfooter-link"><img src={IcoContact} className="svg-inject firstfooter-link-ico firstfooter-link-ico-comment" /> Entrar em contato</a>

                            </div>*/}


                        </div>




                        <div className="row row-equal-height d-block-mobile gutter-row-15" >

                            <div className="col-sm-4 col-xs-12 col-footer mb-20-mobile" id="contato">
                                <div className="row row-equal-height ml-0 mr-0 mb-20 pb-20 borderbottomfooter">
                                    <div className="col-xs-4 ds-vertical-align pc-0">
                                        <img src={IcoFooter1} className="img-fluid img-center ico-footer" />
                                    </div>
                                    <div className="col-xs-8 ds-vertical-align pr-0">
                                        <h4 className="officina-font mb-0">Serviço de atendimento ao segurado</h4>
                                    </div>
                                </div>  
                                <div className="row">
                                    <div className="col-xs-12 pc-20">
                                        <p><a className="footer-links" href="tel:0800 580 0277" onClick={this.onClickPhoneNumber} target="_blank">0800 580 0277</a> ou</p>
                                        <p>WhatsApp: <a className="footer-links" href="https://api.whatsapp.com/send?phone=+5511972878201&text=Ol%C3%A1!%20Necessito%20de%20ajuda!" onClick={this.onClickMovilePhoneNumber} target="_blank">(11) 972878201</a></p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-4 col-xs-12 col-footer mb-20-mobile">
                                <div className="row row-equal-height ml-0 mr-0 mb-20 pb-20 borderbottomfooter">
                                    <div className="col-xs-4 ds-vertical-align pc-0">
                                        <img src={IcoFooter2} className="img-fluid img-center ico-footer" />
                                    </div>
                                    <div className="col-xs-8 ds-vertical-align pr-0">
                                        <h4 className="officina-font mb-0">Horário de atendimento</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-12 pc-20">
                                        <p>Segunda à sexta das <strong>8h às 20h</strong> e sábado das <strong>8h às 15h</strong></p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-4 col-xs-12 col-footer mb-20-mobile">
                                <div className="row row-equal-height ml-0 mr-0 mb-20 pb-20 borderbottomfooter">
                                    <div className="col-xs-4 ds-vertical-align pc-0">
                                        <img src={IcoFooter3} className="img-fluid img-center ico-footer" />
                                    </div>
                                    <div className="col-xs-8 ds-vertical-align pr-0">
                                        <h4 className="officina-font mb-0">Em parceria com</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-12 pc-40 ds-vertical-align">
                                        <a href="https://www.zurich.com.br/pt-br" onClick={this.onClickLogoZurich} target="_blank" className="link-zurich"><img src={ZurichSeguros} className="ico-telfins" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid bg-white pt-40 pb-40">

                    <div className=" footer-bottom" style={{ maxWidth:'123rem', marginLeft: 'auto', marginRight:'auto'}}>
                        <div className="row">
                            <p>Este site utiliza cookies próprios e de terceiros para compilar informações sobre seus hábitos de navegação, e assim poder melhorar e personalizar sua experiência. Caso continue a navegar neste site, entendemos que está de acordo com a <a href=" https://www.vivo.com.br/a-vivo/informacoes-aos-clientes/centro-de-privacidade"> <u>Política de Privacidade.</u> </a></p>
                            <p>Seguros Garantidos por Zurich Minas Brasil Seguros S/A - Código SUSEP da Seguradora: 05495 CNPJ 17.197.385/0001-21.
                                As condições contratuais do Plano de Seguro encontram-se registradas na SUSEP sob os números 15414.005122/2011-69 (Roubo e Furto Qualificado),
                                15.414.005123-2011/11 (Danos Materiais). Fale Conosco: Ouvidoria 0800 770 1061 de segunda a sexta-feira,
                                das 8h30 às 17h (exceto feriados) e SAC 24 horas: 0800 284 4848 e Deficiente Auditivo: 0800 275 8585.
                                A comercialização do seguro é fiscalizada pela SUSEP-Superintendência Nacional de Seguros Privados Serviço de Atendimento ao Público
                               SUSEP: 0800-0218484, site www.susep.gov.br . O registro deste plano na SUSEP não implica, por parte da Autarquia, incentivo ou recomendação a sua comercialização. </p>
                        </div>
                        <div className="col-md-12 mb-20-ipad footer__brands">

                                <div className="col-md-4 nopadding text-left footer__brands__item-1">
                                    <img src={LogoTelefonica} alt="Logo da Telefonica." className="logo-telefonica-footer" height='60px' length='350px' />
                                </div>

                                <div className="col-md-4 nopadding text-center footer__brands__item-2">
                                    <img src={IcoJogueJunto} alt="Logo da Vivo Jogue Junto." className="logo-vivatudo" height='60px' length='350px'/>
                                </div>

                                <div className="col-md-4 nopadding text-right footer__brands__item-3">
                                    <img src={IcoLogo5G} alt="Logo 4.5G + Fibra" className="logo-4g" height='60px' length='400px'/>
                                </div>
                            </div>
                        {/*window.screen.width >=760 ?
                        <div className="col-xl-12" style={{maxWidth:'123rem', display: 'flex', justifyContent: 'space-around', marginTop: '2rem', height:'auto', margin: 'auto'}}>
                            <img src={LogoTelefonica} className="logo-telefonica-footer" height='44px' length='300px' />
                            <img src={IcoJogueJunto} className="logo-vivatudo" height='44px' length='300px' style={{marginRight: '18%', marginLeft: '18%'}}/>
                            <img src={IcoLogo5G} className="logo-4g" height='44px' length='300px' />
                        </div> :
                        <div className="col-xl-12" style={{maxWidth:'123rem', display: 'flex', justifyContent: 'space-around', marginTop: '2rem', height:'auto', margin: 'auto'}}>
                            <img src={LogoTelefonica} className="logo-telefonica-footer" height='30px' length='250px' />
                            <img src={IcoJogueJunto} className="logo-vivatudo" height='30px' length='250px' style={{marginRight: '4%', marginLeft: '4%'}}/>
                            <img src={IcoLogo5G} className="logo-4g" height='30px' length='250px' />
                            
                        </div>*/}

                    
                    </div>

                </div>
                </footer>
            </div>
        )
    }

    onClickHome = () => {
        this.props.history.push('/');

    }

    onClickContratation = () =>{
        this.props.history.push('/Contratation');
        window.dataLayer.push ({
            'event' : 'asyncGTM',
            'GAcategory' : 'vivo:br:seguro movil',
            'GAaction' : 'elementos fijos:links rapidos:contratar seguro',
             'GAlabel' :'vivo:br:seguro movil:'});
    }

    onClickPlanos = () => {
        this.props.history.push('/planos');
    }
    onClickComoUsar = () => {
        this.props.history.push('/como-usar-o-seguro');
        window.dataLayer.push ({
            'event' : 'asyncGTM',
            'GAcategory' : 'vivo:br:seguro movil',
            'GAaction' : 'elementos fijos:links rapidos:como usar o seguro',
            'GAlabel' :'vivo:br:seguro movil:'});
    }
    onClickCondiciones = () => {
        this.props.history.push('/condicoes-gerais');
    }
    onClickPreguntas = () => {
        this.props.history.push('/preguntas-frequentes');
    }

    onClickGoTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        window.dataLayer.push ({
            'event' : 'asyncGTM',
            'GAcategory' : 'vivo:br:seguro movil',
            'GAaction' : 'elementos fijos:links rapidos:acceso area cliente',
            'GAlabel' :'vivo:br:seguro movil:'});
    }
    onClickLogoZurich = () =>{
        window.dataLayer.push ({
            'event' : 'asyncGTM',
            'GAcategory' : 'vivo:br:seguro movil',
            'GAaction' : 'elementos fijos:links rapidos:link zurich',
            'GAlabel' :'vivo:br:seguro movil:'});
    }
    
    onClickPhoneNumber = () =>{
        window.dataLayer.push ({
            'event' : 'asyncGTM',
            'GAcategory' : 'vivo:br:seguro movil',
            'GAaction' : 'elementos fijos:links rapidos:telefono',
            'GAlabel' :'vivo:br:seguro movil:'});
    }

    onClickMovilePhoneNumber = () =>{
        window.dataLayer.push ({
            'event' : 'asyncGTM',
            'GAcategory' : 'vivo:br:seguro movil',
            'GAaction' : 'elementos fijos:links rapidos:whatsapp',
            'GAlabel' :'vivo:br:seguro movil:'});
    }
}

export default withRouter(Footer);
