import React, { Component } from 'react';
import { Redirect} from 'react-router-dom';
import rpc, { rpcWithParams }  from './our/rpc';

export class EmailPageRedirect extends Component {
    componentDidMount(){
        this.props.onChangeOffer(3);
         const value = {
            dateOcurrence: new Date(),
            pageVisited: 'Llega por la parte de Email.',
            idOffert: 3
        };
        rpc({
            type: 'SaveContrationStepsHistory',
            value
        }).then((result) => {
            this.props.onChangeContratationStep(result);
            console.log(result);
        });
    }

    render() {
        return (
            <Redirect to="/Contratation" />

        )
    }
}

export default EmailPageRedirect
