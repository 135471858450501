import React, { Component, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect,withRouter } from 'react-router-dom';
//import Contratation from './contratation/Contratation.jsx';
import Header_new from './global/Header_new.jsx';
import Header_Essencial from './global/Header_Essencial.jsx';
import Header_redirect from './global/Header_redirect.jsx';
import Footer from './global/Footer_new.jsx';
import Footer_redirect from './global/Footer_redirect.jsx';
import FooterAC from './global/Footer.jsx';
import Home from './Home_new.jsx';
import Home_Essencial from './Home_Essencial.jsx';
import Home_redirect from './Home_redirect.jsx';
import ComoUsarOSeguro from './menu/ComoUsarOSeguro_new.jsx';
import CondicoesGerais from './menu/CondicoesGerais_new.jsx';
import PreguntasFrequentes from './menu/PreguntasFrequentes_new.jsx';
import planos from './menu/planos_new.jsx';
import Colaborador from './Colaborador_new.jsx';
import Voucher from './Voucher_new.jsx';
import SmsPageRedirect from './SmsPageRedirect.jsx';
import EmailPageRedirect from './EmailPageRedirect.jsx';
import ActivacaoChipRedirect from './ActivacaoChipRedirect.jsx';
import ContratationBannerRedirect from './ContratationBannerRedirect.jsx';
import AppDayRedirect from './AppDayRedirect.jsx';
import LollapaloozaRedirect from './LollapaloozaRedirect.jsx';
import Loading from './img/loading.svg';
import ClientArea from './areaDoCliente/ClientArea';
import rpc from './our/rpc';
import './App.css';
import './css/animate.css';
import './css/swiper.min.css';
import './fonts/officina/officina.css';
import './fonts/roboto/roboto.css';
import './fonts/vivo/vivo.css';
import ItauRedirect from './ItauVivoRedirect.jsx';
import TISContratationRedirect from './TISContratationRedirrect.jsx';
import { TISAPPPasarelaRedirect } from './TISAPPPasarelaRedirect.jsx';



const Contratation = React.lazy(() => import('./contratation/Contratation.jsx'));

class App extends Component {

  constructor(props) {
    super(props);
    this.state = { vivoPK: '', voucherCode: '', idOffer: undefined, pagamentoTarjeta: false, loadingIframe: true, idContratationStep: undefined ,loginPrivateArea:false, productTag: 'vivo:br:seguro movil:',
                  homeData: { deviceTypeId: undefined, brandId: undefined, brandName: undefined, modelId: undefined, modelExtendedName: undefined, productId: undefined}, loaded: false,
                  headerTopActive: false, headerBottomActive: false, tisContratationToken: undefined, tisContratationUser: undefined, tisContratationError: false };
  }

  /*componentDidMount() {
    if (localStorage.getItem('cookie') == "True") {
      //Area de Cliente
      require('./css/public.css');
      require('./css/bootstrap.min.css');
      require('./css/user.css');
    } else {
      //Web
      require('./css/global_new.css');
      require('./css/custom_new.css');
      require('./css/slick_new.css');
    }
    this.setState({loaded: true});
  }*/


  resizeIframe = () => {
    document.getElementById("iframe").style.height = '2048px';
    document.getElementById("iframe").style.width = '100%';
    this.setState({ loadingIframe: false });
  }


  render() {
    //console.log(document.styleSheets.length);
    const loadingLayer = <div className='modal-backdrop' style={{ backgroundColor: '#FFFFFF', opacity: '1', zIndex: 9999 }}><img src={Loading} style={{ position: 'fixed', top: '50%', left: '50%', zIndex: 9999 }}></img></div>;
    /*if (!(this.props.refWeb.current) && !(this.props.refWeb.current)) {
      return <div></div>;
    }*/
    return (
      <div>
        {this.state.pagamentoTarjeta ?
          <>  
            {this.state.loadingIframe && loadingLayer}
            <iframe src='' id='iframe' name='iframe' height="1px" width="1px" frameborder="0" onLoad={this.resizeIframe} />
          </>
          : <div className={"App"} style={{display: 'none'}}>
                {(this.state.loginPrivateArea || localStorage.getItem('cookie') == "True")? 
                  <div className="App">
                    <Suspense fallback={<div></div>}>
                        <Switch>
                              <Route path='/area-do-cliente' render={props => (
                                <ClientArea
                                  {...props}
                                  {...this.state}/>   
                              )} />
                              <Redirect to='/area-do-cliente/meus-seguros'/>
                        </Switch>
                    </Suspense>
                    <FooterAC/>
                  </div>
                     :
                        <div>
                {this.state.isEssencial ? 
                  <Header_redirect/>
                  :
                  !this.state.isAppPayment ? <Header_redirect/> : <></>}
                    <Suspense fallback={<div></div>}>
                      <Switch>
                      {/*<Route path='/Contratation' render={props => (
                        <Contratation
                          {...props}
                          {...this.state}
                          showPagamentoTarjeta={this.showPagamentoTarjeta}
                          onChangeOffer={this.onChangeOffer} 
                          onChangeClientData={this.onChangeClientData}
                          onChangeContratationStep={this.onChangeContratationStep}
                          useVoucher={this.useVoucherCode}
                          resetHomeData={this.resetHomeData}
                          homeData={this.state.homeData}
                          closeTisContratationError={this.closeTisContratationError} />
                      )} />
                      

                        <Route exact path="/como-usar-o-seguro" component={ComoUsarOSeguro} />
                        <Route exact path="/condicoes-gerais" component={Home} />
                        <Route path="/preguntas-frequentes" component={PreguntasFrequentes} />
                      <Route exact path="/planos" component={planos} /> */}
                        <Route exact path="/" render={props => (
                          <Home_redirect
                          />
                        )} />
                        {/*<Route exact path="/colaborador-vivo" render={props => (
                          <Colaborador
                            {...props}
                            {...this.state}
                            onChangeVivoPk={this.onChangeVivoPk}
                            isVivoEmployee={this.isVivoEmployee}
                          />
                        )} />
                        <Route exact path="/vivovaloriza" render={props => (
                          <Voucher
                            {...props}
                            {...this.state}
                            onChangeVoucherCode={this.onChangeVoucherCode}
                            isVoucherCode={this.isVoucherCode}
                          />
                        )} />
                        <Route exact path="/oferta-sms" render={props => (
                          <SmsPageRedirect
                            {...props}
                            {...this.state}
                            onChangeOffer={this.onChangeOffer}
                            onChangeContratationStep={this.onChangeContratationStep}
                          />
                        )} />
                        <Route exact path="/oferta-email" render={props => (
                          <EmailPageRedirect
                            {...props}
                            {...this.state}
                            onChangeOffer={this.onChangeOffer}
                            onChangeContratationStep={this.onChangeContratationStep}
                          />
                        )} />
                        <Route exact path="/ativacaochip" render={props => (
                          <ActivacaoChipRedirect
                            {...props}
                            {...this.state}
                            onChangeOffer={this.onChangeOffer}
                            onChangeContratationStep={this.onChangeContratationStep}
                          />
                        )} />
                        <Route exact path="/ofertabanner" render={props => (
                          <ContratationBannerRedirect
                            {...props}
                            {...this.state}
                            onChangeOffer={this.onChangeOffer}
                            onChangeContratationStep={this.onChangeContratationStep}
                          />
                        )} />
                        <Route exact path="/appday" render={props => (
                          <AppDayRedirect
                            {...props}
                            {...this.state}
                            onChangeOffer={this.onChangeOffer}
                            onChangeContratationStep={this.onChangeContratationStep}
                          />
                        )} />
                        <Route exact path="/lollapalooza" render={props => (
                          <LollapaloozaRedirect
                            {...props}
                            {...this.state}
                            onChangeOffer={this.onChangeOffer}
                            onChangeContratationStep={this.onChangeContratationStep}
                          />
                        )} />
                        <Route exact path="/itau" render={props => (
                          <ItauRedirect
                            {...props}
                            {...this.state}
                            onChangeOffer={this.onChangeOffer}
                            onChangeContratationStep={this.onChangeContratationStep}
                          />
                        )} />
                        <Route exact path="/tistoken" render={props => (
                          <TISContratationRedirect
                            {...props}
                            {...this.state}
                            setTISContratationData={this.setTISContratationData}
                            onChangeContratationStep={this.onChangeContratationStep}
                            openTisContratationError={this.openTisContratationError}
                          /
                        )} />
                        <Route exact path="/essencial" render={props => (
                          <Home_Essencial
                            {...props}
                            {...this.state}
                            onChangeOffer={this.onChangeOffer}
                            onChangeContratationStep={this.onChangeContratationStep}
                            homeData={this.state.homeData} setHomeData={this.onChangeHomeData}
                            isEssencial={this.isEssencial}
                          />
                        )} />*/}
                        <Route exact path="/apppayment" render={props => (
                          <TISAPPPasarelaRedirect
                            {...props}
                            isAppPayment={this.isAppPayment}
                          />
                        )} />
                        <Redirect to='/' />
                    </Switch>
                  </Suspense>
                  { !this.state.isAppPayment ? <Footer_redirect activateHeaderTop={this.activateHeaderTop}/> : <></>}
                </div>
                }
          </div>
        }
      </div>
    );
  }

  activateHeaderTop = () => {
    this.setState({headerTopActive: true});
    let bodyClass = document.body.className;
    if (!bodyClass.includes("fixed-body")) {
      document.body.className = bodyClass + " fixed-body";
    }
  }

  activateHeaderBottom = () => {
    this.setState({headerBottomActive: true});
    let bodyClass = document.body.className;
    if (!bodyClass.includes("fixed-body")) {
      document.body.className = bodyClass + " fixed-body";
    }
  }

  deactivateHeader = () => {
    this.setState({headerTopActive: false, headerBottomActive: false});
    let bodyClass = document.body.className;
    if (bodyClass.includes("fixed-body")) {
      document.body.className = bodyClass.replace("fixed-body", "");
    }
  }

  onChangeClientData = (userName, phoneNumber, CPF, id) =>{
    this.setState({userName, phoneNumber, CPF, id});
  }

  onChangeOffer = (e) => {

    this.setState({ idOffer: e });
    if (e === 7) {
      this.isAppDay();
    } else if (e === 8) {
      this.isItauVivo();
    }else if (e === 9) {
      this.isLollapalooza();
    }
  }

  onChangeContratationStep = (e) => {

    this.setState({ idContratationStep: e });
  }

  onChangeVivoPk = (e) => {

    this.setState({ vivoPK: e.target.value });
  }

  onChangeVoucherCode = (e) => {
    this.setState({ voucherCode: e.target.value });
  }

  setTISContratationData = (token, user) => {
    this.setState({ tisContratationToken: token, tisContratationUser: user });
  }

  showPagamentoTarjeta = () => {
    this.setState({ pagamentoTarjeta: true });
  }


  setloginPrivateArea= () => {
    this.setState({ loginPrivateArea: true },
    ()=> {
      for(let css of document.styleSheets) {
        css.disabled = true;
      }
      localStorage.setItem('cookie', 'True');
      this.props.history.push('/area-do-cliente/meus-seguros');
      window.location.reload();
    }); 
  }

  isVivoEmployee = () => {
    this.setState({ isVivoEmployee: true, isVoucherCode: false, isAppDay: false, isItau: false, isEssencial: false, isLollapalooza: false });
  }

  isVoucherCode = () => {
    this.setState({ isVoucherCode: true, isVivoEmployee: false, isAppDay: false, isItau: false, isEssencial: false, isLollapalooza: false });
  }

  isAppDay = () => {
    this.setState({ isAppDay: true, isVoucherCode: false, isVivoEmployee: false, isItau: false, isEssencial: false, isLollapalooza: false})
  }

  isLollapalooza = () => {
    this.setState({ isLollapalooza: true, isAppDay: false, isVoucherCode: false, isVivoEmployee: false, isItau: false, isEssencial: false})
  }


  isItauVivo = () => {
    this.setState({ isItau: true, isAppDay: false, isVoucherCode: false, isVivoEmployee: false, isEssencial: false, isLollapalooza: false});
  }

  isEssencial = () => {
    this.setState({ isEssencial: true, isAppDay: false, isVoucherCode: false, isVivoEmployee: false, isItau: false, isLollapalooza: false})
  }

  isAppPayment = () => { 
    this.setState({ isAppPayment: true});
  }

  useVoucherCode = () => {
    this.setState({ isVoucherCode: false, voucherCode: ''});
  }
  
  onChangeCPF = (e) => {

   this.setState({ CPF: e });
  }

  onChangePhoneNumber = (e) => {

    this.setState({ phoneNumber: e });
  }
  onChangeValueByName = (e) => {
        this.setState({ ...this.state, [e.target.name]: e.target.value });
  }
  onChangeHomeData = (value) => {
        this.setState({ ...this.state, homeData: value});
  }
  resetHomeData = () => {
        this.setState({ ...this.state, homeData: { deviceTypeId: undefined, brandId: undefined, brandName: undefined, modelId: undefined, modelExtendedName: undefined, productId: undefined}});
  }

  openTisContratationError = () => {
    this.setState({tisContratationError: true});
  }

  closeTisContratationError = () => {
    this.setState({tisContratationError: false});
  }

}
export default withRouter(App);
