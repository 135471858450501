import React, { Component } from 'react';
import { Redirect} from 'react-router-dom';
import rpc, { rpcWithParams }  from './our/rpc';

export class ItauRedirect extends Component {
    componentDidMount(){
        this.props.onChangeOffer(8);
        const value = {
        dateOcurrence: new Date(),
        pageVisited: 'Llega por la parte de Itau Vivo.',
        idOffert: 8
        };
        rpc({
            type: 'SaveContrationStepsHistory',
            value
        }).then((result) => {
            this.props.onChangeContratationStep(result);
            console.log(result);
        });
    }

    render() {
        return (
            <Redirect to="/Contratation" />

        )
    }
}

export default ItauRedirect