import React from 'react';
import ReactDOM from 'react-dom'

export default class RenderInBody extends React.Component {

    popup = document.createElement('div');

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.popup = document.createElement('div');
        document.body.appendChild(this.popup);
        this.renderLayer();
    }

    componentDidUpdate() {
        this.renderLayer();
    }

    componentWillUnmount() {
        ReactDOM.unmountComponentAtNode(this.popup);
        document.body.removeChild(this.popup);
    }

    render() {
        return null;
    }

    renderLayer = () => {
        ReactDOM.render(this.props.component, this.popup);
    }
}