import React, { Component } from 'react';
import { Redirect} from 'react-router-dom';
import rpc, { rpcWithParams }  from './our/rpc';

export class TISAPPPasarelaRedirect extends Component {
    componentDidMount(){
        this.props.isAppPayment();
        if (this.props.location.search !== '') {
            const queryString = this.props.location.search;
            console.log(queryString);
            var searchParams = new URLSearchParams(queryString);
            console.log(searchParams.get("token"));
            const token = searchParams.get("token");
            rpc({
                type: 'ValidateAppToken',
                token: token
            }).then((result) => {
                if (result != undefined && result != null && result.policyNumber != undefined && result.policyNumber != null) {
                    this.sendPayment(result.URL
                        , {
                            zeroDollar: true, isRetry: 'false', policyNumber: result.policyNumber, amount: result.amount, customerTrust: '02',
                            customerData: result.customerData, customerEmail: result.customerEmail, urlOK: result.commonUrl + 'correctPayment.jsp?token=' + token,
                            urlKO: result.commonUrl + 'failPayment.jsp?token=' + token
                        }, 'post', token);
                } else {
                    window.open(result.commonUrl + 'WS/TIS/FailedPayment');
                }
            });
        }
    }

    sendPayment = (path, params, method = 'post', tokken) => {
        rpc({
            type: 'InsertExternalPaymentMsgHistory',
            urlSubmitted: path,
            dataSubmitted: JSON.stringify(params),
            policyNumberSubmitted: params.policyNumber,
            tokenSubmitted: tokken
        }).then((response) => {

            const form = document.createElement('form');
            form.method = method;
            form.action = path;
            //form.target = 'iframe'; //se quita por que daba problemas para abrir la pasarela
    
            for (const key in params) {
                if (params.hasOwnProperty(key)) {
                    const hiddenField = document.createElement('input');
                    hiddenField.type = 'hidden';
                    hiddenField.name = key;
                    hiddenField.value = params[key];
    
                    form.appendChild(hiddenField);
                }
            }
    
            document.body.appendChild(form);
            form.submit();
        });

    }

    render() {
        return (
            <></>
        )
    }
}

export default TISAPPPasarelaRedirect