import React, { Component } from 'react';
import rpc from './our/rpc';
import BannerLogo from './img/logo_seguro.svg';
import EssencialBack from './img/back-desctados-escudo.png';
import BannerBack0 from './img/back-seguro-celular-0.jpg';
import BannerBack from './img/back-seguro-celular.jpg';
import LandingIco1 from './img/landing-ico-1.svg';
import LandingIco2 from './img/landing-ico-2.svg';
import LandingIco3 from './img/landing-ico-3.svg';
import LandingIco4 from './img/landing-ico-4.svg';
import IcoInfo from './img/ico_info_new.svg';
import RoboHurto from './img/robo-hurto_new.jpg';
import IcoReparo from './img/ico_reparo-oficial_new.svg';
import IcoPagamento from './img/ico_pagamento-facil_new.svg';
import IcoConexao from './img/ico_conexao_new.svg';
import IcoEntrega from './img/ico_entrega_new.svg';
import IcoFacilidade from './img/ico_facilidade_new.svg';
import Coste from './img/coste_new.jpg';
import Tranquilidad from './img/tranquilidad_new.jpg';
import IcoCompromiso from './img/ico_compromiso_new.svg';
import IcoReferencia from './img/ico_referencia_new.svg';
import IcoCobertura from './img/ico_cobertura_new.svg';
import BannerItaucard from './img/banner-itaucard_new.jpg';
import BannerVivopay from './img/banner-vivo-pay_new.png';
import VivoLogoWhite from './img/landing-logo-seguro-cellular.svg';
import Slider from 'react-slick';
import Tippy from '@tippyjs/react';
import DropdownSearch from './global/DropdownSearch';
import GenericPopup_new from './popUps/GenericPopUp_new.jsx';
import Loading from './img/loading.svg';

export class Home_Essencial extends Component {

    constructor(props) {
        super(props);
        this.state = {
          scrollBottom:0,
          deviceTypeId: undefined,
          brands: undefined,
          brandId: undefined,
          brandName: '',
          models: undefined,
          modelId: undefined,
          modelExtendedName: '',
          products: undefined,
          productId: undefined,
          primaMensual: undefined,
          priceDisable: true,
          loading: true
        };
        window.s = window.s || {};
        window.s.pageName = 'vivo:br:seguro movil:essential';
        window.s.products = 'vivo:br:seguro movil:';
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
        this.setState({scrollBottom: window.pageYOffset+window.innerHeight});
        window.dataLayer.push ({
            'event' : 'asyncGTM.step',
            'GAcategory' : 'vivo:br:seguro movil',
            'GAaction' : 'paso:01:home:acceso',
            'GAlabel' :'vivo:br:seguro movil:',
            'modalidadEspecial' :'essential'});
        let homeData = {
          deviceTypeId: this.state.deviceTypeId,
          brandId: this.state.brandId,
          brandName: this.state.brandName,
          modelId: this.state.modelId,
          modelExtendedName: this.state.modelExtendedName,
          productId: undefined
        };
        this.props.setHomeData(homeData);
        this.onChangeDeviceType(1);
        this.props.isEssencial();
    }

    handleScroll = () => {
        this.setState({scrollBottom: window.pageYOffset+window.innerHeight});
    }

    render() {
      const loadingLayer = this.state.loading ? <div className="modal-backdrop fade-in" style={{opacity: '0.4', zIndex: 9999}}><img src={Loading} style={{position: 'fixed', top: 'calc(50% - (64px / 2))', left: 'calc(50% - (64px / 2))', zIndex: 9999}}></img></div> : <></>;
        return (
            <main className="" id="customrenov">
              {this.state.essencialError && this.showEssencialError()}
              {loadingLayer}
              <section className="vivo-home-slider-promo__top landing">
                <Slider 
                    dots={true}
                    infinite={true}
                    speed={500}
                    slidesToShow={1}
                    slidesToScroll={1}
                    variableWidth={false}
                  >
                  {/*<div className="slide">
                    <div className="slide-content container">
                      <img className="landing-title-logo" src={VivoLogoWhite}/>
                      <p className="landing-title">Proteção que cabe no seu bolso</p>
                      <div className="vivo-home-cabecera__destacado landing">
                        <div className="vivo-home-cabecera__destacado__pastilla">Seguro celular por apenas</div>
                        <div className="vivo-home-cabecera__destacado__precio">
                          <div className="vivo-home-cabecera__destacado__precio_wrapper">
                            <p className="precio-1">R$</p>
                            <p className="precio-2">9,90</p>
                          </div>
                        </div>
                      </div>
                      <p class="slider-small">O valor máximo de indenização pelo seguro é de R$ 2.000,00</p>
                    </div>
                    <div className="slide-bg-img">
                      <img src={EssencialBack}/>
                    </div>
                  </div>*/}
                  <div className="slide">
                    <div className="slide-content container">
                      <img className="logo-slider" src={BannerLogo}/>
                      <p className="p-v2">Seu smartphone <strong className="link-color">sempre protegido.</strong></p>
                      <div className="vivo-home-cabecera__destacado vivo-home-cabecera__destacado-v2">                    
                        <div className="vivo-home-cabecera__destacado__precio_v2">
                          <span className="b1">
                            <div className="vivo-home-cabecera__destacado__pastilla">Por apenas</div>
                            <p className="precio-1">R$</p>
                          </span>
                          <span className="b2">
                            <p className="precio-2">9</p>
                          </span>
                          <span className="b3">
                            <p className="precio-2-1">,90</p>
                            <p className="precio-3">/mês</p>
                          </span>
                        </div>
                      </div>
                      <p className="small">O valor máximo de indenização pelo seguro é de R$ 2.000</p>
                    </div>
                    <div className="slide-bg-img">
                      <img src={BannerBack}/>
                    </div>
                  </div> 
                  <div className="slide">
                    <div className="slide-content container">
                      <img clasName="logo-slider" src={BannerLogo}/>
                      <p className="p-v2"><strong className="link-color">Procurando um plano mais completo?</strong><br/>Conheça nossas outras coberturas</p>
                    </div>
                    <div className="slide-bg-img">
                      <img src={BannerBack0}/>
                    </div>
                  </div> 
                </Slider>
              </section>
              
              <section className="row vivo-home-calculadora">
                <div className="col-xs-12">
                  <div className="container">
                    <div className="row row-text">
                      <div className="col-xs-12">
                        <h2>Quanto custa meu seguro?</h2>
                      </div>
                    </div>
                    <div className="row row-calculadora">
                      <div className="col">
                        <p>Marca</p>
                        <DropdownSearch
                          options={this.state.brands}
                          onChangeSearchWord={this.onChangeBrand}
                          searchWord={this.state.brandName}
                          disabled={this.state.deviceTypeId === undefined}
                          placeHolder="Selecione"
                          divClassName="dropdown-search-div-home"
                          inputClassName="dropdown-search-input-home"
                          listDivClassName="dropdown-search-list-div-home"
                          listItemClassName="dropdown-search-list-item-home"
                        />
                      </div>
                      <div className="col">
                        <p>Modelo</p>
                        <DropdownSearch
                          options={this.state.models}
                          onChangeSearchWord={this.onChangeModel}
                          searchWord={this.state.modelExtendedName}
                          disabled={this.state.models === undefined}
                          placeHolder="Selecione"
                          divClassName="dropdown-search-div-home"
                          inputClassName="dropdown-search-input-home"
                          listDivClassName="dropdown-search-list-div-home-model"
                          listItemClassName="dropdown-search-list-item-home"
                          optionId="idDevice"
                          optionName="modelExtendedName"
                        />
                      </div>
                      <div className="col">
                        <a id="clicktoggle" onClick={() => this.onClickCotar()} className="btn-vivo alt shadow">Cotar agora</a>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="row vivo-home-protecao">
                <div className="col-xs-12">
                  <div className="container">
                    <div className="row tipos-de-proteccion landing">
                      <div className="col-sm-3 col-xs-6">
                        <img src={LandingIco1}/>
                        <p>Apenas R$ 9,90 por mês</p>
                      </div>
                      <div className="col-sm-3 col-xs-6">
                        <img src={LandingIco2}/>
                        <p>Importância segurada até R$ 2000,00</p>
                      </div>
                      <div className="col-sm-3 col-xs-6">
                        <img src={LandingIco3}/>
                        <p>Franquia de 25%</p>
                      </div>
                      <div className="col-sm-3 col-xs-6">
                        <img src={LandingIco4}/>
                        <p>Vigência de 61 meses</p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              
              <section className="row vivo-interior-coberturas landing">
                <div className="col-xs-12">
                  <div className="container">
                    <div className="row row-text">
                      <div className="col-xs-12">
                        <h2>Seguro aparelho flexível</h2>
                        <p>Você escolhe a cobertura que precisa</p>
                      </div>
                    </div>
                    <div className="row tipos-de-coberturas">
                      <div className="col col-xs-12">
                        <div className="row tipos-de-coberturas-row-landing">
                          <div className="col-img col-lg-4 col-sm-5 col-xs-12">
                            <img src={RoboHurto}/>
                          </div>
                          <div className="col-lg-8 col-sm-7 col-xs-12 tipos-de-coberturas__info">
                            <p>Proteção até R$ 2.000,00 em caso de Roubo e Furto qualificado</p>
                            <div className="tipos-de-coberturas__info__precio">
                              <p>Por</p>
                              <div className="tipos-de-coberturas__info__precio__valoracion">
                                <p className="precio-1">R$</p>
                                <p className="precio-2">9</p>
                                <p className="precio-3">,90<br/>/Mês</p>
                              </div>
                              <a onClick={() => {this.onClickBannerContratation(1)}} className="btn-vivo shadow">Contratar</a>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </section>
                
                {/* MODIFICAR RESULTADOS DEL SEGURO */}
                <section id="slidetoggle" className="row vivo-home-calculadora-results" style={this.state.priceDisable ? {display: 'none'} : {}}>
                    <div className="col-xs-12">
                        <div className="container">
                            <div className="row row-calculadora-results">
                                <div className="col col-sm-4 col-xs-4">
                                    <Tippy content={<div className="row-calculadora-results__info__popup one"><p>Proteção completa em caso de:</p><ul className="check verde"><li>Roubo</li><li>Furto simples e qualificado</li></ul></div>} allowHTML={true} arrow={true} animation="shift-away" trigger="mouseenter click" animation="shift-toward" placement="bottom" maxWidth="none" interactive={true}>
                                      <div className="row-calculadora-results__info" id="popover-1">
                                          <h2>Roubo e Furto <span><img src={IcoInfo}/></span></h2>
                                          {/* <div className="row-calculadora-results__info__popup one">
                                              <p>Proteção completa em caso de:</p>
                                              <ul className="check verde">
                                              <li>Roubo</li>
                                              <li>Furto simples e qualificado</li>
                                              </ul>
                                          </div> */ }
                                      </div>
                                    </Tippy>
                                    <div className="row-calculadora-results__precio">
                                        <p className="precio-1">R$</p>
                                        <p className="precio-2">{this.state.primaMensual && this.state.primaMensual.robo.id && this.state.primaMensual.robo.prima[0]}</p>
                                        <p className="precio-3">,{this.state.primaMensual && this.state.primaMensual.robo.id && this.state.primaMensual.robo.prima[1]}<br/>/Mês</p>
                                    </div>
                                    <a onClick={() => {this.onClickContratation(2)}} className="btn-vivo shadow">Contrate Já</a>
                                </div>
                                <div className="col col-sm-4 col-xs-4">
                                  <Tippy content={<div className="row-calculadora-results__info__popup two"><p>Proteção completa em caso de:</p><ul className="check verde"><li>Quebra acidental</li><li>Imersão em líquidos</li><li>Danos elétricos</li></ul></div>} allowHTML={true} arrow={true} animation="shift-away" trigger="mouseenter click" animation="shift-toward" placement="bottom" maxWidth="none" interactive={true}>
                                      <div className="row-calculadora-results__info" id="popover-2">
                                          <h2>Danos acidentais <span><img src={IcoInfo}/></span></h2>
                                          { /*
                                          <div className="row-calculadora-results__info__popup two">
                                              <p>Proteção completa em caso de:</p>
                                              <ul className="check verde">
                                                  <li>Quebra acidental</li>
                                                  <li>Imersão em líquidos</li>
                                                  <li>Danos elétricos</li>
                                              </ul>
                                          </div>*/
                                          }
                                      </div>
                                    </Tippy>
                                    <div className="row-calculadora-results__precio">
                                        <p className="precio-1">R$</p>
                                        <p className="precio-2">{this.state.primaMensual && this.state.primaMensual.danos.id && this.state.primaMensual.danos.prima[0]}</p>
                                        <p className="precio-3">,{this.state.primaMensual && this.state.primaMensual.danos.id && this.state.primaMensual.danos.prima[1]}<br/>/Mês</p>
                                    </div>
                                    <a onClick={() => {this.onClickContratation(1)}} className="btn-vivo shadow">Contrate Já</a>
                                </div>
                                <div className="col col-sm-4 col-xs-4">
                                    <Tippy content={<div className="row-calculadora-results__info__popup three"><p>Proteção completa em caso de:</p><ul className="check verde"><li>Roubo</li><li>Furto simples e qualificado</li><li>Quebra acidental</li><li>Imersão em líquidos</li><li>Danos elétricos</li></ul></div>} allowHTML={true} arrow={true} animation="shift-away" trigger="mouseenter click" animation="shift-toward" placement="bottom" maxWidth="none" interactive={true}>
                                      <div className="row-calculadora-results__info" id="popover-3">
                                        <h2>Roubo e Furto + Danos <span><img src={IcoInfo}/></span></h2>
                                        {/*
                                        <div className="row-calculadora-results__info__popup three">
                                            <p>Proteção completa em caso de:</p>
                                            <ul className="check verde">
                                                <li>Roubo</li>
                                                <li>Furto simples e qualificado</li>
                                                <li>Quebra acidental</li>
                                                <li>Imersão em líquidos</li>
                                                <li>Danos elétricos</li>
                                            </ul>
                                        </div>
                                        */}
                                      </div>
                                    </Tippy>
                                    <div className="row-calculadora-results__precio">
                                        <p className="precio-1">R$</p>
                                        <p className="precio-2">{this.state.primaMensual && this.state.primaMensual.multi.id && this.state.primaMensual.multi.prima[0]}</p>
                                        <p className="precio-3">,{this.state.primaMensual && this.state.primaMensual.multi.id && this.state.primaMensual.multi.prima[1]}<br/>/Mês</p>
                                    </div>
                                    <a onClick={() => {this.onClickContratation(3)}} className="btn-vivo shadow">Contrate Já</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
                
                <section className="row vivo-home-porque">
                  <div className="col-xs-12">
                    <div className="container">
                      <div className="row row-text">
                        <div className="col-xs-12">
                          <h2>Por que contratar os seguros da Vivo?</h2>
                          <p>Sabemos o quanto o seu aparelho é especial e por isso a Vivo disponibiliza o melhor serviço para você.</p>
                        </div>
                      </div>
                      <div className="row vivo-home-porque__lista">
                        {/*<div className="col">
                          <img src={IcoReparo}/>
                          <h3>Reparo oficial</h3>
                          <p>Assistências autorizadas e peças originais.</p>
                        </div>*/}
                        <div className="col">
                          <img src={IcoPagamento}/>
                          <h3>Pagamento fácil</h3>
                          <p>Pague através da sua fatura Vivo.</p>
                        </div>
                        <div className="col">
                          <img src={IcoConexao}/>
                          <h3>Conexão Vivo</h3>
                          <p>Você terá toda atenção de um cliente Vivo.</p>
                        </div>
                        <div className="col">
                          <img src={IcoEntrega}/>
                          <h3>Entrega rápida</h3>
                          <p>Você escolhe o endereço e a entrega é por nossa conta.</p>
                        </div>
                        <div className="col">
                          <img src={IcoFacilidade}/>
                          <h3>Facilidade</h3>
                          <p>Acione seu seguro de forma rápida e digital.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="row vivo-home-coste">
                  <div className="col-xs-12">
                    <div className="container">
                      <div className="row row-text">
                        <div className="col-xs-12">
                          <h2>O custo de ficar sem aparelho</h2>
                        </div>
                      </div>
                      <div className="row row-coste">
                        <div className="col col-lg-4 col-sm-12 col-xs-12">
                          <ul className="check rojo">
                            <li>Ter que arcar com o valor integral de um novo aparelho</li>
                            {/*<li>Custo elevado com conserto</li>
                            <li>Reparos sem procedência ou garantia</li>*/}
                          </ul>
                        </div>
                        <div className="col col-lg-4 col-sm-12 col-xs-12">
                          <img src={Coste}/>
                        </div>
                        <div className="col col-lg-4 col-sm-12 col-xs-12">
                          <ul className="check rojo">
                            <li>Ficar desconectado de quem você gosta</li>
                            <li>Não ter acesso às redes sociais e aos seus principais aplicativos</li>
                            <li>Perder a chance de registrar aquele momento inesquecível</li>
                          </ul>
                        </div>
                      </div>
                      <div className="row row-barra">
                        <div className="col col-xs-12">
                          <p>Veja como o seguro pode ajudar você</p>
                          <a onClick={() => {this.onClickBannerContratation(4)}} className="btn-vivo alt shadow">Cotar agora</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="row vivo-home-tranquilidad">
                  <div className="col-xs-12">
                    <div className="container">
                      <div className="row row-text">
                        <div className="col-xs-12">
                          <h2>Use seu aparelho com tranquilidade e conte conosco em caso de imprevistos indesejáveis!</h2>
                        </div>
                      </div>
                      <div className="row row-tranquilidad">
                        <div className="col col-sm-5 col-xs-12">
                          <img src={Tranquilidad}/>
                        </div>
                        <div className="col col-sm-7 col-xs-12">
                          <p>Dicas de Segurança</p>
                          <ul className="check verde">
                            <li>Sempre ative a tela de bloqueio do seu celular</li>
                            <li>Ative também a autenticação em 2 fatores, sempre que possível</li>
                            <li>Use senhas alfanuméricas e variadas. Usar um gerenciador de senhas é uma ótima opção</li>
                            <li>Use redes públicas de wi-fi em último caso</li>
                            <li>Nunca clique em links suspeitos, cuja origem é desconhecida</li>
                            <li>Contrate o Seguro da Vivo que protege seus dispositivos contra imprevistos!</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="row vivo-home-escoger">
                  <div className="col-xs-12">
                    <div className="container">
                      <div className="row row-text">
                        <div className="col-xs-12">
                          <h2>Por que escolher os seguros da Vivo?</h2>
                          <p>A Zurich tem parceria com a Vivo desde 2012 para a comercialização do Seguro Celular em todo o Brasil.</p>
                        </div>
                      </div>
                      <div className="row vivo-home-escoger__lista">
                        <div className="col col-sm-4 col-xs-12">
                          <img src={IcoCompromiso}/>
                          <p>Compromisso com a satisfação dos nossos clientes</p>
                        </div>
                        <div className="col col-sm-4 col-xs-12">
                          <img src={IcoReferencia}/>
                          <p>Referência no setor de seguros de tecnologia</p>
                        </div>
                        <div className="col col-sm-4 col-xs-12">
                          <img src={IcoCobertura}/>
                          <p>A melhor cobertura do mercado</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="row vivo-home-slider-promo">
                  <div className="col-xs-12">
                    <div className="container">
                      <div className="row row-text">
                        <div className="col-xs-12">
                          <h2>A Vivo também pode te ajudar com outros serviços:</h2>
                        </div>
                      </div>
                      <div className="vivo-home-slider-promo__slider">
                        <Slider 
                          dots={true}
                          infinite={true}
                          speed={500}
                          slidesToShow={1}
                          slidesToScroll={1}
                        >
                          <div className="slide">
                            <div className="slide-content">
                            <p className="title">Cartão Vivo Itaú</p>
                              <p>parcelamento em 18x e até 10% de cashback para vocé</p>
                              <a className="btn-vivo" href="https://www.itau.com.br/cartoes/escolha/formulario/vivo-itaucard-cashback-visa-platinum.html?utm_source=vivo&utm_medium=parceria&utm_campaign=portalseguros" target="_blank">PEÇA JÁ O SEU</a>
                              <p class="small">*Para compras a partir de R$ 1.500.000</p>
                            </div>
                            <div className="slide-bg-img">
                              <img src={BannerItaucard}/>
                            </div>
                          </div>
                          {/*<div className="slide">
                            <div className="slide-content">
                              <p className="title">Vivo Pay</p>
                              <p>A conta digital que te dá bônus de internet</p>
                              <a className="btn-vivo" href="https://www.vivo.com.br/para-voce/produtos-e-servicos/ofertas/vivo-pay?utm_source=seguro-celular&utm_medium=LP&utm_campaign=lp-seguros-vivo-pay" target="_blank">SAIBA MAIS</a>
                            </div>
                            <div className="slide-bg-img">
                              <img src={BannerVivopay}/>
                            </div>
                          </div>*/}
                        </Slider>
                      </div>
                    </div>
                  </div>
                </section>
            </main>
        );
    }

    onChangeValueByName = (e) => {
        this.setState({ ...this.state, [e.target.name]: e.target.value });
    }

    onClickBannerContratation = (origen) => {
      let gaLabel = 'vivo:br:seguro movil:';
      if (this.state.brandId !== undefined) {
        gaLabel = gaLabel + this.state.brandName;
      }
      if (this.state.modelId !== undefined) {
        gaLabel = gaLabel + ' ' + this.state.modelExtendedName;
      }
      switch(origen){
          case 1:
              window.dataLayer.push ({
                  'event' : 'asyncGTM',
                  'GAcategory' : 'vivo:br:seguro movil',
                  'GAaction' : 'home:boton ir a calcular',
                  'GAlabel' :gaLabel,
                  'localizacion' : 'banner planes:roubo e furto qualificado'});
              break;

          case 2:
              window.dataLayer.push ({
                'event' : 'asyncGTM',
                'GAcategory' : 'vivo:br:seguro movil',
                'GAaction' : 'home:boton ir a calcular',
                'GAlabel' :gaLabel,
                'localizacion' : 'danos acidentais'});
              break;
          case 3:
              window.dataLayer.push ({
                'event' : 'asyncGTM',
                'GAcategory' : 'vivo:br:seguro movil',
                'GAaction' : 'home:boton ir a calcular',
                'GAlabel' :gaLabel,
                'localizacion' : 'banner planes:roubo e furto mas danos'});
              break;
          case 4:
              window.dataLayer.push ({
                  'event' : 'asyncGTM',
                  'GAcategory' : 'vivo:br:seguro movil',
                  'GAaction' : 'home:boton ir a calcular',
                  'GAlabel' :gaLabel,
                  'localizacion' : 'banner gasto no tener seguro'});
              break;
          default: 
          break;
      }

      this.props.history.push('/Contratation/');

    }

    onClickContratation = (origen) =>{
      let homeData = {
        deviceTypeId: this.state.deviceTypeId,
        brandId: this.state.brandId,
        brandName: this.state.brandName,
        modelId: this.state.modelId,
        modelExtendedName: this.state.modelExtendedName,
        productId: undefined
      };
        switch(origen){
            case 1:
                window.dataLayer.push ({
                    'event' : 'asyncGTM',
                    'GAcategory' : 'vivo:br:seguro movil',
                    'GAaction' : 'paso:02:contratacion:calculo:04:coberturas',
                    'GAlabel' :'vivo:br:seguro movil:' + homeData.brandName + ' ' + homeData.modelExtendedName,
                    'modalidad' : 'danos acidentais',
                    'prima' : this.state.primaMensual.danos.prima[0] + ',' + this.state.primaMensual.danos.prima[1]
                  });
                    homeData.productId = this.state.primaMensual !== undefined && this.state.primaMensual.danos !== undefined ? this.state.primaMensual.danos.id : undefined;
                break;

            case 2:
                window.dataLayer.push ({
                    'event' : 'asyncGTM',
                    'GAcategory' : 'vivo:br:seguro movil',
                    'GAaction' : 'paso:02:contratacion:calculo:04:coberturas',
                    'GAlabel' :'vivo:br:seguro movil:' + homeData.brandName + ' ' + homeData.modelExtendedName,
                    'modalidad' : 'roubo e furto',
                    'prima' : this.state.primaMensual.robo.prima[0] + ',' + this.state.primaMensual.robo.prima[1]
                  });
                homeData.productId = this.state.primaMensual !== undefined && this.state.primaMensual.robo !== undefined ? this.state.primaMensual.robo.id : undefined;
                break;
            case 3:
                window.dataLayer.push ({
                    'event' : 'asyncGTM',
                    'GAcategory' : 'vivo:br:seguro movil',
                    'GAaction' : 'paso:02:contratacion:calculo:04:coberturas',
                    'GAlabel' :'vivo:br:seguro movil:' + homeData.brandName + ' ' + homeData.modelExtendedName,
                    'modalidad' : 'roubo e furto + danos',
                    'prima' : this.state.primaMensual.multi.prima[0] + ',' + this.state.primaMensual.multi.prima[1]
                  });
                homeData.productId = this.state.primaMensual !== undefined && this.state.primaMensual.multi !== undefined ? this.state.primaMensual.multi.id : undefined;
                break;
            default: 
            break;
        }

        this.props.setHomeData(homeData);
        this.props.history.push('/Contratation/');
        
    }

    onChangeDeviceType = (e) =>{
      const idSubtype = e;
      const subtypeText = idSubtype == 1 ? 'celular' :
                          idSubtype == 2 ? 'tablet' :
                          idSubtype == 3 ? 'smartwatch' : 
                          idSubtype == 4 ? 'notebook' : 'fone de ouvido';
      if (idSubtype !== undefined && idSubtype !== '') {
          this.setState({ primaMensual: undefined, products: undefined, productId: undefined, brands: undefined, brandId: undefined, brandName: '', models: undefined, modelId: undefined, modelExtendedName: '', priceDisable: true });
          rpc({
              type: 'GetAllBrandDeviceWithModelCurrentlySoldByIdSubtype',
              idSubtype
          }).then((brands) => {
              if (brands !== undefined && brands !== null) {
                  this.setState({ deviceTypeId: idSubtype, brands, loading: false });
                  window.dataLayer.push({
                    'event': 'asyncGTM',
                    'GAcategory': 'vivo:br:seguro movil',
                    'GAaction': 'paso:02:contratacion:calculo:01.5:dispositivo',
                    'GAlabel': 'vivo:br:seguro celular:',
                    'dispositivo': subtypeText
                  });
              }
          });
      }
    }

    onChangeBrand = (e) => {
      let brandName = undefined;
      if (e.target.value !== undefined) {
          brandName = e.target.value.toUpperCase();
      }

      const brand = this.state.brands.find((brand) => { return brand.name.toUpperCase() === brandName });
      let brandId = undefined;
      if (brand !== undefined) {
          brandId = brand.id;
      } else {
          this.setState({ brandName, brandId: undefined, models: undefined, modelId: undefined, modelExtendedName: '', priceDisable: true})
      }
      //const brandId = e.target.value;

      if (brandId !== undefined && brandId !== '') {
        this.setState({ primaMensual: undefined, modelId: undefined, priceDisable: true });
          rpc({
              type: 'GetModelDevicesByBrandCatalogIdAndIdSubtype',
              idBrand: brandId,
              idSubtype: this.state.deviceTypeId,
          }).then((devices) => {
              this.setState({ brandName, brandId, models: devices });
              window.dataLayer.push({
                'event': 'asyncGTM',
                'GAcategory': 'vivo:br:seguro movil',
                'GAaction': 'paso:02:contratacion:calculo:02:marca',
                'GAlabel': 'vivo:br:seguro celular:' + brandName,
                'marca': brandName
              });
          });
      }
    }

    onChangeModel = (e) => {

      let modelExtendedName = undefined;
      if (e.target.value !== undefined) {
          modelExtendedName = e.target.value.toUpperCase();
      }

      const device = this.state.models.find((device) => { return device.modelExtendedName.toUpperCase() === modelExtendedName });
      let modelId = undefined;
      if (device !== undefined) {
          modelId = device.idDevice;
      } else {
          this.setState({ modelExtendedName, modelId: undefined, productId: undefined, priceDisable: true })
      }

      if (modelId === undefined)
          return;

      /*if (device.actualPrice > 2000) {
        this.setState({ essencialError: true, modelExtendedName: undefined })
        return;
      }*/
      //const modelId = e.target.value;

      //if (modelId === undefined || modelId === '')
        //return;

      this.setState({ primaMensual: undefined, priceDisable: true });

      let primas = {danos: {prima: undefined, id: undefined}, robo: {prima: undefined, id: undefined}, multi: {prima: undefined, id: undefined}};

      rpc({
          type: 'GetListProductsPortalOSCPPublicByIdSubtype',
          idSubtype: this.state.deviceTypeId,
          productType: 3
      }).then((products) => {
          products.map(product => {
            rpc({
              type: 'SelectBandByidDeviceAndidProduct',
              idDevice: modelId,
              idProduct: product.id,
              idSubtype: this.state.deviceTypeId,
              isItau: 0
            }).then(bandResult => {
                let prima = bandResult.band.primaMensual;
                prima = parseFloat(prima).toFixed(2);
                let dividePrice = prima.toString().split(".");
                if (dividePrice.length === 1) {
                    dividePrice.push("00");
                }
                primas.robo.prima = dividePrice;
                primas.robo.id = product.id;
            })
          });
          this.setState({ modelExtendedName, primaMensual: primas, modelId });
          window.dataLayer.push({
            'event': 'asyncGTM',
            'GAcategory': 'vivo:br:seguro movil',
            'GAaction': 'paso:02:contratacion:calculo:03:modelo',
            'GAlabel': 'vivo:br:seguro celular:' + this.state.brandName + ' ' + modelExtendedName,
            'modelo': modelExtendedName,
            'precioAparelho': device.actualPrice
          });
            
      });
  }

  onClickCotar() {
    let priceDisable = this.state.primaMensual === undefined || this.state.primaMensual.robo.id === undefined;
    this.setState({priceDisable});
    if (!priceDisable) {
      window.dataLayer.push({
        'event': 'asyncGTM',
        'GAcategory': 'vivo:br:seguro movil',
        'GAaction': 'home:boton calcular',
        'GAlabel': 'vivo:br:seguro celular:' + this.state.brandName + ' ' + this.state.modelExtendedName,
        'localizacion': 'banner principal'
      }); 
      let primas = {
        robo: new Number(this.state.primaMensual.robo.prima[0] + ',' + this.state.primaMensual.robo.prima[1])
      }
      let prima = undefined;
      prima = primas.robo;
      window.dataLayer.push({
        'event': 'asyncGTM',
        'GAcategory': 'vivo:br:seguro movil',
        'GAaction': 'paso:02:contratacion:calculo:04.5:visualización prima',
        'GAlabel': 'vivo:br:seguro celular:' + this.state.brandName + ' ' + this.state.modelExtendedName,
        'prima': prima
      }); 
    }
    this.onClickContratation(2);
  }

  closeEssencialError = () => {
    this.setState({essencialError: false});
  }

  showEssencialError() {
    return <GenericPopup_new close={this.closeEssencialError} 
        title='Alerta!'
        message='Vivo Essencial não se aplica a dispositivos com valor superior a R$ 2000'/>;
  }
}

export default Home_Essencial;