import React, { Component } from 'react'
import rpc from '../our/rpc';
import moment from 'moment';

import '../fonts/vivo/vivo.css';
import '../fonts/officina/officina.css';
import '../fonts/roboto/roboto.css';
import '../fonts/font-awesome/css/font-awesome.min.css';
import GenericPopup from '../popUps/GenericPopUp';

export class MeusDados extends Component {

    constructor(props) {
        super(props);
        this.state = {errorDataPopUp: false};
    }

    componentDidMount() {

        rpc({
            type: 'AreaPrivadaSelectClient'
        }).then((result) => {
            var name = result.client.name;
            const surname = result.client.surname;
            if(surname!==undefined && surname!==''){
                name = name + ' ' + surname;
            }
            this.setState({
                name,
                document: result.client.document,
                birthDate: moment(new Date(result.client.birthdate)).format("YYYY-MM-DD"),
                email: result.client.emailAddress,
                phoneNumber1: result.client.phoneNumber1,
                phoneNumber2: result.client.phoneNumber2,
                CEP: result.client.addressPc,
                addressStreet: result.client.addressStreet,
                addressCity: result.client.addressCity,
                addressComplement: result.client.addressComplement,
                addressProvince: result.client.addressProvince,
                addressNumber: result.client.addressNumber,
                addressDistrict: result.client.district
            });
            rpc({
                type: 'SelectCitys',
                provincia: result.client.addressProvince,
            }).then((result) => {
                this.setState({ citys: result });
            });
        });
        rpc({
            type: 'SelectAllProvinces'
        }).then((result) => {
            this.setState({ provinces: result });
        });




    }

    render() {


        return (<main className="container content nopadding" id="customRenovAP">

            <div id="divIdentificacao" className="container">
                <div className="col-xs-12" style={{ marginTop: "20px" }}>
                    <div className="resumo resumo-hr">
                        <div className="usuario-resumo">
                            Olá: <span id="lblNomeUsuarioLogado">{this.state.name ? this.state.name : ""}</span>
                        </div>
                        <div className="numero-resumo">
                            Linha:<span id="lblTelefoneUsuarioLogado">{this.state.phoneNumber1 ? this.state.phoneNumber1 : ""}</span>
                        </div>
                        <div className="plano-resumo">
                            Produto:<span id="lblDescricaoProduto">{this.props.productName ? this.props.productName : "Unknown"}</span>
                        </div>
                        <form action="" method="POST" name="logOut">
                            <div className="pull-right pull-center-mobile">
                                <a href="logout" title="Sair" id="buttonExitAP" className="btn btn-block btn-roxo" onClick={this.props.logout}> Sair </a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>


            {this.state.error && this.showError()}
            {this.state.errorDataPopUp===true && this.showErrorData()}
            {this.state.correctChange && this.showCorrect()}

            <div className="row container nopadding">
                <div className="col-md-8 mb-20">
                    <label>Nome *</label>
                    <input name="name" value={this.state.name ? this.state.name : ""} onChange={this.onChangeValueByName} type="text" id="ContentPlaceHolder1_txtNome" className="form-control" tabIndex="0"/>
                </div>
            </div>
            <div className="row container nopadding">
                <div className="col-md-4 mb-20">
                    <label>CPF *</label>
                    <input name="document" value={this.state.document ? this.state.document : ""} onChange={this.onChangeValueByName} type="text" maxLength="11" id="ContentPlaceHolder1_txtCPF" className="form-control" tabIndex="0" />
                </div>
                <div className="col-md-4 mb-20">
                    <label>RG</label>
                    <input name="document2" value={this.state.document2 ? this.state.document2 : ""} onChange={this.onChangeValueByName} type="text" maxLength="11" id="ContentPlaceHolder1_txtCPF" className="form-control" tabIndex="0" />
                </div>

                <div className="col-md-4 mb-20">
                    <label>Data de nascimento *</label>
                    <input name="birthDate" value={this.state.birthDate ? this.state.birthDate : ""} onChange={this.onChangeValueByName} type="date" id="ContentPlaceHolder1_txtDtaNasc" className="form-control" tabIndex="0" />
                </div>
            </div>

            <div className="row container nopadding">
                <div className="col-md-4 mb-20">
                    <label>E-mail *</label>
                    <input name="email" value={this.state.email ? this.state.email : ""} onChange={this.onChangeValueByName} type="text" id="ContentPlaceHolder1_txtEmail" className="form-control" tabIndex="0" />
                </div>

                <div className="col-md-4 mb-20">
                    <label>Tel. residencial * </label>
                    <input name="phoneNumber1" value={this.state.phoneNumber1 ? this.state.phoneNumber1 : ""} onChange={this.onChangeValueByName} type="text" id="ContentPlaceHolder1_txtTelRes" className="form-control" tabIndex="0" maxLength="13" />
                </div>

                <div className="col-md-4 mb-20">
                    <label>Tel. comercial</label>
                    <input name="phoneNumber2" value={this.state.phoneNumber2 ? this.state.phoneNumber2 : ""} onChange={this.onChangeValueByName} type="text" id="ContentPlaceHolder1_txtTelCom" className="form-control" tabIndex="0" maxLength="13" />
                </div>
            </div>

            <div className="row container nopadding">
                <div className="col-md-4 mb-20">
                    <label>CEP *</label>
                    <input name="CEP" value={this.state.CEP ? this.state.CEP : ""} onChange={this.buscarCEP} type="text" maxLength="9" id="ContentPlaceHolder1_txtCEP" className="form-control" tabIndex="0" />
                </div>
            </div>

            <div className="row container nopadding">
                <div className="col-md-5 mb-20">
                    <label>Logradouro *</label>
                    <input name="addressStreet" value={this.state.addressStreet ? this.state.addressStreet : ""} onChange={this.onChangeValueByName} type="text" id="ContentPlaceHolder1_txtLogradouro" className="form-control" tabIndex="0" />
                </div>

                <div className="col-md-2 mb-20">
                    <label>Número *</label>
                    <input name="addressNumber" value={this.state.addressNumber ? this.state.addressNumber : ""} onChange={this.onChangeValueByName} type="text" id="ContentPlaceHolder1_txtNumero" className="form-control" tabIndex="0" />
                </div>

                <div className="col-md-5 mb-20">
                    <label>Complemento</label>
                    <input name="addressComplement" value={this.state.addressComplement ? this.state.addressComplement : ""} onChange={this.onChangeValueByName} type="text" id="ContentPlaceHolder1_txtComplemento" className="form-control" tabIndex="0" />
                </div>
            </div>

            <div className="row container nopadding">
                <div className="col-md-4 mb-20">
                    <label>Bairro *</label>
                    <input name="addressDistrict" placeholder='Coloque seu bairro' value={this.state.addressDistrict ? this.state.addressDistrict : ""} onChange={this.onChangeValueByName} type="text" id="ContentPlaceHolder1_txtBairro" className="form-control" tabIndex="0" />
                </div>

                <div className="col-md-4 mb-20">
                    <label>Estado *</label>
                    <select name="addressProvince" value={this.state.addressProvince ? this.state.addressProvince : ""} onChange={this.onChangeProvince} id="ContentPlaceHolder1_ddlEstado" className="form-control" tabIndex="0">
                        {this.state.provinces && this.state.provinces.map(province => (
                            <option key={province.id} value={province.name}>
                                {province.name}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="col-md-4 mb-20">
                    <label>Cidade *</label>
                    <select name="addressCity" value={this.state.addressCity ? this.state.addressCity : ""} onChange={this.onChangeValueByName} id="ContentPlaceHolder1_ddlCidade" className="form-control" tabIndex="0">
                        {this.state.citys && this.state.citys.map(city => (
                            <option key={city.ciudad} value={city.ciudad}>
                                {city.ciudad}
                                               </option>
                        ))}
                    </select>
                </div>
                <div className="row mt-20 mt-20-mobile mt-20-ipad">
                    <div className="col-md-12">
                        <div className="form-group">
                            <input type="button" name="ctl00$ContentPlaceHolder1$btnVoltar" value="Voltar"  onClick={() => this.props.history.push('/area-do-cliente/meus-seguros')} className="btn btn-amarilho" style={{marginLeft:'10rem'}}/>
                            <input type="button" name="ctl00$ContentPlaceHolder1$btnSalvar" value="Salvar"  onClick={this.updateClient} style={{marginLeft:'2rem'}}   className="btn btn-roxo" />
                        </div>
                    </div>
                </div>
            </div>
        </main>
        );

    }

    nameProduct = (id) => {
        if (id == 1) {
            return "Vivo Seguro Celular Danos";
        } else if (id == 2) {
            return "Vivo Seguro Celular Roubo";
        } else if (id == 3) {
            return "Vivo Seguro Celular Roubo + Danos";
        } else {
            return "Unknown";
        }
    }
    updateClient = () => {
        if (this.checkData()) {
            var splited = this.state.name.split(" ");
            var surname='';
            splited.length >1&&splited.map((element, index)=>{
                if(index!==0){
                    surname+=element;
                    surname+=' ';
                }
            });
            rpc({
                type: 'AreaPrivadaUpdateClient',
                name: this.state.name.split(" ")[0],
                surname: surname!==""?surname: this.state.name.split(" ")[1],
                document: this.state.document,
                document2: this.state.document2 ,
                birthdate: new Date(this.state.birthDate),
                emailAddress: this.state.email,
                phoneNumber1: this.state.phoneNumber1,
                phoneNumber2: this.state.phoneNumber2,
                addressStreet: this.state.addressStreet,
                addressPc: this.state.CEP,
                addressNumber: this.state.addressNumber,
                addressComplement: this.state.addressComplement,
                addressCity: this.state.addressCity,
                addressProvince: this.state.addressProvince,
                district: this.state.addressDistrict
            }).then((result) => {
                if(result ===null){
                    this.setState({ error: true });
                }else{
                    this.setState({ error: false, correctChange: true });
                }
                
            });
        }
    }

    checkData = () => {
         const name = this.state.name;
         const document = this.state.document;
         const birthDate = this.state.birthDate;
         const email = this.state.email;
         const phoneNumber1 = this.state.phoneNumber1;
         const CEP= this.state.CEP;
         const addressStreet= this.state.addressStreet;
         const addressCity= this.state.addressCity;
         const addressComplement = this.state.addressComplement;
         const addressProvince = this.state.addressProvince;
         const addressDistrict = this.state.addressDistrict;
         var splited = this.state.name.split(" ");
            var surname='';
            splited.length >1&&splited.map((element, index)=>{
                if(index!==0){
                    surname+=element;
                    surname+=' ';
                }
            });

        var errorMessage = [];

        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const digits = /^\d+$/;

        if (birthDate === undefined || birthDate === null || birthDate==='') {
            errorMessage.push(<p>Dígite uma data de nascimento válida, o campo não pode ficar em branco.</p>);
        }
        if (name === undefined || name === null || name==='') {
            errorMessage.push(<p>Dígite um nome válido, o campo não pode ficar em branco.</p>);
        }

        if (addressStreet === undefined || addressStreet === null || addressStreet==='') {
            errorMessage.push(<p>Dígite um endereço válido, o campo não pode ficar em branco.</p>);
        }

        if (addressProvince === undefined || addressProvince === null || addressProvince==='') {
            errorMessage.push(<p>Dígite um estado válido, o campo não pode ficar em branco.</p>);
        }

        if (addressCity === undefined || addressCity === null || addressCity==='') {
            errorMessage.push(<p>Dígite uma cidade válido, o campo não pode ficar em branco.</p>);
        }
        if (addressComplement === undefined || addressComplement === null || addressComplement==='') {
            errorMessage.push(<p>Dígite um Complemento válido, o campo não pode ficar em branco.</p>);
        }
        if (addressDistrict === undefined || addressDistrict === null || addressDistrict==='') {
            errorMessage.push(<p>Dígite um Bairro válido, o campo não pode ficar em branco.</p>);
        }

        if (email ==='' || !re.test(email)) {
            errorMessage.push(<p>Dígite um E-mail válido, o campo não pode fichar em branco.</p>);
        }

        if (phoneNumber1 === '' || phoneNumber1.length !== 13 ) {
            errorMessage.push(<p>Dígite um número de telefone de 13 dígitos.</p>);
        }

        if(surname === undefined ||surname === '' ){
            errorMessage.push(<p>Dígite um Sobrenome válido, o campo não pode ficar em branco.</p>);
        }

        if (!/^[0-9]+$/.test(phoneNumber1)) {
            errorMessage.push(<p>O número de telefone só pode ser numérico.</p>);
        }

        const isDigit = digits.test(document);
        if ( (document ==='' || (document.length !== 11 && document.length !== 14) ) || !isDigit)  {
            errorMessage.push(<p>Dígite 11 dígitos para o CPF e 14 dígitos para CNPJ. </p>);
        }

        // addressPc
        const etal = /^[0-9]{8}$/;
        if (CEP ==='' || !etal.test(CEP)) {
            errorMessage.push(<p>Dígite um CEP válido com 8 dígitos.</p>);
        }


        if(errorMessage.length!==0){
            this.setState({errorMessage, errorDataPopUp: true});
            return false;
        }

        return true;


    }
    onChangeProvince = (e) => {
        if (e.target.value != undefined && e.target.value != null) {
            this.onChangeValueByName(e);
            rpc({
                type: 'SelectCitys',
                provincia: e.target.value,
            }).then((result) => {
                this.setState({ citys: result });
            });
        }

    }


    buscarCEP = (e) => {
        this.onChangeValueByName(e);
        rpc({
            type: 'SelectZipCodeByCEP',
            cep: e.target.value
        }).then((response) => {
            if (response) {
                this.setState({
                    addressCity: response.ciudad && response.ciudad.toUpperCase(),
                    addressProvince: response.provincia,
                    addressDistrict: response.barrio && response.barrio.toUpperCase(),
                    addressStreet: response.direccion && response.direccion.toUpperCase(),
                    addressComplement: response.complemento && response.complemento.toUpperCase(),
                });
                rpc({
                    type: 'SelectCitys',
                    provincia: response.provincia,
                }).then((result) => {
                    this.setState({ citys: result });
                });
            }
        });
    }

    showError = () => {
       
        const message = 'Ocorreu um erro ao alterar dados';
        return <GenericPopup close={this.closeError} 
                    title='Erro ao alterar os dados.'
                    message={message}/>;
    }
    closeError = () => {
        this.setState({error: false});
    }

    showErrorData = () => {
       
        return <GenericPopup close={this.closeErrorData} 
                    title='Erro ao alterar os dados.'
                    message={this.state.errorMessage}/>;
    }
    closeErrorData = () => {
        this.setState({errorDataPopUp: false});
    }

    showCorrect = () => {
       
        const message = 'Os dados foram alterados corretamente.';
        return <GenericPopup close={this.closeCorrect} 
                    title='Os dados foram alterados corretamente.'
                    message={message}/>;
    }
    closeCorrect = () => {
        this.setState({correctChange: false});
    }

    onChangeValueByName = (e) => {
        this.setState({ ...this.state, [e.target.name]: e.target.value });
    }



}

export default MeusDados;
