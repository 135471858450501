import React, { Component } from 'react';
import IcoArrow from '../img/ico-arrow_new.svg';


export class PreguntasFrequentes_new extends Component {
    constructor(props) {
        super(props);
        this.state = { faq1: false, faq2: false, faq3: false, faq4: false, faq5: false, faq6: false, faq7: false, faq8: false, faq9: false, faq10: false, faq11: false, faq12: false, faq13: false, faq14: false, faq15: false, faq16: false, faq17: false };
        window.s = window.s || {};
        window.s.pageName = 'vivo:br:seguro movil:faqs';
        window.s.products = 'vivo:br:seguro movil:';
    }

    componentDidMount(){
        window.dataLayer.push ({
            'event' : 'asyncGTM.step',
            'GAcategory' : 'vivo:br:seguro movil',
            'GAaction' : 'paso:01:faqs:acceso',
            'GAlabel' :'vivo:br:seguro movil:'});
    }

    render() {
        return (
            <main className="" id="customrenov">
                <section className="row vivo-interior-faq">
                    <div className="col-xs-12">
                        <div className="container">
                            <div className="row row-text">
                                <div className="col-xs-12">
                                    <h2>FAQ</h2>
                                    <p>Veja abaixo algumas dúvidas frequentes sobre nossos produtos.</p>
                                </div>
                            </div>
                            <div className="row row-faq">
                                <div className="col col-lg-4 col-sm-12 col-xs-12">
                                    <a onClick={this.onClickTabelaAparelhos} className="btn-vivo shadow">Qual o valor do meu aparelho?</a>
                                </div>
                                <div className="col col-lg-8 col-sm-12 col-xs-12">
                                    <div className={this.state.faq1 ? "accordion active" : "accordion"} onClick={() => this.onClickFAQ(1)}>Quais são as coberturas do seguro celular? 
                                        <span className="accordion-icon"><img src={IcoArrow}/></span>
                                    </div>
                                    <div className="accordion-content" style={{}}>
                                        <div className="accordion-content__inner">
                                            <p>
                                                O <strong>Vivo Seguro Celular:</strong> é totalmente flexível, com a possibilidade de escolha da cobertura que melhor atende você: Danos Acidentais, Roubo e Furto ou Roubo e Danos.
                                                <br/><br/>
                                                <strong>Vivo Seguro Celular com a cobertura de Roubo e Furto:</strong> Garante proteção para o seu aparelho em caso de Roubo e Furto Simples ou Qualificado, com limite de indenização de acordo com a o valor de referência do aparelho. 
                                                <br/> 
                                                Multiproteção possui cobertura de Roubo, Furto Qualificado e Vivo Proteção Celular Danos, com limite de indenização de acordo com o valor do celular.
                                                <br/><br/>
                                                <strong>Vivo Seguro Celular com a cobertura de Danos Acidentais:</strong> Garantimos o conserto ou troca do aparelho em caso de queda ou colisão acidental, dano elétrico, se causado por curto-circuito acidental e derramamento de líquidos ou imersão em substâncias líquidas.
                                                <br/><br/>
                                                <strong>Vivo Seguro Celular com cobertura de Roubo e Danos Acidentais:  </strong> Garante proteção para o seu aparelho em caso de roubo, furto simples ou qualificado e danos acidentais, com limite de indenização de acordo com a o valor de referência do aparelho.
                                            </p>
                                        </div>
                                    </div>
                                    <div className={this.state.faq2 ? "accordion active" : "accordion"} onClick={() => this.onClickFAQ(2)}>O que é LMI?
                                        <span className="accordion-icon"><img src={IcoArrow}/></span>
                                    </div>
                                    <div className="accordion-content" style={{}}>
                                        <div className="accordion-content__inner">
                                            <p>O LMI ou limite máximo de indenização, corresponde ao valor máximo de responsabilidade da seguradora caso ocorra o sinistro durante o período de vigência do seguro. Esse valor é equivalente ao máximo definido no bilhete de seguro.</p>
                                        </div>
                                    </div>
                                    <div className={this.state.faq3 ? "accordion active" : "accordion"} onClick={() => this.onClickFAQ(3)}>Qual é o valor do Seguro?
                                        <span className="accordion-icon"><img src={IcoArrow}/></span>
                                    </div>
                                    <div className="accordion-content" style={{}}>
                                      <div className="accordion-content__inner">
                                        <p>Você pode consultar o valor do seguro de cada plano, acessando a aba "Coberturas”.</p>
                                      </div>
                                    </div>
                                    <div className={this.state.faq4 ? "accordion active" : "accordion"} onClick={() => this.onClickFAQ(4)}>Quais são as coberturas do seguro celular?
                                      <span className="accordion-icon"><img src={IcoArrow}/></span>
                                    </div>
                                    <div className="accordion-content" style={{}}>
                                      <div className="accordion-content__inner">
                                        <p>Você pode consultar o valor do seguro de cada plano, acessando a aba "Coberturas”.</p>
                                      </div>
                                    </div>
                                    <div className={this.state.faq5 ? "accordion active" : "accordion"} onClick={() => this.onClickFAQ(5)}>Este seguro cobre o reparo do meu aparelho caso ele quebre acidentalmente?
                                      <span className="accordion-icon"><img src={IcoArrow}/></span>
                                    </div>
                                    <div className="accordion-content" style={{}}>
                                      <div className="accordion-content__inner">
                                        <p>Sim. A cobertura de dano acidental garante o reparo do seu aparelho de forma a possibilitar que o bem volte a funcionar normalmente.</p>
                                      </div>
                                    </div>
                                    <div className={this.state.faq6 ? "accordion active" : "accordion"} onClick={() => this.onClickFAQ(6)}>Em caso de Danos Acidentais se não tiver conserto?
                                      <span className="accordion-icon"><img src={IcoArrow}/></span>
                                    </div>
                                    <div className="accordion-content" style={{}}>
                                      <div className="accordion-content__inner">
                                        <p>Quando não for possível efetuar o reparo do aparelho, a seguradora Zurich poderá providenciar a reposição do bem por outro igual ou similar.</p>
                                      </div>
                                    </div>
                                    <div className={this.state.faq7 ? "accordion active" : "accordion"} onClick={() => this.onClickFAQ(7)}>Quantas indenizações eu tenho direito?
                                      <span className="accordion-icon"><img src={IcoArrow}/></span>
                                    </div>
                                    <div className="accordion-content" style={{}}>
                                      <div className="accordion-content__inner">
                                        <p>Em caso de Danos Acidentais você tem direito a até 3 (três) indenizações de sinistros ou até atingir o limite máximo de indenização, com o mesmo bem segurado; depois disso a cobertura estará automaticamente cancelada.</p>
                                        <p>Em caso de Roubo ou Furto Simples ou Qualificado você tem direito a 1 (uma) indenização de sinistro até o limite máximo de indenização; depois disso a cobertura estará automaticamente cancelada.</p>
                                      </div>
                                    </div>
                                    <div className={this.state.faq8 ? "accordion active" : "accordion"} onClick={() => this.onClickFAQ(8)}>Qual a periodicidade de pagamento?
                                      <span className="accordion-icon"><img src={IcoArrow}/></span>
                                    </div>
                                    <div className="accordion-content" style={{}}>
                                      <div className="accordion-content__inner">
                                        <p>O pagamento deste seguro é mensal.</p>
                                      </div>
                                    </div>
                                    <div className={this.state.faq9 ? "accordion active" : "accordion"} onClick={() => this.onClickFAQ(9)}>Por quanto tempo estarei coberto?
                                      <span className="accordion-icon"><img src={IcoArrow}/></span>
                                    </div>
                                    <div className="accordion-content" style={{}}>
                                      <div className="accordion-content__inner">
                                        <p>O prazo de vigência é de 61 (sessenta e um) meses, iniciando-se às 24 (vinte e quatro) horas da data de adesão e será válido enquanto houver o recolhimento do prêmio mensal de seguro.</p>
                                      </div>
                                    </div>
                                    <div className={this.state.faq10 ? "accordion active" : "accordion"} onClick={() => this.onClickFAQ(10)}>Quando posso renovar meu seguro?
                                      <span className="accordion-icon"><img src={IcoArrow}/></span>
                                    </div>
                                    <div className="accordion-content" style={{}}>
                                      <div className="accordion-content__inner">
                                          <p>A cobertura do seguro individual será renovada automaticamente uma única vez, pelo mesmo período, desde que não exista manifestação expressa contrária à renovação, por parte da Seguradora ou do Segurado, até 60 (sessenta) dias antes da data de encerramento da vigência.</p>
                                      </div>
                                    </div>
                                    <div className={this.state.faq11 ? "accordion active" : "accordion"} onClick={() => this.onClickFAQ(11)}>Se eu esquecer de pagar eu perco a cobertura?
                                      <span className="accordion-icon"><img src={IcoArrow}/></span>
                                    </div>
                                    <div className="accordion-content" style={{}}>
                                      <div className="accordion-content__inner">
                                          <p>Sim, o seguro é mensal, portanto, você estará protegido nos meses em que houver pagamento do seguro.</p>
                                      </div>
                                    </div>
                                    <div className={this.state.faq12 ? "accordion active" : "accordion"} onClick={() => this.onClickFAQ(12)}>O que é franquia?
                                      <span className="accordion-icon"><img src={IcoArrow}/></span>
                                    </div>
                                    <div className="accordion-content" style={{}}>
                                      <div className="accordion-content__inner">
                                          <p>Franquia é o valor de responsabilidade do segurado que deverá ser e pago à seguradora no momento da indenização do seu sinistro e poderá ser variável de acordo com o plano contratado.</p>
                                      </div>
                                    </div>
                                    <div className={this.state.faq13 ? "accordion active" : "accordion"} onClick={() => this.onClickFAQ(13)}>Para acionar o seguro eu preciso pagar a franquia?
                                      <span className="accordion-icon"><img src={IcoArrow}/></span>
                                    </div>
                                    <div className="accordion-content" style={{}}>
                                      <div className="accordion-content__inner">
                                          <p><strong>Vivo Seguro Celular com cobertura de Roubo ou Furto Simples ou Qualificado:</strong> você pagará uma franquia de 25% do valor do aparelho.</p>
                                          <p><strong>Vivo Seguro Celular com cobertura de Danos Acidentais:</strong> você poderá escolher entre pagar uma franquia de 15% para o reparo do seu aparelho ou 10% para a reposição do seu bem por um aparelho renovado.</p>
                                          <p><strong>Vivo Seguro Celular com cobertura de Roubo e Danos Acidentais:  </strong> você pagará uma franquia de 25% para roubo e 20% para danos acidentais do valor do aparelho.</p>
                                      </div>
                                    </div>
                                    <div className={this.state.faq14 ? "accordion active" : "accordion"} onClick={() => this.onClickFAQ(14)}>O que é aparelho renovado?
                                      <span className="accordion-icon"><img src={IcoArrow}/></span>
                                    </div>
                                    <div className="accordion-content" style={{}}>
                                      <div className="accordion-content__inner">
                                          <p>É um aparelho que apresentou defeito e foi trocado por peças novas e originais e passadas por testes completos. Todos os produtos incluem o software operacional originalmente fornecido com a unidade.</p>
                                      </div>
                                    </div>
                                    <div className={this.state.faq15 ? "accordion active" : "accordion"} onClick={() => this.onClickFAQ(15)}>O que não está coberto?
                                      <span className="accordion-icon"><img src={IcoArrow}/></span>
                                    </div>
                                    <div className="accordion-content" style={{}}>
                                      <div className="accordion-content__inner">
                                          <p><strong>Na cobertura Danos Acidentais:</strong> danos de causas internas em geral, originados pelo próprio funcionamento do bem segurado, incluindo os danos decorrentes de desarranjo, de defeito de material, de erro ou falha de fabricação ou de execução de produtos, falta de manutenção, depreciação, deterioração e desgaste natural do bem.</p>
                                          <p>Para saber quais são todos os riscos excluídos, <a className="vivo-color bold"> veja as condições gerais de cada plano.</a></p>
                                      </div>
                                    </div>
                                    <div className={this.state.faq16 ? "accordion active" : "accordion"} onClick={() => this.onClickFAQ(16)}>Como posso cancelar o seguro?
                                      <span className="accordion-icon"><img src={IcoArrow}/></span>
                                    </div>
                                    <div className="accordion-content" style={{}}>
                                      <div className="accordion-content__inner">
                                          <p>Você pode cancelar o seguro através do Serviço de Atendimento ao Segurado nos telefones: <a href="tel:08005800277" target="_blank" className="" style={{cursor: 'pointer'}}>0800 580 0277</a> ou WhatsApp: <a className="footer-links" href="https://api.whatsapp.com/send?phone=+5511972878201&amp;text=Ol%C3%A1!%20Necessito%20de%20ajuda!" target="_blank">(11) 97287-8201</a> - Segunda à sexta das 8h às 20h e sábado das 8h às 15h ou acesso via web, pelo endereço <a href=" http://www.vivosegurocelular.com.br/"> www.vivosegurocelular.com.br </a> na área do cliente. Se preferir, também pode cancelar o seguro ligando para *4422 (apenas linhas Vivo) ou enviar SMS para este mesmo número, escolher a opção 4 e responder SAIR.</p>
                                      </div>
                                    </div>
                                    <div className={this.state.faq17 ? "accordion active" : "accordion"} onClick={() => this.onClickFAQ(17)}>O que é Depreciação Anual do Aparelho?
                                      <span className="accordion-icon"><img src={IcoArrow}/></span>
                                    </div>
                                    <div className="accordion-content" style={{}}>
                                      <div className="accordion-content__inner">
                                        <p>
                                          De forma objetiva com o passar do tempo, o valor do seu aparelho vai diminuindo, levando em consideração alguns elementos como desgaste e tempo de uso.
                                          <br/>Com isso, anualmente Limite Máximo de Indenização do seguro será atualizado, de acordo com o valor do aparelho depreciado, consequentemente o valor do seguro também será alterado, pois o mesmo é calculado em cima do valor do aparelho.
                                        </p>
                                          
                                        <p>Conforme exemplo abaixo:</p>
                      
                                        <table className="faq-table">
                                          <tr>
                                            <th className="faq-th">Valor do LMI<br/>1º Ano</th>
                                            <th className="faq-th">Valor do Seguro<br/>1º Ano</th>
                                            <th className="faq-th">Valor do LMI<br/>2º Ano</th>
                                            <th className="faq-th">Valor do Seguro<br/>2º Ano</th>
                                            <th className="faq-th">Valor do LMI<br/>3º Ano</th>
                                            <th className="faq-th">Valor do Seguro<br/>3º Ano</th>
                                          </tr>
                                          <tr>
                                            <td className="faq-td">R$ 1.500,00</td>
                                            <td className="faq-td">R$ 27,60</td>
                                            <td className="faq-td">R$ 1.350,00</td>
                                            <td className="faq-td">R$ 24,84</td>
                                            <td className="faq-td">R$ 1.215,00</td>
                                            <td className="faq-td">R$ 22,36</td>
                                          </tr>
                                        </table>
                                      </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
    onClickTabelaAparelhos = () => {
        //Estos dos campos vienen rellenados para que pasen las verificaciones
        window.dataLayer.push ({
            'event' : 'asyncGTM',
            'GAcategory' : 'vivo:br:seguro movil',
            'GAaction' : 'faqs:Qual o valor do meu aparelho',
            'GAlabel' :'vivo:br:seguro movil:'});
        window.open(process.env.PUBLIC_URL + '/FileDownload?tokken='+ 0 + '&documentAparelhos=' + 1, '_blank').print();
    }


    onClickFAQ = (number) => {
      let state = this.state;
      switch(number) {
        case 1:
          state.faq1 = !this.state.faq1;
          window.dataLayer.push ({
            'event' : 'asyncGTM',
            'GAcategory' : 'vivo:br:seguro movil',
            'GAaction' : 'faqs:Quais são as coberturas do seguro celular',
            'GAlabel' :'vivo:br:seguro movil:'});
          break;
        case 2:
          state.faq2 = !this.state.faq2;
          window.dataLayer.push ({
            'event' : 'asyncGTM',
            'GAcategory' : 'vivo:br:seguro movil',
            'GAaction' : 'faqs:O que é LMI',
            'GAlabel' :'vivo:br:seguro movil:'});
          break;
        case 3:
          state.faq3 = !this.state.faq3;
          window.dataLayer.push ({
            'event' : 'asyncGTM',
            'GAcategory' : 'vivo:br:seguro movil',
            'GAaction' : 'faqs:Qual é o valor do Seguro',
            'GAlabel' :'vivo:br:seguro movil:'});
          break;
        case 4:
          state.faq4 = !this.state.faq4;
          window.dataLayer.push ({
            'event' : 'asyncGTM',
            'GAcategory' : 'vivo:br:seguro movil',
            'GAaction' : 'faqs:Quais são as coberturas do seguro celular',
            'GAlabel' :'vivo:br:seguro movil:'});
          break;
        case 5:
          state.faq5 = !this.state.faq5;
          window.dataLayer.push ({
            'event' : 'asyncGTM',
            'GAcategory' : 'vivo:br:seguro movil',
            'GAaction' : 'faqs:Este seguro cobre o reparo do meu aparelho caso ele quebre acidentalmente',
            'GAlabel' :'vivo:br:seguro movil:'});
          break;
        case 6:
          state.faq6 = !this.state.faq6;
          window.dataLayer.push ({
            'event' : 'asyncGTM',
            'GAcategory' : 'vivo:br:seguro movil',
            'GAaction' : 'faqs:Em caso de Danos Acidentais se não tiver conserto',
            'GAlabel' :'vivo:br:seguro movil:'});
          break;
        case 7:
          state.faq7 = !this.state.faq7;
          window.dataLayer.push ({
            'event' : 'asyncGTM',
            'GAcategory' : 'vivo:br:seguro movil',
            'GAaction' : 'faqs:Quantas indenizações eu tenho direito',
            'GAlabel' :'vivo:br:seguro movil:'});
          break;
        case 8:
          state.faq8 = !this.state.faq8;
          window.dataLayer.push ({
            'event' : 'asyncGTM',
            'GAcategory' : 'vivo:br:seguro movil',
            'GAaction' : 'faqs:Qual a periodicidade de pagamento',
            'GAlabel' :'vivo:br:seguro movil:'});
          break;
        case 9:
          state.faq9 = !this.state.faq9;
          window.dataLayer.push ({
            'event' : 'asyncGTM',
            'GAcategory' : 'vivo:br:seguro movil',
            'GAaction' : 'faqs:Por quanto tempo estarei coberto',
            'GAlabel' :'vivo:br:seguro movil:'});
          break;
        case 10:
          state.faq10 = !this.state.faq10;
          window.dataLayer.push ({
            'event' : 'asyncGTM',
            'GAcategory' : 'vivo:br:seguro movil',
            'GAaction' : 'faqs:Quando posso renovar meu seguro',
            'GAlabel' :'vivo:br:seguro movil:'});
          break;
        case 11:
          state.faq11 = !this.state.faq11;
          window.dataLayer.push ({
            'event' : 'asyncGTM',
            'GAcategory' : 'vivo:br:seguro movil',
            'GAaction' : 'faqs:Se eu esquecer de pagar eu perco a cobertura',
            'GAlabel' :'vivo:br:seguro movil:'});
          break;
        case 12:
          state.faq12 = !this.state.faq12;
          window.dataLayer.push ({
            'event' : 'asyncGTM',
            'GAcategory' : 'vivo:br:seguro movil',
            'GAaction' : 'faqs:O que é franquia',
            'GAlabel' :'vivo:br:seguro movil:'});
          break;
        case 13:
          state.faq13 = !this.state.faq13;
          window.dataLayer.push ({
            'event' : 'asyncGTM',
            'GAcategory' : 'vivo:br:seguro movil',
            'GAaction' : 'faqs:Para acionar o seguro eu preciso pagar a franquia',
            'GAlabel' :'vivo:br:seguro movil:'});
          break;
        case 14:
          state.faq14 = !this.state.faq14;
          window.dataLayer.push ({
            'event' : 'asyncGTM',
            'GAcategory' : 'vivo:br:seguro movil',
            'GAaction' : 'faqs:O que é aparelho renovado',
            'GAlabel' :'vivo:br:seguro movil:'});
          break;
        case 15:
          state.faq15 = !this.state.faq15;
          window.dataLayer.push ({
            'event' : 'asyncGTM',
            'GAcategory' : 'vivo:br:seguro movil',
            'GAaction' : 'faqs:O que não está coberto',
            'GAlabel' :'vivo:br:seguro movil:'});
          break;
        case 16:
          state.faq16 = !this.state.faq16;
          window.dataLayer.push ({
            'event' : 'asyncGTM',
            'GAcategory' : 'vivo:br:seguro movil',
            'GAaction' : 'faqs:Como posso cancelar o seguro',
            'GAlabel' :'vivo:br:seguro movil:'});
          break;
        case 17:
          state.faq17 = !this.state.faq17;
          window.dataLayer.push ({
            'event' : 'asyncGTM',
            'GAcategory' : 'vivo:br:seguro movil',
            'GAaction' : 'faqs:O que é Depreciação Anual do Aparelho',
            'GAlabel' :'vivo:br:seguro movil:'});
          break;
        default:
          break;
      }
      this.setState({faq1: state.faq1, faq2: state.faq2, faq3: state.faq3, faq4: state.faq4, faq5: state.faq5, faq6: state.faq6, faq7: state.faq7, faq8: state.faq8, faq9: state.faq9, faq10: state.faq10, faq11: state.faq11, faq12: state.faq12, faq13: state.faq13, faq14: state.faq14, faq15: state.faq15, faq16: state.faq16, faq17: state.faq17})
    }
}

export default PreguntasFrequentes_new;