import React, { Component } from 'react';
import { Redirect} from 'react-router-dom';
import rpc, { rpcWithParams }  from './our/rpc';

export class TISContratationRedirect extends Component {
    componentDidMount(){
        const value = {
        dateOcurrence: new Date(),
        pageVisited: 'Llega por la parte de TISSinistros.',
        idOffert: 9
        };
        rpc({
            type: 'SaveContrationStepsHistory',
            value
        }).then((result) => {
            this.props.onChangeContratationStep(result);
        });

        if (this.props.location.search !== '') {
            const queryString = this.props.location.search;
            var searchParams = new URLSearchParams(queryString);
            console.log(this.props.location);
            console.log(queryString);
            console.log(searchParams);
            const token = searchParams.get("token");
            rpc({
                type: 'SelectTisContratationTokenByTokenIfUsable',
                tokken: token
            }).then((result) => {
                if (result != undefined && result != null) {
                    this.props.setTISContratationData(token, result.user);
                    rpc({
                        type: 'UseTisContratationToken',
                        tokken: token,
                        used: 1
                    })
                } else {
                    this.props.openTisContratationError();
                }
            });
        }
    }

    render() {
        return (
            <Redirect to="/Contratation" />

        )
    }
}

export default TISContratationRedirect