import RenderInBody from '../renderInBody';
const React = require('react');

class ErrorPopUp extends React.Component {

    render() {
        return (
            <RenderInBody component={<div className='u_dialog_container'><div className='u-dialog u-vertical'>
            <div className="modal-header d-flex">
                <div className="col-xs-11 nopadding ds-vertical-align">
                    <h5 className="modal-title">Error</h5>
                </div>
                <div className="col-xs-1 nopadding ds-vertical-align">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" tabIndex="0">
                        <span aria-hidden="true" onClick={this.props.close}>×</span>
                    </button>
                </div>
            </div>
            <div className="modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-body">
                        <p className="vivo-color bold">O número pode ser apenas numérico</p>
                    </div>
                </div>
            </div>
            </div></div>}/>
        );
    }
}

export default ErrorPopUp;