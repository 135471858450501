import RenderInBody from '../renderInBody';
import rpc from '../our/rpc';
const React = require('react');

class ConfirmPopUp extends React.Component {

    constructor(props) {
        super(props);
        this.state = { idCancelation: 0 };
    }

    componentWillMount() {
        rpc({
            type: 'GetCancellationReasonsOfPolicy'
        }).then((reason) => {
            this.setState({ cancellationReasons: reason });
        });
    }


    render() {
        return (
            <RenderInBody component={<div className='u_dialog_container'><div className='u-dialog u-vertical'>
                <div className="modal-header d-flex">
                    <div className="col-xs-11 nopadding ds-vertical-align">
                        <h5 className="modal-title">Aviso</h5>
                    </div>
                    <div className="col-xs-1 nopadding ds-vertical-align">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" tabIndex="0">
                            <span aria-hidden="true" onClick={this.props.close}>×</span>
                        </button>
                    </div>
                </div>
                <div className="modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <p className="vivo-color bold">Selecione o motivo do seu cancelamento</p>
                            <select name="idCancelation" value={this.state.idCancelation} title={'Cancellation reason'} onChange={this.onChangeValueByName} required>
                                <option className="bs-title-option" value=""></option>
                                {this.state.cancellationReasons && this.state.cancellationReasons.map(reason => (
                                    <option key={reason.id} value={reason.id}>
                                        {reason.nameWeb}
                                    </option>
                                ))}
                            </select>
                            <br/>
                            <br/>
                            <input type="submit" name="ctl00$ContentPlaceHolder1$btnclose" onClick={this.props.close} value="Não, eu não quero cancelar" id="ContentPlaceHolder1_btnclose" className="btn btn-vivo btn-roxo" tabIndex="0" />
                            <input type="submit" name="ctl00$ContentPlaceHolder1$btnAvancar1" onClick={this.submit} value="Sim, quero cancelar meu certificado" id="ContentPlaceHolder1_btnAvancar1" className="btn btn-vivo btn-roxo" style={{ float: 'right' }} tabIndex="0" />
                        </div>
                    </div>
                </div>
            </div></div>} />

        );
    }

    submit = () => {
        this.props.resolve(this.state.idCancelation);
    }

    onChangeValueByName = (e) => {
        this.setState({ ...this.state, [e.target.name]: e.target.value });
    }
}


export default ConfirmPopUp;