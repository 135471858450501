import RenderInBody from '../renderInBody';
const React = require('react');

class GenericPopup_new extends React.Component {

    render() {

        const header = <div className="modal-header">
            <div className="modal-title">
                <p>{this.props.title}</p>
            </div>
            <a  onClick={this.props.close} className="modal-close"></a>
        </div>;

        const body =
            <div className="modal-body">
                <p className="title last">{this.props.message}</p>
            </div>
            ;



            return  (
                <RenderInBody component={
                    <div id="modal-wrapper">
                        <div className="modal" id="ModalVerificacion">
                            {header}{body}
                        </div>
                        <div id="modal-mask" onClick={this.props.close}></div>
                    </div>
                }/>
        
                );
    }
}

export default GenericPopup_new;