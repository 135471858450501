import React, { Component, Suspense } from 'react';
import vivoLogo from '../img/vivo-logo.svg';
import home from '../img/ico-home.svg';

import rpc from '../our/rpc';
import MeuSeguro from './MeuSeguro.jsx';
import MeusPagamentos from './MeusPagamentos.jsx';
import MeusDados from './MeusDados.jsx';
import '../fonts/vivo/vivo.css';
import '../fonts/officina/officina.css';
import '../fonts/roboto/roboto.css';
import '../fonts/font-awesome/css/font-awesome.min.css';





import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';


export class ClientArea extends Component {

    constructor(props) {
        super(props);
        this.state = { urlOnline: '/TISOnlineClaims', menuClicked: false, endCancelation: false }; // /TISOnlineClaims
    }

    componentDidMount() {
        rpc({
            type: 'IsLogged'
        }).then((result) => {
            if (!result) {
                for(let css of document.styleSheets) {
                    css.disabled = true;
                }
                localStorage.removeItem('cookie');
                this.props.history.push('/');
                window.location.reload();
            } else if (this.props.idPolicy != undefined && this.props.idPolicy != null) {
                localStorage.setItem('cookie', 'True');
                rpc({
                    type: 'AreaPrivadaGetLivePoliciesByClientId'
                }).then((policiesLive) => {
                    if (policiesLive != null && policiesLive != undefined) {
                        this.setState({ selectedPolicy: policiesLive.listPolicies[0].idPolicy, productName: this.nameProduct(policiesLive.listPolicies[0].idProduct) });
                    }
                });
            } else {
                if (localStorage.getItem('cookie') != 'True') {
                    for(let css of document.styleSheets) {
                        css.disabled = true;
                    }
                    localStorage.setItem('cookie', 'True');
                    window.location.reload();
                }
            }
        });
        const path = window.location.pathname.split('/');
        let url = '';
        if (path.length > 2 && !path[1].includes('AreaDo')) {
            url = window.location.origin + '/' + path[1];
        } else {
            url = window.location.origin + '/';
        }
        this.setState({ url })
    }




    render() {
        const header = (

            <body id='page-top' data-spy='scroll' data-target='.navbar-fixed-top'>
                <div className='container-fluid#cabeceraAP bloques_todos' id='cabeceraAP' style={{}}>
                    <div className='container nopadding'>

                        <div className='col-sm-12 ds-vertical-align cabecera-1 precabecera-logo' id='cabeceraTodosAP'>
                            <a onClick={() => { this.props.history.push('/area-do-cliente/meus-seguros') }}><img src={vivoLogo} style={{ marginBottom: '1rem' }} /></a>
                            <h1 className='vivo-font mb-0'>ÁREA DO CLIENTE</h1>
                        </div>
                    </div>
                </div>
                <nav className='container-fluid bloques_todos' id='menuVivoAP'>
                    <div id='menuAdmintelins' className='container cabeceramenu-1' >

                        {/* Movil */}
                        <div className='navbar-header hidden-md hidden-lg'>
                            <button type='button' onClick={this.clickedMenu} className='navbar-toggle collapsed' data-toggle='collapse' data-target='#bs-example-navbar-collapse-2' aria-expanded='false'>
                                <span className='sr-only'>Toggle navigation</span>
                                <span className='icon-bar'></span>
                                <span className='icon-bar'></span>
                                <span className='icon-bar'></span>
                            </button>
                            <a className='navbar-brand' href='#'>Menu</a>
                            {this.state.menuClicked ? 
                            <ul className='nav navbar-nav'>
                                <li><a onClick={() => { this.props.history.push('/area-do-cliente/meus-seguros') }} style={{cursor: 'pointer',fontFamily: 'inherit', fontWeight: 'inherit', fontSize: 'inherit', lineHeight: 'inherit', background: '#6d2b92', color: 'white', border: 'none' }}> Meu Seguro</a></li>
                                <li><a onClick={() => { this.props.history.push('/area-do-cliente/meus-dados') }} style={{cursor: 'pointer',fontFamily: 'inherit', fontWeight: 'inherit', fontSize: 'inherit', lineHeight: 'inherit', background: '#6d2b92', color: 'white', border: 'none' }}> Meus Dados</a></li>
                                <li><a onClick={() => { this.props.history.push('/area-do-cliente/meus-pagamentos') }} disabled={this.state.selectedPolicy == undefined || this.state.selectedPolicy == null} style={{cursor: 'pointer',fontFamily: 'inherit', fontWeight: 'inherit', fontSize: 'inherit', lineHeight: 'inherit', background: '#6d2b92', color: 'white', border: 'none' }}> Meus Pagamentos</a></li>
                                <li><a href={this.state.urlOnline} style={{cursor: 'pointer',fontFamily: 'inherit', fontWeight: 'inherit', fontSize: 'inherit', lineHeight: 'inherit', background: '#6d2b92', color: 'white', border: 'none' }}> Abrir Sinistro</a></li>
                            </ul> : undefined}

                        </div>
                        {/* Opciones */}
                        <div className='collapse navbar-collapse' id='bs-example-navbar-collapse-2'>
                            <ul className='navbar-nav'>
                            <li className='row-1'>
                                    <button onClick={() => { this.props.history.push('/area-do-cliente/meus-seguros') }} style={{ background: '#6d2b92', border: 'none' }}>
                                        <img src={home} className='svg-inject' id='icoHome' />
                                    </button>
                                </li>
                                <li className='row-3'>
                                    <a onClick={() => { this.props.history.push('/area-do-cliente/meus-seguros') }} style={{cursor: 'pointer',fontFamily: 'inherit', fontWeight: 'inherit', fontSize: 'inherit', lineHeight: 'inherit', background: '#6d2b92', color: 'white', border: 'none' }}> Meu Seguro</a>
                                </li>
                                <li className='row-3'>
                                    <a onClick={() => { this.props.history.push('/area-do-cliente/meus-dados') }} style={{cursor: 'pointer',fontFamily: 'inherit', fontWeight: 'inherit', fontSize: 'inherit', lineHeight: 'inherit', background: '#6d2b92', color: 'white', border: 'none' }}> Meus Dados</a>
                                </li>
                                <li className='row-3'>
                                    {this.state.selectedPolicy == undefined || this.state.selectedPolicy == null ?
                                    <a onClick={() => {}} style={{cursor: 'pointer',fontFamily: 'inherit', fontWeight: 'inherit', fontSize: 'inherit', lineHeight: 'inherit' ,background: '#6d2b92', color: 'white', border: 'none' }}> Meus Pagamentos</a> 
                                    : <a onClick={() => { this.props.history.push('/area-do-cliente/meus-pagamentos') }} style={{cursor: 'pointer',fontFamily: 'inherit', fontWeight: 'inherit', fontSize: 'inherit', lineHeight: 'inherit' ,background: '#6d2b92', color: 'white', border: 'none' }}> Meus Pagamentos</a>}
                                    
                                </li>
                                <li className='row-3'>
                                    <a href={this.state.urlOnline} style={{cursor: 'pointer',fontFamily: 'inherit', fontWeight: 'inherit', fontSize: 'inherit', lineHeight: 'inherit', background: '#6d2b92', color: 'white', border: 'none' }}> Abrir Sinistro</a>
                                </li>

                                {/*<li id='acompanharSinistroSection'><a href='acompanharSinistroLayout.jsp'>Acompanhar Sinistro</a></li>!
                                font-family: inherit;
    font-size: inherit;
    line-height: inherit;*/}
                            </ul>
                        </div>

                    </div>
                </nav>
            </body>);


        return (

            <div>
                {header}
                <Switch>
                    <Route path='/area-do-cliente/meus-pagamentos' render={props => (
                        <MeusPagamentos
                            {...props}
                            {...this.state}
                            logout={this.logout}
                            idPolicy={this.state.selectedPolicy}
                            client={this.state.client}
                            policy={this.state.policy}
                            payments={this.state.payments}
                        />
                    )} />
                    <Route exact path='/area-do-cliente/meus-seguros' render={props => (
                        <MeuSeguro
                            {...props}
                            {...this.state}
                            logout={this.logout}
                            selectPolicy={this.selectPolicy}
                            cancelarPolicy={this.cancelarPolicy}
                            openCancelPopUp={this.openCancelPopUp}
                            closeCancelPopUp={this.closeCancelPopUp}
                            openConfirmCancelPopUp={this.openConfirmCancelPopUp}
                            closeConfirmCancelPopUp={this.closeConfirmCancelPopUp}
                            closeEndCancelationPopUp={this.closeEndCancelationPopUp}
                            productName={this.state.productName}
                        />
                    )} />
                    <Route exact path='/area-do-cliente/meus-dados' render={props => (
                        <MeusDados
                            {...props}
                            {...this.state}
                            logout={this.logout}
                            productName={this.state.productName}

                        />
                    )} />
                    <Redirect to='/area-do-cliente/meus-seguros' />
                </Switch>
            </div>
        );
    }

    logout = () => {
        rpc({
            type: 'OscpLogOut',
        }).then(() => {
            for(let css of document.styleSheets) {
                css.disabled = true;
            }
            localStorage.removeItem('cookie');
            this.props.history.push('/');
            window.location.reload();
        });
    }

    selectPolicy = (policy, productName) => {
        if (this.state.selectPolicy != policy && policy != null) {
            rpc({
                type: 'AreaPrivadaSelectClient',
                idPolicy: policy
            }).then((result) => {
                this.setState({ client: result.client, policy: result.policy, payments: result.payments, selectedPolicy: policy, productName, idSubtype: result.idSubtype });
            });
        }
    }

    closeCancelPopUp = () => {
        this.setState({ selectedPolicy: undefined, cancelar: false });
    }

    openCancelPopUp = (idPolicy) => {
        this.setState({ selectedPolicy: idPolicy, cancelar: true });
    }

    closeEndCancelationPopUp = () => {
        this.setState({  endCancelation: false });
    }

    closeConfirmCancelPopUp = () => {
        this.setState({  confirmCancelar: false, selectedPolicy: undefined, cancelar: false });
    }

    openConfirmCancelPopUp = (idCancelation) => {
        this.setState({  confirmCancelar: true, idCancelation  });
    }

    cancelarPolicy = () => {
        this.setState({ cancelar: false, confirmCancelar: false, endCancelation: true });
        rpc({
            type: 'AreaPrivadaCancelPolicy',
            idPolicy: this.state.selectedPolicy,
            idCancelation: this.state.idCancelation
        }).then((result) => {
            if (result != null && result !== undefined) {
                window.location.reload();
            }
        });

    }

    clickedMenu = () => {
        this.setState({ menuClicked: !this.state.menuClicked});
    }

    nameProduct = (id) => {
        if (id == 1) {
            return 'Vivo Seguro Celular Danos';
        } else if (id == 2) {
            return 'Vivo Seguro Celular Roubo';
        } else if (id == 3) {
            return 'Vivo Seguro Celular Roubo + Danos';
        } else {
            return 'Unknown';
        }
    }

    returnHome = () => {
        this.props.history.push('/area-do-cliente/meus-seguros')
    }


}

export default ClientArea;
