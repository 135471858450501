import React, { Component } from 'react';
import { Redirect} from 'react-router-dom';
import rpc, { rpcWithParams }  from './our/rpc';

export class AppDayRedirect extends Component {
    componentDidMount(){
        rpc({
            type: 'IsAppDayByDate',
            date: new Date()
        }).then((result) => {
            if (result > 0) {
                this.props.onChangeOffer(7);
                const value = {
                dateOcurrence: new Date(),
                pageVisited: 'Llega por la parte de AppDay.',
                idOffert: 7
                };
                rpc({
                    type: 'SaveContrationStepsHistory',
                    value
                }).then((result) => {
                    this.props.onChangeContratationStep(result);
                    console.log(result);
                });
            }
        });
    }

    render() {
        return (
            <Redirect to="/Contratation" />

        )
    }
}

export default AppDayRedirect