import React, { useState, useEffect, Suspense } from 'react';
import App from './App';

const defaultState = {
    loading: false
}

const ThemeWeb = React.lazy(() => import('./Themes/ThemeWeb.jsx'));

const ThemeAreaPrivada = React.lazy(() => import('./Themes/ThemeAreaPrivada.jsx'));

const ThemeSelector = ({children}) => {

        const [loading, setLoading] = useState(defaultState.loading);
        useEffect(() => { setLoading(false) },[]);
        //useEffect(() => { setTimeout(() => setLoading(false), 1000)}, []);

        return(
            <>
                <Suspense fallback={<></>}>
                    {localStorage.getItem('cookie') == "True" && <ThemeAreaPrivada />}
                    {localStorage.getItem('cookie') != "True" && <ThemeWeb />}
                    {loading ? undefined : children}
                </Suspense>
            </>
        );
}
  
export default ThemeSelector;