import React, { Component } from 'react';
import rpc from '../our/rpc';
import download from '../img/btn_certificado.svg';
import ConfirmPopUp from '../popUps/ConfirmPopUp.jsx';
import ContinuarCancelamentoPopUp from '../popUps/ContinuarCancelamentoPopUp.jsx'
import GenericPopup from '../popUps/GenericPopUp';
import '../fonts/vivo/vivo.css';
import '../fonts/officina/officina.css';
import '../fonts/roboto/roboto.css';
import '../fonts/font-awesome/css/font-awesome.min.css';



export class MeuSeguro extends Component {

    constructor(props) {
        super(props);
        this.state = {errorCerificado: false, textoErrorCerificado: ''};
    }
   
    componentDidMount(){
        rpc({
            type: 'AreaPrivadaGetPoliciesByDocument',
        }).then((policies) => {
            this.setState({policies:policies});
        });    
    }

    render() {
        const tablePolicies= this.state.policies!=null && this.state.policies.listPolicies!=undefined?this.state.policies.listPolicies.map((policy) => {
          return(
                <tr style={{backgroundColor: policy.policyStatus == 2 ?"#e5efca" : ""}}>
                    <td align="center">{this.getEquipamiento(policy.idSubtype)}</td>
                    <td align="center">{policy.productName=="Vivo Multiproteção"?"Vivo Seguro Celular Roubo + Danos":policy.productName}</td>
                    <td align="center">{policy.phoneNumber}</td>
                    <td align="center">{policy.numeroDaSorte?policy.numeroDaSorte:"-"}</td>
                    <td align="center">{policy.policyNumber}</td>
                    <td align="center">{new Intl.NumberFormat('pt-BR').format(policy.policyPremium)}</td>
                    <td align="center">{this.getText(policy.policyStatus)}</td>
                    <td align="center" style={{cursor: 'pointer'}}><img  src={download} onClick={() => this.newWindow(policy)}/></td>
                    <td align="center" style={{cursor: 'pointer'}}><img src="../img/btn_selecionar_03.svg" onClick={() => this.props.selectPolicy(policy.policyId, policy.productName=="Vivo Multiproteção"?"Vivo Seguro Celular Roubo + Danos":policy.productName)}/></td>
                    <td align="center" style={{cursor: 'pointer'}}><img src="../img/btn_selecionar_03.svg" onClick={() => this.props.openCancelPopUp(policy.policyId)}/></td>
                </tr>
          );
        }):<div></div>;
                
            
        return (
            <main className="container nopadding" id="customrenov">
            <div className="container text-center mt-60 mb-20 mt-20-mobile mb-0-mobile">
                <h2 className="roboto-font">Gerencie seu Seguro</h2>
            </div>
            <form action="" method="POST" name="meus-seguros">
                <div id="divIdentificacao" className="container nopadding">
                    <div className="col-xs-12 flex-center-1-column mt-40 mb-40 mb-20-mobile" style={{marginTop: "20px"}}>
                        <div className="resumo">
                            <div className="usuario-resumo">
                                Olá: <span id="lblNomeUsuarioLogado">{ this.state.policies?this.state.policies.listPolicies[0].clientFullname.split(" ")[0]:""}</span>
                            </div>
                            <div className="numero-resumo">
                                Meu Vivo: <span id="lblTelefoneUsuarioLogado">{this.state.policies?this.state.policies.listPolicies[0].phoneNumber:""}</span>
                            </div>
                            <div className="plano-resumo">
                                Produto:
                              <span id="lblDescricaoProduto">{this.getProductName()}</span>
                            </div>
                            <div className="pull-right pull-center-mobile hidden-sm hidden-md hidden-lg">
                                <a href="logout" id="buttonExitAP" title="Sair" className="btn btn-block btn-roxo" onClick={this.props.logout}> Sair </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-md-12 nopadding pull-right box-logado">
                {this.props.cancelar === true && this.showCancelPopUp()}
                {this.props.confirmCancelar === true && this.showConfirmCancelPopUp()}
                {this.props.closeEndCancelationPopUp ===true && this.showEndCancelationPopUp()}
                {this.state.errorCerificado === true && this.showErrorCertificatePopUp()}

        
                    <div id="ContentPlaceHolder1_divProtecaoCelular" className="titulo-box-logado">
                        Vivo Seguro Celular
                        <div className="pull-right hidden-xs">
                            <a href="logout" id="buttonExitAP" title="Sair" className="btn btn-block btn-roxo" onClick={this.props.logout}> Sair </a>
                        </div>
                    </div>
                    <div id="ContentPlaceHolder1_pnlGridProtecaoCelular">
        
                        <div className="table-responsive">
                            <table className="table cabecalhoGrid" cellSpacing="0" rules="all" border="1" id="ContentPlaceHolder1_gvProtecaoCelular" style={{borderCollapse:"collapse"}}>
                                <tbody>
                                    <tr>
                                        
                                      <th scope="col">Equipamento</th><th scope="col">Produto</th><th scope="col">Linha</th><th scope="col">Nº da Sorte</th><th scope="col">Nº Certificado</th><th scope="col">Valor Mensal R$</th><th scope="col">Situação</th><th scope="col">Certificado</th><th scope="col">Selecionar</th><th scope="col">Cancelar</th>
                                        
                                    </tr>
                                    {tablePolicies}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </form>
        </main>);
    }
    getText(id) {
        switch (id) {
            case 1:
                return "Nova";
            case 2:
                return "Ativo";
            case 3:
                return "Caducada";
            case 4:
                return "Cancelada";
            case 5:
                return "Suspensa";
            case 6:
                return "Inválida";
            case 7:
                return "Rejeitada";
            case 9:
                return "Excesso suspenso";
            case 10:
                return "Referida";
            case 11:
                return "Verificando informações";
            default:
                return '';
        }
    }
    getEquipamiento(id){
        switch (id) {
            case 1:
                return "celular";
            case 2:
                return "Tablet";
            case 3:
                return "SmartWatch";
            case 4:
                return "Notebook";
            case 5:
                return "Fone de ouvido";
            default:
                return '';
        }

    }
    newWindow =(policy)=>{
        if(policy.policyStatus===2 || policy.policyStatus===5){
            this.downloadCertificate(policy.policyId);
        }else if(policy.policyStatus===4){
            rpc({
                type: 'AreaPrivadaCheckCertificateChanceGeneration',
                idPolicy: policy.policyId
            }).then((canGenerate) => {
                if(canGenerate===true){
                    this.downloadCertificate(policy.policyId);
                }else{
                    this.setState({errorCerificado:true, textoErrorCerificado:'Estimado cliente, por favor entrar em contato com a Central de Atendimento e solicitar o documento desejado.'});
                }
            });  
        }else if(policy.policyStatus===11){
            this.setState({errorCerificado:true, textoErrorCerificado:'Ops!  Ainda não é possível fazer o upload do certificado pois a contratação do seu seguro ainda não foi finalizada. Em caso de dúvidas, entre em contato conosco pelo telefone 0800 580 0277 ou pelo whatsapp (11) 972878201.'});
        }
    }

    downloadCertificate = (policyId)=>{
        window.open(process.env.PUBLIC_URL + '/FileDownload?policyId=' + policyId, '_blank').print();

    }
    nameProduct = (id)=>{
        if(id==1){
            return "Vivo Seguro Celular Danos";
        }else if(id==2){
            return "Vivo Seguro Celular Roubo";
        }else if(id==3){
            return "Vivo Seguro Celular Roubo + Danos";
        }else{
            return "Unknown";
        }
    }
    getProductName=()=>{
        if(this.props.productName!=undefined){
            return this.props.productName;
        }else if(this.state.policies!=undefined){
            return this.state.policies.listPolicies[0].productName=="Vivo Multiproteção"? 
            "Vivo Seguro Celular Roubo + Danos":this.state.policies.listPolicies[0].productName;
        }else{
            return "";
        }
    }

    showCancelPopUp = () => {

        return <ConfirmPopUp 
            {...this.props}
            {...this.state}
            close={() => {this.props.closeCancelPopUp()}}
            resolve={(result) => result && this.props.openConfirmCancelPopUp(result)} />;
    }

    showEndCancelationPopUp = () => {

        const message = 'O seu seguro foi cancelado. \n A partir de agora você não conta mais com as coberturas do Vivo Seguro Celular. Caso tenha alguma dúvida, permanecemos à disposição no telefone 0800 580 0277 ou pelo whatsapp (11) 972878201.';

        return <GenericPopup 
            message={message}
            close={() => {this.props.closeEndCancelationPopUp()}} />;
    }

    showErrorCertificatePopUp = () => {

        return <GenericPopup 
            title='Erro ao criar o certificado.'
            message={this.state.textoErrorCerificado}
            close={() => {this.closeErrorCertificatePopUp()}} />;
    }

    closeErrorCertificatePopUp = () =>{
        this.setState({errorCerificado:false, textoErrorCerificado:''});
    }

    showConfirmCancelPopUp = () => {
        let message = '';
        if(this.props.idCancelation==='12'){
            message = 'Você sabia que mesmo fazendo a portabilidade da linha para outra operadora você consegue manter o seu aparelho protegido conosco? Basta alterar o meio de pagamento para cartão de crédito, selecionando a aba Meus Pagamentos.';
        } else if(this.props.idCancelation==='11'){
            message = 'Você sabia que mesmo alterando o aparelho você pode mantê-lo protegido? Basta entrar em contato conosco para nos informar seu novo aparelho pelo telefone 0800 580 0277 ou pelo whatsapp (11) 972878201';
        } else{
            message = 'Temos uma oferta especial para você manter seu aparelho protegido com o Vivo Seguro Celular!  Entre em contato conosco pelo telefone 0800 580 0277 ou pelo whatsapp (11) 972878201';
        }
        

        return <ContinuarCancelamentoPopUp 
            {...this.props}
            {...this.state}
            message={message}
            close={() => {this.props.closeConfirmCancelPopUp()}}
            resolve={(result) => result && this.props.cancelarPolicy()} />;
    }

}

export default MeuSeguro;
