import RenderInBody from '../renderInBody';
const React = require('react');

class VerifyLoginPopUp_new extends React.Component {

    constructor(props) {
        super(props);
        this.state = {showPinPopUp: false};
        console.log(window.screen.width);
    }

    render() {
        const header = 
            <div className="modal-header">
                <div className="modal-title">
                    <h5>
                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" width="30px" height="30px" viewBox="0 0 30 30" style={{enableBackground: "new 0 0 30 30"}} space="preserve" className="ico-vivo-faq svg-inject  replaced-svg">
                            <g>
                                <path style={{fill: "#DE8650"}} d="M15,30c-4.8,0-8.5-1.2-11-3.7C1.3,23.8,0,20,0,15c0-5,1.3-8.8,4-11.3C6.5,1.2,10.2,0,15,0   c4.8,0,8.5,1.2,11,3.7c2.6,2.5,4,6.4,4,11.3s-1.3,8.8-4,11.3C23.5,28.8,19.8,30,15,30L15,30z M15,1.9c-4.3,0-7.5,1.1-9.7,3.2   C3,7.2,1.9,10.6,1.9,15c0,4.4,1.1,7.8,3.4,9.9c2.2,2.1,5.4,3.1,9.7,3.1c4.3,0,7.5-1.1,9.7-3.1c2.3-2.2,3.4-5.5,3.4-9.9   c0-4.4-1.1-7.8-3.4-10C22.5,3,19.3,1.9,15,1.9L15,1.9z"></path>
                                <g>
                                    <path style={{fill: "#DE8650"}} d="M14.9,11.2c0.7,0,1.2,0.5,1.2,1.2v11.2c0,0.7-0.5,1.2-1.2,1.2s-1.2-0.5-1.2-1.2V12.4    C13.7,11.7,14.3,11.2,14.9,11.2z"></path>
                                    <path style={{fill: "#DE8650"}} d="M14.9,5.1c1.1,0,2,0.9,2,2s-0.9,2-2,2c-0.3,0-0.6-0.1-0.9-0.2c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.1-0.1-0.2-0.2    C13.2,8.2,13,7.6,13,7.1C13,6,13.9,5.1,14.9,5.1L14.9,5.1z"></path>
                                </g>
                            </g>
                        </svg> Login Vivo
                    </h5>
                </div>
                <a  onClick={this.props.close} className="modal-close"></a>
            </div>
        ;
        
        const body = 
            <div className="modal-body">
                <p className="title last">Olá! Para acessar a Área do Cliente te enviaremos um código por SMS para validar o número do seu celular. </p>
                <p className="title last">Digite o Código que recebeu por SMS</p>
                <div className="modal-form">
                    <label>PIN</label>
                    <input name="pinCode" onChange={this.props.onChangeValueByName} value={this.props.pin} type="text" maxLength="14" id="ContentPlaceHolder1_txtCPF" className="form-control texto" tabIndex="0"/>
                </div>
                {this.props.errorPin && <p className="last" style={{color: 'red'}}>Código incorreto. Por favor, tente novamente, ou solicite o reenvio do seu código clicando no link abaixo.</p>}
                <div className="modal-buttons center" style={{marginBottom: '20px'}}>
                    <a onClick={() => { if(this.props.pinCode && this.props.pinCode != null) this.onClickValidatePIN()}} className="btn-vivo shadow">Avançar</a>
                </div>
                <p className="last">Ainda não recebeu o SMS? Clique em <a onClick={() => this.props.sendSMS(2)} style={{color: '#337ab7', cursor: 'pointer'}}>reenviar</a></p>
                <p className="last">Mesmo após o reenvio não conseguiu receber o SMS? Por favor, entre em contato conosco: Telefone: 0800 580 0277 WhatsApp: 11 97287 8201</p>
            </div>
        ;

        return  (
        <RenderInBody component={
            <div id="modal-wrapper">
                <div className="modal" id="ModalNotaFiscal">
                    {header}{body}
                </div>
                <div id="modal-mask" onClick={this.props.close}></div>
            </div>
        }/>

        );
    }

    submit = () => {
        this.props.resolve(true);
    }

    onClickSendSMS = () => {
        this.props.sendSMS();
    }

    onClickValidatePIN = () => {
        this.props.validatePin();
    }
}

export default VerifyLoginPopUp_new;