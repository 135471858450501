import React, { Component } from 'react';
import rpc from './our/rpc';
import IcoInfo from './img/ico_info_new.svg';
import IcoEscudo from './img/ico_escudo_new.svg';
import ProtecaoCelular from './img/protecao-celular_new.svg';
import ProtecaoSmartwatch from './img/protecao-smartwatch_new.svg';
import ProtecaoTablet from './img/protecao-tablet_new.svg';
import ProtecaoNotebook from './img/protecao-notebook_new.svg';
import RoboHurto from './img/robo-hurto_new.jpg';
import Danos from './img/danos_new.jpg';
import RoboHurtoDanos from './img/robo-hurto-danos_new.jpg';
import IcoReparo from './img/ico_reparo-oficial_new.svg';
import IcoPagamento from './img/ico_pagamento-facil_new.svg';
import IcoConexao from './img/ico_conexao_new.svg';
import IcoEntrega from './img/ico_entrega_new.svg';
import IcoFacilidade from './img/ico_facilidade_new.svg';
import Coste from './img/coste_new.jpg';
import Tranquilidad from './img/tranquilidad_new.jpg';
import IcoCompromiso from './img/ico_compromiso_new.svg';
import IcoReferencia from './img/ico_referencia_new.svg';
import IcoCobertura from './img/ico_cobertura_new.svg';
import BannerItaucard from './img/banner-itaucard_new.jpg';
import BannerVivopay from './img/banner-vivo-pay_new.png';
import Slider from 'react-slick';
import Tippy from '@tippyjs/react';
import DropdownSearch from './global/DropdownSearch';

import HomeBanner1 from './img/back-desctados-escudo.png';
import HomeBanner2 from './img/back-2.png';
import HomeBanner3 from './img/back-seguro-celular.jpg';
import HomeBanner4 from './img/back-seguro-celular-2.jpg';
import LogoSeguro from './img/logo_seguro.svg';
import LogoTech from './img/logo-tech.svg';

import FingerPrint from '@fingerprintjs/fingerprintjs-pro';

export class Home_new extends Component {

    constructor(props) {
        super(props);
        this.state = {
          scrollBottom:0,
          deviceTypeId: undefined,
          brands: undefined,
          brandId: undefined,
          brandName: '',
          models: undefined,
          modelId: undefined,
          modelExtendedName: '',
          products: undefined,
          productId: undefined,
          primaMensual: undefined,
          priceDisable: true
        };
        window.s = window.s || {};
        window.s.pageName = 'vivo:br:seguro movil:home';
        window.s.products = 'vivo:br:seguro movil:';
    }
    /**
     * async function generateFingerPrintPRO() {
            var urlHost = window.location.host
              , base_endpoint = ""
              , cred = "";
            urlHost.includes("simonly") || urlHost.includes("telefonicainsurance") ? (base_endpoint = "https://fpjs.prep.telefonicainsurance.com",
            cred = "Yl8n5YphmEl1hCxuizKc") : (base_endpoint = "https://fpjs.telefonicainsurance.com",
            cred = "M48iHri7NgJwDNn2qrqN");
            const fpId = FingerprintJS.load({
                token: cred,
                endpoint: base_endpoint
            });
            let userToken = $token;
            await fpId.then(fp=>fp.get({
                extendedResult: !0,
                tag: {
                    token: userToken,
                    origin: 1
                }
            })).then(result=>{
                innerFingerPrint = result.visitorId,
                console.log($token),
                console.log(result.visitorId),
                window.location.href = wepAppURL + "?token=" + $token + "&fp=" + result.visitorId
            }
            , error=>{
                errorCallFP = Boolean(!0),
                window.location.href = wepAppURL + "?token=" + $token
            }
            )
        }
        function getIPaddress(ipaddress) {
            var r = /\b\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\b/, a, t = ipaddress.match(r);
            return null != t ? t[0] : "0.0.0.0"
        }
        function generateFingerPrint() {
            var unix = Math.round(+new Date / 1e3);
            f_print.add("ip", $ip),
            f_print.add("timestamp", unix),
            f_print.add("token", $tokenCode),
            f_print.add("tokenNormalizado", $token);
            var parser = new UAParser;
            gUA = parser.getResult(),
            f_print.add("so", gUA.os.name),
            new Fingerprint2({
                preprocessor: function(key, value) {
                    if ("user_agentX" == key) {
                        var parser = new UAParser(value), userAgentMinusVersion;
                        return parser.getOS().name + " " + parser.getBrowser().name
                    }
                    return value
                }
            }).get((function(result, components) {
                components.forEach((function(element) {
                    f_print.add(element.key, element.value)
                }
                )),
                obt = crearStringFPMD5(),
                $.get("https://tim.telefonicainsurance.com/TIMConsole/jsp/wimip.jsp", (function(data) {
                    if (clickBtnDescargaApp)
                        wsfp(obt);
                    else {
                        f_print.add("IPService", getIPaddress(data.trim())),
                        fingerPrint = crearStringFPMD5();
                        var contenidoToken = $("#btnDescargaApp").data("clipboard-text");
                        $("#btnDescargaApp").attr("data-clipboard-text", contenidoToken + ";" + fingerPrint.hash + ";[" + fingerPrint.fingerprint + "]"),
                        clickBtnDescargaApp = !0
                    }
                }
                ))
            }
            ))
        }

     */

    testFP = async () => {
      // Datos PRO
      //  endpoint = https://fpjs.telefonicainsurance.com
      //  token load = M48iHri7NgJwDNn2qrqN

      const base_endpoint = "https://fpjs.telefonicainsurance.com";
      const cred = "M48iHri7NgJwDNn2qrqN";

      // Datos PRE

      // const base_endpoint = "https://fpjs.prep.telefonicainsurance.com";
      // const cred = "Yl8n5YphmEl1hCxuizKc";

      var $tokenCode = 'Rvp8X0LBcc9xhObknOi9Gmt3uEKq1o4QrMgAF4NtSz63NmnRHGQLmCp9Yo7v';
      var $token = 'pCmLQGHRnmN36zStN4FAgMrQ4o1qKEu3tmG9iOnkbOhx9ccBL0';

      const fpId = FingerPrint.load({
        token: cred,
        endpoint: base_endpoint
      });

      let userToken = $token;
      await fpId.then(fp=>fp.get({
          extendedResult: !0,
          tag: {
              token: userToken,
              origin: 1
          }
      })).then(result=>{
          //innerFingerPrint = result.visitorId,
          console.log($token);
          console.log(result.visitorId);//,
          //window.location.href = wepAppURL + "?token=" + $token + "&fp=" + result.visitorId
      }
      , error=>{
          console.log('ERROR FP');
          //errorCallFP = Boolean(!0)//,
          //window.location.href = wepAppURL + "?token=" + $token
      });

      /*const fpPromise = import('https://fpjs.telefonicainsurance.com/v3/oWXb6lzbK3US4SGzfqU')
      .then(FingerprintJS => FingerprintJS.load());

      fpPromise.then(fp => fp.get({extendedResult: true, tag:{"token":'M48iHri7NgJwDNn2qrqN', "origin": 1}})).then(result => console.log(result.visitorId));*/
      /*const fpId = FingerPrint.load({token:'M48iHri7NgJwDNn2qrqN', endpoint:'https://fpjs.telefonicainsurance.com'});
      await fpId.then(fp => {
        fp.get({extendedResult: true, tag:{"token":'Ra5IuFL9GRwLoeSviQEB', "origin": 1}})
      }).then(result => {
        console.log('fp tag: ', result);
      }, (error) => {
        console.log('fp error: ', error);
      });*/
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
        this.setState({scrollBottom: window.pageYOffset+window.innerHeight, cotarAgora: false});
        window.dataLayer.push ({
            'event' : 'asyncGTM.step',
            'GAcategory' : 'vivo:br:seguro movil',
            'GAaction' : 'paso:01:home:acceso',
            'GAlabel' :'vivo:br:seguro movil:'});
        let homeData = {
          deviceTypeId: this.state.deviceTypeId,
          brandId: this.state.brandId,
          brandName: this.state.brandName,
          modelId: this.state.modelId,
          modelExtendedName: this.state.modelExtendedName,
          productId: undefined
        };
        this.props.setHomeData(homeData);
        //this.testFP();
    }

    handleScroll = () => {
        this.setState({scrollBottom: window.pageYOffset+window.innerHeight});
    }

    render() {
        return (
            <main className="" id="customrenov">
                <section className="vivo-home-slider-promo__top">
                  <Slider 
                    dots={true}
                    infinite={true}
                    speed={500}
                    slidesToShow={1}
                    slidesToScroll={1}
                    variableWidth={false}
                  >
                    <div className="slide">
                      <div className="slide-content container">
                        <img className="logo-slider" src={LogoSeguro}/>
                        <p className="p-v2">Seu iPhone <strong className="link-color">sempre protegido.</strong></p>
                        <div className="vivo-home-cabecera__destacado vivo-home-cabecera__destacado-v2">
                          <div className="vivo-home-cabecera__destacado__precio_v2">
                            <span className="b1">
                              <div className="vivo-home-cabecera__destacado__pastilla">Planos a partir de</div>
                              <p className="precio-1">R$</p>
                            </span>
                            <span className="b2">
                              <p className="precio-2">28</p>
                            </span>
                            <span className="b3">
                              <p className="precio-2-1">,50</p>
                              <p className="precio-3">/mês</p>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="slide-bg-img">
                        <img src={HomeBanner3}></img>
                      </div>
                    </div>
                    <div className="slide">
                      <div className="slide-content container">
                        <img className="logo-slider" src={LogoSeguro}/>
                        <p className="p-v2">Seu Samsung Galaxy <strong className="link-color">sempre protegido.</strong></p>
                        <div className="vivo-home-cabecera__destacado vivo-home-cabecera__destacado-v2">
                          <div className="vivo-home-cabecera__destacado__precio_v2">
                            <span className="b1">
                              <div className="vivo-home-cabecera__destacado__pastilla">Planos a partir de</div>
                              <p className="precio-1">R$</p>
                            </span>
                            <span className="b2">
                              <p className="precio-2">10</p>
                            </span>
                            <span className="b3">
                              <p className="precio-2-1">,23</p>
                              <p className="precio-3">/mês</p>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="slide-bg-img">
                        <img src={HomeBanner3}></img>
                      </div>
                    </div>
                    <div className="slide">
                      <div className="slide-content container">
                        <img className="logo-slider" src={LogoTech}/>
                        <p className="p-v2">Proteção para seu <strong className="link-color">smartwatch,
                          tablet ou notebook.</strong></p>
                      </div>
                      <div className="slide-bg-img">
                        <img src={HomeBanner4}/>
                      </div>
                    </div>
                    {/*<div className="slide">
                        <div className="slide-content container">
                          <p className="title">Seguro Celular</p>
                          <p>Seu <strong>Samsung Galaxy</strong> protegido onde você estiver.</p>
                          <div className="vivo-home-cabecera__destacado">
                            <div className="vivo-home-cabecera__destacado__pastilla">Planos a partir de</div>
                            <div className="vivo-home-cabecera__destacado__precio">
                              <div className="vivo-home-cabecera__destacado__precio_wrapper">
                                <p className="precio-1">R$</p>
                                <p className="precio-2">10,23</p>
                                <p className="precio-3">/mês</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="slide-bg-img">
                          <img src={HomeBanner1}/>
                        </div>
                    </div>*/}
                    {/*<div className="slide">
                      <div className="slide-content container">
                        <p className="title">Seguro Celular</p>
                        <p>Seu <strong>iPhone</strong> protegido onde você estiver.</p>
                        <div className="vivo-home-cabecera__destacado">
                          <div className="vivo-home-cabecera__destacado__pastilla">Planos a partir de</div>
                          <div className="vivo-home-cabecera__destacado__precio">
                            <div className="vivo-home-cabecera__destacado__precio_wrapper">
                              <p className="precio-1">R$</p>
                              <p className="precio-2">28,50</p>
                              <p className="precio-3">/mês</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="slide-bg-img">
                        <img src={HomeBanner1}/>
                      </div>
                    </div>*/}
                    {/*<div className="slide">
                      <div className="slide-content container">
                        <p className="title">Seguro Celular e Tech</p>
                        <p>Proteja seus dispositivos contra <strong>roubo, furtos e danos acidentais</strong></p>
                        <a className="btn-vivo" href="#" target="_blank">SAIBA MAIS</a>
                      </div>
                      <div className="slide-bg-img">
                        <img src={HomeBanner2}/>
                      </div>
                    </div> */}   
                  </Slider>
                </section>

                {/* MODIFICAR CALCULE SU SEGURO */}
                <section className="row vivo-home-calculadora">
                    <div className="col-xs-12">
                        <div className="container">
                            <div className="row row-text">
                                <div className="col-xs-12">
                                    <h2>Quanto custa meu seguro?</h2>
                                </div>
                            </div>
                            <div className="row row-calculadora">
                                <div className="col">
                                    <p>Equipamento</p>
                                    <select className="home" name="dispositivos" value={this.state.deviceTypeId} onChange={this.onChangeDeviceType}>
                                        <option value=''>Selecione</option>
                                        <option value='1'>Celular</option>
                                        <option value='2'>Tablet</option>
                                        <option value='3'>Smartwatch</option>
                                        <option value='4'>Notebook</option>
                                        <option value='5'>Fone de ouvido</option>
                                    </select>
                                </div>
                                <div className="col">
                                    <p>Marca</p>
                                    <DropdownSearch
                                      options={this.state.brands}
                                      onChangeSearchWord={this.onChangeBrand}
                                      searchWord={this.state.brandName}
                                      disabled={this.state.deviceTypeId === undefined}
                                      placeHolder="Selecione"
                                      divClassName="dropdown-search-div-home"
                                      inputClassName="dropdown-search-input-home"
                                      listDivClassName="dropdown-search-list-div-home"
                                      listItemClassName="dropdown-search-list-item-home"
                                    />
                                    {/*<datalist id="marca" className='select'>
                                        <option value="">Selecione</option>
                                        {this.state.brands && this.state.brands.map(brand => (
                                                    <option key={brand.id} value={brand.name}></option>
                                                ))}
                                    </datalist>
                                        <input autoComplete='off' list='marca' className="select home" name="marca" disabled={this.state.deviceTypeId === undefined} placeholder='Selecione' value={this.state.brandName} onChange={this.onChangeBrand}/>*/}
                                    {/*<select className="home" name="marca" value={this.state.brandId} onChange={this.onChangeBrand} disabled={this.state.deviceTypeId === undefined}>
                                        <option value="" >Selecione</option>
                                        {this.state.brands && this.state.brands.map(brand => (
                                                        <option key={brand.id} value={brand.id}>
                                                            {brand.name}
                                                        </option>
                                                    ))}
                                        </select>*/}
                                </div>
                                <div className="col">
                                    <p>Modelo</p>
                                    <DropdownSearch
                                      options={this.state.models}
                                      onChangeSearchWord={this.onChangeModel}
                                      searchWord={this.state.modelExtendedName}
                                      disabled={this.state.models === undefined}
                                      placeHolder="Selecione"
                                      divClassName="dropdown-search-div-home"
                                      inputClassName="dropdown-search-input-home"
                                      listDivClassName="dropdown-search-list-div-calcule-model"
                                      listItemClassName="dropdown-search-list-item-home"
                                      optionId="idDevice"
                                      optionName="modelExtendedName"
                                    />
                                    {/*<datalist id="modelo" className='select'>
                                        <option value="">Selecione</option>
                                        {this.state.models && this.state.models && this.state.models.map(model => (
                                                        <option key={model.idDevice} value={model.modelExtendedName}></option>
                                                    ))}
                                    </datalist>
                                        <input autoComplete='off' list='modelo' className="select home" name="modelo" disabled={this.state.models === undefined} placeholder='Selecione' value={this.state.modelExtendedName} onChange={this.onChangeModel}/>*/}
                                    {/*<select className="home" name="modelo" value={this.state.modelId} onChange={this.onChangeModel} disabled={this.state.brandId === undefined}>
                                        <option value="" >Selecione</option>
                                        {this.state.models && this.state.models && this.state.models.map(model => (
                                                        <option key={model.idDevice} value={model.idDevice}>
                                                            {model.modelExtendedName}
                                                        </option>
                                                    ))}
                                    </select>*/}
                                </div>
                                <div className="col">
                                    <a id="clicktoggle" onClick={() => this.onClickCotar()} className="btn-vivo alt shadow">Cotar agora</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* MODIFICAR RESULTADOS DEL SEGURO */}
                <section id="slidetoggle" className="row vivo-home-calculadora-results" style={/*this.state.priceDisable ? */{display: 'none'} /*: {}*/}>
                    <div className="col-xs-12">
                        <div className="container">
                            <div className="row row-calculadora-results">
                                <div className="col col-sm-4 col-xs-4">
                                    <Tippy content={<div className="row-calculadora-results__info__popup one"><p>Proteção completa em caso de:</p><ul className="check verde"><li>Roubo</li><li>Furto simples e qualificado</li></ul></div>} allowHTML={true} arrow={true} animation="shift-away" trigger="mouseenter click" animation="shift-toward" placement="bottom" maxWidth="none" interactive={true}>
                                      <div className="row-calculadora-results__info" id="popover-1">
                                          <h2>Roubo e Furto <span><img src={IcoInfo}/></span></h2>
                                          {/* <div className="row-calculadora-results__info__popup one">
                                              <p>Proteção completa em caso de:</p>
                                              <ul className="check verde">
                                              <li>Roubo</li>
                                              <li>Furto simples e qualificado</li>
                                              </ul>
                                          </div> */ }
                                      </div>
                                    </Tippy>
                                    <div className="row-calculadora-results__precio">
                                        <p className="precio-1">R$</p>
                                        <p className="precio-2">{this.state.primaMensual && this.state.primaMensual.robo.id && this.state.primaMensual.robo.prima[0]}</p>
                                        <p className="precio-3">,{this.state.primaMensual && this.state.primaMensual.robo.id && this.state.primaMensual.robo.prima[1]}<br/>/Mês</p>
                                    </div>
                                    <a onClick={() => {this.onClickContratation(2)}} className="btn-vivo shadow">Contrate Já</a>
                                </div>
                                <div className="col col-sm-4 col-xs-4">
                                  <Tippy content={<div className="row-calculadora-results__info__popup two"><p>Proteção completa em caso de:</p><ul className="check verde"><li>Quebra acidental</li><li>Imersão em líquidos</li><li>Danos elétricos</li></ul></div>} allowHTML={true} arrow={true} animation="shift-away" trigger="mouseenter click" animation="shift-toward" placement="bottom" maxWidth="none" interactive={true}>
                                      <div className="row-calculadora-results__info" id="popover-2">
                                          <h2>Danos acidentais <span><img src={IcoInfo}/></span></h2>
                                          { /*
                                          <div className="row-calculadora-results__info__popup two">
                                              <p>Proteção completa em caso de:</p>
                                              <ul className="check verde">
                                                  <li>Quebra acidental</li>
                                                  <li>Imersão em líquidos</li>
                                                  <li>Danos elétricos</li>
                                              </ul>
                                          </div>*/
                                          }
                                      </div>
                                    </Tippy>
                                    <div className="row-calculadora-results__precio">
                                        <p className="precio-1">R$</p>
                                        <p className="precio-2">{this.state.primaMensual && this.state.primaMensual.danos.id && this.state.primaMensual.danos.prima[0]}</p>
                                        <p className="precio-3">,{this.state.primaMensual && this.state.primaMensual.danos.id && this.state.primaMensual.danos.prima[1]}<br/>/Mês</p>
                                    </div>
                                    <a onClick={() => {this.onClickContratation(1)}} className="btn-vivo shadow">Contrate Já</a>
                                </div>
                                <div className="col col-sm-4 col-xs-4">
                                    <Tippy content={<div className="row-calculadora-results__info__popup three"><p>Proteção completa em caso de:</p><ul className="check verde"><li>Roubo</li><li>Furto simples e qualificado</li><li>Quebra acidental</li><li>Imersão em líquidos</li><li>Danos elétricos</li></ul></div>} allowHTML={true} arrow={true} animation="shift-away" trigger="mouseenter click" animation="shift-toward" placement="bottom" maxWidth="none" interactive={true}>
                                      <div className="row-calculadora-results__info" id="popover-3">
                                        <h2>Roubo e Furto + Danos <span><img src={IcoInfo}/></span></h2>
                                        {/*
                                        <div className="row-calculadora-results__info__popup three">
                                            <p>Proteção completa em caso de:</p>
                                            <ul className="check verde">
                                                <li>Roubo</li>
                                                <li>Furto simples e qualificado</li>
                                                <li>Quebra acidental</li>
                                                <li>Imersão em líquidos</li>
                                                <li>Danos elétricos</li>
                                            </ul>
                                        </div>
                                        */}
                                      </div>
                                    </Tippy>
                                    <div className="row-calculadora-results__precio">
                                        <p className="precio-1">R$</p>
                                        <p className="precio-2">{this.state.primaMensual && this.state.primaMensual.multi.id && this.state.primaMensual.multi.prima[0]}</p>
                                        <p className="precio-3">,{this.state.primaMensual && this.state.primaMensual.multi.id && this.state.primaMensual.multi.prima[1]}<br/>/Mês</p>
                                    </div>
                                    <a onClick={() => {this.onClickContratation(3)}} className="btn-vivo shadow">Contrate Já</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="row vivo-home-protecao">
                  <div className="col-xs-12">
                    <div className="container">
                      <div className="row row-text">
                        <div className="col-xs-12">
                          <h2>Proteção para todos os seus dispositivos</h2>
                          <div className="card">
                            <img src={IcoEscudo}/>
                            <p>Agora você também pode proteger todos os seus dispositivos com as mesmas coberturas</p>
                          </div>
                        </div>
                      </div>
                      <div className="row tipos-de-proteccion">
                        <div className="col-sm-3 col-xs-6">
                          <img src={ProtecaoCelular}/>
                          <p>Celular</p>
                        </div>
                        <div className="col-sm-3 col-xs-6">
                          <img src={ProtecaoSmartwatch}/>
                          <p>Smartwatch</p>
                        </div>
                        <div className="col-sm-3 col-xs-6">
                          <img src={ProtecaoTablet}/>
                          <p>Tablet</p>
                        </div>
                        <div className="col-sm-3 col-xs-6">
                          <img src={ProtecaoNotebook}/>
                          <p>Notebook</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="row vivo-home-aparelho">
                  <div className="col-xs-12">
                    <div className="container">
                      <div className="row row-text">
                        <div className="col-xs-12">
                          <h2>Seguro aparelho flexível</h2>
                          <p>Você escolhe a cobertura que precisa</p>
                        </div>
                      </div>
                      <div className="row tipos-de-aparelho">
                        <div className="col col-sm-4 col-xs-12">
                          <img src={RoboHurto}/>
                          <h3>Roubo e Furto</h3>
                          <p>Proteção completa em caso de:</p>
                          <ul className="check verde">
                            <li>Roubo</li>
                            <li>Furto simples e qualificado</li>              
                          </ul>
                          <a onClick={() => {this.onClickBannerContratation(1)}} className="btn-vivo shadow">Calcular</a>
                        </div>
                        <div className="col col-sm-4 col-xs-12">
                          <img src={Danos}/>
                          <h3>Danos acidentais</h3>
                          <p>Proteção completa em caso de:</p>
                          <ul className="check verde">
                            <li>Quebra acidental</li>
                            <li>Imersão em líquidos</li>
                            <li>Danos elétricos</li>
                          </ul>
                          <a onClick={() => {this.onClickBannerContratation(2)}} className="btn-vivo shadow">Calcular</a>
                        </div>
                        <div className="col col-sm-4 col-xs-12">
                          <img src={RoboHurtoDanos}/>
                          <h3>Roubo e Furto + Danos</h3>
                          <p>Proteção completa em caso de:</p>
                          <ul className="check verde">
                            <li>Roubo</li>
                            <li>Furto simples e qualificado</li>
                            <li>Quebra acidental</li>
                            <li>Imersão em líquidos</li>
                            <li>Danos elétricos</li>
                          </ul>
                          <a onClick={() => {this.onClickBannerContratation(3)}} className="btn-vivo shadow">Calcular</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="row vivo-home-porque">
                  <div className="col-xs-12">
                    <div className="container">
                      <div className="row row-text">
                        <div className="col-xs-12">
                          <h2>Por que contratar os seguros da Vivo?</h2>
                          <p>Sabemos o quanto o seu aparelho é especial e por isso a Vivo disponibiliza o melhor serviço para você.</p>
                        </div>
                      </div>
                      <div className="row vivo-home-porque__lista">
                        <div className="col">
                          <img src={IcoReparo}/>
                          <h3>Reparo oficial</h3>
                          <p>Assistências autorizadas e peças originais.</p>
                        </div>
                        <div className="col">
                          <img src={IcoPagamento}/>
                          <h3>Pagamento fácil</h3>
                          <p>Pague através da sua fatura Vivo.</p>
                        </div>
                        <div className="col">
                          <img src={IcoConexao}/>
                          <h3>Conexão Vivo</h3>
                          <p>Você terá toda atenção de um cliente Vivo.</p>
                        </div>
                        <div className="col">
                          <img src={IcoEntrega}/>
                          <h3>Entrega rápida</h3>
                          <p>Você escolhe o endereço e a entrega é por nossa conta.</p>
                        </div>
                        <div className="col">
                          <img src={IcoFacilidade}/>
                          <h3>Facilidade</h3>
                          <p>Acione seu seguro de forma rápida e digital.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="row vivo-home-coste">
                  <div className="col-xs-12">
                    <div className="container">
                      <div className="row row-text">
                        <div className="col-xs-12">
                          <h2>O custo de ficar sem aparelho</h2>
                        </div>
                      </div>
                      <div className="row row-coste">
                        <div className="col col-lg-4 col-sm-12 col-xs-12">
                          <ul className="check rojo">
                            {/*<li>Ter que arcar com o valor integral de um novo aparelho</li>*/}
                            <li>Valor integral de um novo aparelho</li>
                            <li>Custo elevado com conserto</li>
                            <li>Reparos sem procedência ou garantia</li>
                          </ul>
                        </div>
                        <div className="col col-lg-4 col-sm-12 col-xs-12">
                          <img src={Coste}/>
                        </div>
                        <div className="col col-lg-4 col-sm-12 col-xs-12">
                          <ul className="check rojo">
                            <li>Ficar desconectado de quem você gosta</li>
                            <li>Não ter acesso às redes sociais e aos seus principais aplicativos</li>
                            <li>Perder a chance de registrar aquele momento inesquecível</li>
                          </ul>
                        </div>
                      </div>
                      <div className="row row-barra">
                        <div className="col col-xs-12">
                          <p>Veja como o seguro pode ajudar você</p>
                          <a onClick={() => {this.onClickBannerContratation(4)}} className="btn-vivo alt shadow">Cotar agora</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="row vivo-home-tranquilidad">
                  <div className="col-xs-12">
                    <div className="container">
                      <div className="row row-text">
                        <div className="col-xs-12">
                          <h2>Use seu aparelho com tranquilidade e conte conosco em caso de imprevistos indesejáveis!</h2>
                        </div>
                      </div>
                      <div className="row row-tranquilidad">
                        <div className="col col-sm-5 col-xs-12">
                          <img src={Tranquilidad}/>
                        </div>
                        <div className="col col-sm-7 col-xs-12">
                          <p>Dicas de Segurança</p>
                          <ul className="check verde">
                            <li>Sempre ative a tela de bloqueio do seu celular</li>
                            <li>Ative também a autenticação em 2 fatores, sempre que possível</li>
                            <li>Use senhas alfanuméricas e variadas. Usar um gerenciador de senhas é uma ótima opção</li>
                            <li>Use redes públicas de wi-fi em último caso</li>
                            <li>Nunca clique em links suspeitos, cuja origem é desconhecida</li>
                            <li>Contrate o Seguro da Vivo que protege seus dispositivos contra imprevistos!</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="row vivo-home-escoger">
                  <div className="col-xs-12">
                    <div className="container">
                      <div className="row row-text">
                        <div className="col-xs-12">
                          <h2>Por que escolher os seguros da Vivo?</h2>
                          {/*<p>A Zurich tem parceria com a Vivo desde 2012 para a comercialização do Seguro Celular em todo o Brasil.</p>*/}
                        </div>
                      </div>
                      <div className="row vivo-home-escoger__lista">
                        <div className="col col-sm-4 col-xs-12">
                          <img src={IcoCompromiso}/>
                          <p>Compromisso com a satisfação dos nossos clientes</p>
                        </div>
                        <div className="col col-sm-4 col-xs-12">
                          <img src={IcoReferencia}/>
                          <p>Referência no setor de seguros de tecnologia</p>
                        </div>
                        <div className="col col-sm-4 col-xs-12">
                          <img src={IcoCobertura}/>
                          <p>A melhor cobertura do mercado</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="row vivo-home-slider-promo">
                  <div className="col-xs-12">
                    <div className="container">
                      <div className="row row-text">
                        <div className="col-xs-12">
                          <h2>A Vivo também pode te ajudar com outros serviços:</h2>
                        </div>
                      </div>
                      <div className="vivo-home-slider-promo__slider">
                        <Slider 
                          dots={true}
                          infinite={true}
                          speed={500}
                          slidesToShow={1}
                          slidesToScroll={1}
                        >
                          <div className="slide">
                            <div className="slide-content">
                              <p className="title">Cartão Vivo Itaú</p>
                              <p>parcelamento em 18x e até 10% de cashback para vocé</p>
                              <a className="btn-vivo" href="https://www.itau.com.br/cartoes/escolha/formulario/vivo-itaucard-cashback-visa-platinum.html?utm_source=vivo&utm_medium=parceria&utm_campaign=portalseguros" target="_blank">PEÇA JÁ O SEU</a>
                              <p className="small">*Para compras a partir de R$ 1.500.000</p>
                            </div>
                            <div className="slide-bg-img">
                              <img src={BannerItaucard}/>
                            </div>
                          </div>
                          {/*<div className="slide">
                            <div className="slide-content">
                              <p className="title">Vivo Pay</p>
                              <p>A conta digital que te dá bônus de internet</p>
                              <a className="btn-vivo" href="https://www.vivo.com.br/para-voce/produtos-e-servicos/ofertas/vivo-pay?utm_source=seguro-celular&utm_medium=LP&utm_campaign=lp-seguros-vivo-pay" target="_blank">SAIBA MAIS</a>
                            </div>
                            <div className="slide-bg-img">
                              <img src={BannerVivopay}/>
                            </div>
                          </div>*/}
                        </Slider>
                      </div>
                    </div>
                  </div>
                </section>
            </main>
        );
    }

    onChangeValueByName = (e) => {
        this.setState({ ...this.state, [e.target.name]: e.target.value });
    }

    onClickBannerContratation = (origen) => {
      let gaLabel = 'vivo:br:seguro movil:';
      if (this.state.brandId !== undefined) {
        gaLabel = gaLabel + this.state.brandName;
      }
      if (this.state.modelId !== undefined) {
        gaLabel = gaLabel + ' ' + this.state.modelExtendedName;
      }
      switch(origen){
          case 1:
              window.dataLayer.push ({
                  'event' : 'asyncGTM',
                  'GAcategory' : 'vivo:br:seguro movil',
                  'GAaction' : 'home:boton ir a calcular',
                  'GAlabel' :gaLabel,
                  'localizacion' : 'banner planes:roubo e furto qualificado'});
              break;

          case 2:
              window.dataLayer.push ({
                'event' : 'asyncGTM',
                'GAcategory' : 'vivo:br:seguro movil',
                'GAaction' : 'home:boton ir a calcular',
                'GAlabel' :gaLabel,
                'localizacion' : 'danos acidentais'});
              break;
          case 3:
              window.dataLayer.push ({
                'event' : 'asyncGTM',
                'GAcategory' : 'vivo:br:seguro movil',
                'GAaction' : 'home:boton ir a calcular',
                'GAlabel' :gaLabel,
                'localizacion' : 'banner planes:roubo e furto mas danos'});
              break;
          case 4:
              window.dataLayer.push ({
                  'event' : 'asyncGTM',
                  'GAcategory' : 'vivo:br:seguro movil',
                  'GAaction' : 'home:boton ir a calcular',
                  'GAlabel' :gaLabel,
                  'localizacion' : 'banner gasto no tener seguro'});
              break;
          default: 
          break;
      }

      this.props.history.push('/Contratation/');

    }

    onClickContratation = (origen) =>{
      let homeData = {
        deviceTypeId: this.state.deviceTypeId,
        brandId: this.state.brandId,
        brandName: this.state.brandName,
        modelId: this.state.modelId,
        modelExtendedName: this.state.modelExtendedName,
        productId: undefined
      };
        switch(origen){
            case 1:
                window.dataLayer.push ({
                    'event' : 'asyncGTM',
                    'GAcategory' : 'vivo:br:seguro movil',
                    'GAaction' : 'paso:02:contratacion:calculo:04:coberturas',
                    'GAlabel' :'vivo:br:seguro movil:' + homeData.brandName + ' ' + homeData.modelExtendedName,
                    'modalidad' : 'danos acidentais',
                    'prima' : this.state.primaMensual.danos.prima[0] + ',' + this.state.primaMensual.danos.prima[1]
                  });
                    homeData.productId = this.state.primaMensual !== undefined && this.state.primaMensual.danos !== undefined ? this.state.primaMensual.danos.id : undefined;
                break;

            case 2:
                window.dataLayer.push ({
                    'event' : 'asyncGTM',
                    'GAcategory' : 'vivo:br:seguro movil',
                    'GAaction' : 'paso:02:contratacion:calculo:04:coberturas',
                    'GAlabel' :'vivo:br:seguro movil:' + homeData.brandName + ' ' + homeData.modelExtendedName,
                    'modalidad' : 'roubo e furto',
                    'prima' : this.state.primaMensual.robo.prima[0] + ',' + this.state.primaMensual.robo.prima[1]
                  });
                homeData.productId = this.state.primaMensual !== undefined && this.state.primaMensual.robo !== undefined ? this.state.primaMensual.robo.id : undefined;
                break;
            case 3:
                window.dataLayer.push ({
                    'event' : 'asyncGTM',
                    'GAcategory' : 'vivo:br:seguro movil',
                    'GAaction' : 'paso:02:contratacion:calculo:04:coberturas',
                    'GAlabel' :'vivo:br:seguro movil:' + homeData.brandName + ' ' + homeData.modelExtendedName,
                    'modalidad' : 'roubo e furto + danos',
                    'prima' : this.state.primaMensual.multi.prima[0] + ',' + this.state.primaMensual.multi.prima[1]
                  });
                homeData.productId = this.state.primaMensual !== undefined && this.state.primaMensual.multi !== undefined ? this.state.primaMensual.multi.id : undefined;
                break;
            default: 
            break;
        }

        this.props.setHomeData(homeData);
        this.props.history.push('/Contratation/');
        
    }

    onChangeDeviceType = (e) =>{
      const idSubtype = e.target.value;
      const subtypeText = idSubtype == 1 ? 'celular' :
                          idSubtype == 2 ? 'tablet' :
                          idSubtype == 3 ? 'smartwatch' : 
                          idSubtype == 4 ? 'notebook' : 'Fone de ouvido';
      if (idSubtype !== undefined && idSubtype !== '') {
          this.setState({ primaMensual: undefined, products: undefined, productId: undefined, brands: undefined, brandId: undefined, brandName: '', models: undefined, modelId: undefined, modelExtendedName: '', priceDisable: true });
          rpc({
              type: 'GetAllBrandDeviceWithModelCurrentlySoldByIdSubtype',
              idSubtype
          }).then((brands) => {
              if (brands !== undefined && brands !== null) {
                  this.setState({ deviceTypeId: idSubtype, brands });
                  window.dataLayer.push({
                    'event': 'asyncGTM',
                    'GAcategory': 'vivo:br:seguro movil',
                    'GAaction': 'paso:02:contratacion:calculo:01.5:dispositivo',
                    'GAlabel': 'vivo:br:seguro celular:',
                    'dispositivo': subtypeText
                  });
              }
          });
      }
    }

    onChangeBrand = (e) => {
      let brandName = undefined;
      if (e.target.value !== undefined) {
          brandName = e.target.value.toUpperCase();
      }

      const brand = this.state.brands.find((brand) => { return brand.name.toUpperCase() === brandName });
      let brandId = undefined;
      if (brand !== undefined) {
          brandId = brand.id;
      } else {
          this.setState({ brandName, brandId: undefined, models: undefined, modelId: undefined, modelExtendedName: '', priceDisable: true})
      }
      //const brandId = e.target.value;

      if (brandId !== undefined && brandId !== '') {
        this.setState({ primaMensual: undefined, modelId: undefined, priceDisable: true });
          rpc({
              type: 'GetModelDevicesByBrandCatalogIdAndIdSubtype',
              idBrand: brandId,
              idSubtype: this.state.deviceTypeId,
          }).then((devices) => {
              this.setState({ brandName, brandId, models: devices });
              window.dataLayer.push({
                'event': 'asyncGTM',
                'GAcategory': 'vivo:br:seguro movil',
                'GAaction': 'paso:02:contratacion:calculo:02:marca',
                'GAlabel': 'vivo:br:seguro celular:' + brandName,
                'marca': brandName
              });
          });
      }
    }

    onChangeModel = (e) => {

      let modelExtendedName = undefined;
      if (e.target.value !== undefined) {
          modelExtendedName = e.target.value.toUpperCase();
      }

      const device = this.state.models.find((device) => { return device.modelExtendedName.toUpperCase() === modelExtendedName });
      let modelId = undefined;
      if (device !== undefined) {
          modelId = device.idDevice;
      } else {
          this.setState({ modelExtendedName, modelId: undefined, productId: undefined, priceDisable: true })
      }

      if (modelId === undefined)
          return;

      //const modelId = e.target.value;

      //if (modelId === undefined || modelId === '')
        //return;

      this.setState({ primaMensual: undefined, priceDisable: true });

      /*let primas = {danos: {prima: undefined, id: undefined}, robo: {prima: undefined, id: undefined}, multi: {prima: undefined, id: undefined}};

      rpc({
          type: 'GetListProductsPortalOSCPPublicByIdSubtype',
          idSubtype: this.state.deviceTypeId,
          productType: this.props.isItau ? 2 : 0
      }).then((products) => {
          products.map(product => {
            rpc({
              type: 'SelectBandByidDeviceAndidProduct',
              idDevice: modelId,
              idProduct: product.id,
              idSubtype: this.state.deviceTypeId,
              isItau: this.props.isItau ? 2 : 0
            }).then(bandResult => {
              if (this.props.isVivoEmployee) {
                rpc({
                    type: 'SelectActivePromotionByIdProductAndIdPromotion',
                    idProduct: product.id,
                    idPromotion: 10, //es el id de vivoEmployee
                }).then((promotion) => {
                    let prima = bandResult.band.primaMensual * (100 - promotion.discountPercent) / 100;
                    prima = parseFloat(prima).toFixed(2);
                    let dividePrice = prima.toString().split(".");
                    if (dividePrice.length === 1) {
                        dividePrice.push("00");
                    }
                    if (product.id === 1 || product.id === 20) {
                      primas.danos.prima = dividePrice;
                      primas.danos.id = product.id;
                    } else if (product.id === 2 || product.id === 21) {
                      primas.robo.prima = dividePrice;
                      primas.robo.id = product.id;
                    } else {
                      primas.multi.prima = dividePrice;
                      primas.multi.id = product.id;
                    }
                });
              } else {
                let prima = bandResult.band.primaMensual;
                prima = parseFloat(prima).toFixed(2);
                let dividePrice = prima.toString().split(".");
                if (dividePrice.length === 1) {
                    dividePrice.push("00");
                }
                if (product.id === 1 || product.id === 20 || product.id === 38 || product.id === 31 || product.id === 34) {
                  primas.danos.prima = dividePrice;
                  primas.danos.id = product.id;
                } else if (product.id === 2 || product.id === 21 || product.id === 39 || product.id === 32 || product.id === 35 || product.id === 37) {
                  primas.robo.prima = dividePrice;
                  primas.robo.id = product.id;
                } else {
                  primas.multi.prima = dividePrice;
                  primas.multi.id = product.id;
                }
              }
            })
          });*/
          this.setState({ modelExtendedName, /*primaMensual: primas,*/ modelId });
          window.dataLayer.push({
            'event': 'asyncGTM',
            'GAcategory': 'vivo:br:seguro movil',
            'GAaction': 'paso:02:contratacion:calculo:03:modelo',
            'GAlabel': 'vivo:br:seguro celular:' + this.state.brandName + ' ' + modelExtendedName,
            'modelo': modelExtendedName,
            'precioAparelho': device.actualPrice
          });
            
      //});
  }

  onClickCotar() {
    this.onClickContratation();
    /*let priceDisable = this.state.primaMensual === undefined || this.state.primaMensual.danos.id === undefined || this.state.primaMensual.robo.id === undefined || this.state.primaMensual.multi.id === undefined;
    this.setState({priceDisable, cotarAgora: !priceDisable});
    if (!priceDisable) {
      window.dataLayer.push({
        'event': 'asyncGTM',
        'GAcategory': 'vivo:br:seguro movil',
        'GAaction': 'home:boton calcular',
        'GAlabel': 'vivo:br:seguro celular:' + this.state.brandName + ' ' + this.state.modelExtendedName,
        'localizacion': 'banner principal'
      }); 
      let primas = {
        danos: new Number(this.state.primaMensual.danos.prima[0] + ',' + this.state.primaMensual.danos.prima[1]),
        robo: new Number(this.state.primaMensual.robo.prima[0] + ',' + this.state.primaMensual.robo.prima[1]),
        multi: new Number(this.state.primaMensual.multi.prima[0] + ',' + this.state.primaMensual.multi.prima[1])
      }
      let prima = undefined;
      if (primas.danos <= primas.robo && primas.danos <= primas.multi) {
        prima = primas.danos;
      } else if (primas.robo <= primas.danos && primas.robo <= primas.multi) {
        prima = primas.robo;
      } else {
        prima = primas.multi;
      }
      window.dataLayer.push({
        'event': 'asyncGTM',
        'GAcategory': 'vivo:br:seguro movil',
        'GAaction': 'paso:02:contratacion:calculo:04.5:visualización prima',
        'GAlabel': 'vivo:br:seguro celular:' + this.state.brandName + ' ' + this.state.modelExtendedName,
        'prima': prima
      }); 
    }*/
  }
}

export default Home_new;