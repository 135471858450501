import React, { Component } from 'react';
import VivoHome from './img/vivo-home-s1.svg';
import rpc from './our/rpc';
import AvanzarPopUp from './popUps/ErrorPopUp.jsx';

export class Colaborador_new extends Component {
    render() {
        return (
            <main>
                <section className="row vivo-colaborador">
                    <div className="col-xs-12">
                        <div className="container">
                            <div className="row row-colaborador">
                                <div className="col col-xs-12">
                                    <div className="row">
                                        <div className="col-img col-lg-4 col-sm-5 col-xs-12">
                                            <img src={VivoHome}/>
                                        </div>
                                        <div className="col-lg-8 col-sm-7 col-xs-12 vivo-colaborador-form">
                                            <h3>Oferta exclusiva para você!</h3>
                                            <h2>Agora você pode contratar as coberturas do Vivo Seguro Celular por um preço especial!</h2>
                                            <h2>Informe seus dados para continuar:</h2>
                                            <div className="colaborador-form">
                                                <div>
                                                    <label>Matricula</label>
                                                    <input name="ctl00$ContentPlaceHolder1$txtTelefone" type="text" maxLength="15" id="ContentPlaceHolder1_txtTelefone" className="form-control texto" onChange={this.props.onChangeVivoPk} tabindex="0"/>		            
                                                </div>
                                                <div>
                                                    <label>&nbsp;</label>
                                                    <a onClick={this.onClickContratation} className="btn-vivo shadow">Avançar</a>		            
                                                </div>
                                            </div>
                                            <p className="help-block" style={{fontStyle:"italic", fontSize:'13px'}}>
                                                <small>*Importante: A contratação do seguro com valor promocional é exclusivo para colaboradores Vivo e limitado a um seguro com desconto por RE.</small>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {this.state && this.state.avanzarVisible && <AvanzarPopUp close={() => this.setState({avanzarVisible: false})} />}
            </main>
        );
    }

    onClickContratation = () =>{
        var integer = parseInt(this.props.vivoPK, 10);
        if(!isNaN(integer)){
            rpc({
                type: 'IsVivoEmployeeByVivoPK',
                vivoPK: this.props.vivoPK,
            }).then((isVivoEmployee) => {
                if(isVivoEmployee==1){
                    this.props.isVivoEmployee();
                }
                this.props.history.push('/Contratation');
            });
        }else{
            this.setState({avanzarVisible: true});
        }
    }
}

export default Colaborador_new;