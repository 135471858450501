import React, { Component } from 'react'
import rpc from '../our/rpc';
import moment from 'moment';
import '../fonts/vivo/vivo.css';
import '../fonts/officina/officina.css';
import '../fonts/roboto/roboto.css';
import '../fonts/font-awesome/css/font-awesome.min.css';
import GenericPopup from '../popUps/GenericPopUp';

export class MeusPagamentos extends Component {

    constructor(props) {
        super(props);
        this.state = { pagementOption: '', resultPayment: undefined };
    }

    componentWillMount() {

        rpc({
            type: 'GetIpOfPaymentGateWay'
        }).then((url) => {
            this.setState({ urlPaymentGateWay: url });
        });
        if (this.props.client !== undefined && this.props.payments !== undefined && this.props.policy !== undefined) {
            this.setState({ client: this.props.client, policy: this.props.policy, payments: this.props.payments, idSubtype: this.props.idSubtype  });
        } else {
            rpc({
                type: 'AreaPrivadaSelectClient',
                idPolicy: this.props.idPolicy
            }).then((result) => {
                this.setState({ client: result.client, policy: result.policy, payments: result.payments, idSubtype: result.idSubtype });
            });
        }

        const splitResult = this.props.location.search.split("=");
        if (splitResult.length > 1) {
            if (splitResult[1] === 'OK') {
                this.setState({ resultPayment: true })
            } else if (splitResult[1] === 'KO') {
                this.setState({ resultPayment: false })
            }
        }



    }



    render() {
        const payments = this.state.payments ? this.state.payments.map((payment) => {
            return (<tr>
                <td>{this.getEquipamiento(this.state.idSubtype)}</td>
                <td>R$ {payment.amount}</td>
                <td>{moment(new Date(payment.paymentDate)).format("DD/MM/YYYY")}</td>
                <td>{this.statusPayment(payment.status)}</td>
            </tr>);
        }) : <div></div>;



        return (
            <main className="container content nopadding" id="customrenov">
                {this.state.resultPayment !== undefined && this.showCreditCardPopUp()}
                <div className="container text-center mt-60 mb-20 mt-20-mobile mb-0-mobile">

                    <h2 className="roboto-font">Gerencie seu Seguro</h2>

                </div>
                <div id="divIdentificacao" className="container nopadding">
                    <div className="col-xs-12 flex-center-1-column mt-40 mb-40 mb-20-mobile" style={{ marginTop: "20px" }}>
                        <div className="resumo">
                            <div className="usuario-resumo">
                                Olá: <span id="lblNomeUsuarioLogado">{this.state.client && this.state.client.name ? this.state.client.name : ""}</span>
                            </div>
                            <div className="numero-resumo">
                                Linha: <span id="lblTelefoneUsuarioLogado">{this.state.client && this.state.client.phoneNumber1 ? this.state.client.phoneNumber1 : ""}</span>
                            </div>
                            <div className="plano-resumo">
                                Produto: <span id="lblDescricaoProduto">{this.state.policy ? this.getProductName() : ""}</span>
                            </div>
                            <div className="pull-right pull-center-mobile hidden-sm hidden-md hidden-lg">
                                <a name="Sair" href="logout" title="Sair" id="buttonExitAP" className="btn btn-block btn-roxo" onClick={this.props.logout}> Sair </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="ContentPlaceHolder1_AcompanhamentoPagamento_Titulo" className="titulo-box-logado">
                    Histórico de pagamento
            <div className="pull-right hidden-xs">
                        <a name="ctl00$btnSair" href="logout" id="buttonExitAP" title="Sair" className="btn btn-block btn-roxo" onClick={this.props.logout}> Sair </a>
                    </div>
                </div>
                <div>
                    {this.props.idPolicy !== undefined ?
                        <div id="ContentPlaceHolder1_AcompanhamentoPagamento_Panel1">
                            <div className="table-responsive">
                                <table className="table cabecalhoGrid" cellspacing="0" rules="all" border="1" id="ContentPlaceHolder1_AcompanhamentoPagamento_grdDadosPagamento" style={{ borderCollapse: "collapse" }}>
                                    <tbody>
                                        <tr className="auto-style1">
                                            <th scope="col">Equipamento</th><th scope="col">Valor</th><th scope="col">Refer&ecirc;ncia</th><th scope="col">Status do Pagamento</th>
                                        </tr>
                                        {payments}
                                    </tbody>
                                </table>
                            </div>


                            <div className="row">
                                <div className="col-md-3">
                                    <div className="form-group">
                                        {this.state.policy && (this.state.policy.status === 2 || this.state.policy.status === 5) ?
                                            <div>
                                                <label>Alterar o meio de pagamento ou efetuar alteração dos dados do cartão de crédito</label>
                                                <select name="ctl00$pagamentoOption" id="pagamentoOption" className="form-control" onChange={this.onChangePaymentMethod}>
                                                    <option value='n' selected >Selecione</option>
                                                    <option value="1" >Alterar o meio de pagamento para cartão de crédito</option>
                                                    <option value="2" >Alterar dados do cartão de crédito</option>
                                                    <option value="3" >Alterar o meio de pagamento para cartão de crédito itaucard</option>
                                                    <option value="4" >Alterar dados do cartão de crédito para itaucard</option>
                                                </select>
                                                <button className="btn btn-roxo" value="Enviar" name="Enviar" onClick={this.onClickSendPayment}>Enviar</button>
                                            </div>
                                            : <></>}
                                        
                                    </div>

                                </div>
                            </div>
                        </div> :
                        <label> <br />Não há nenhum certificado selecionado, volte ao meu seguro e selecione um certificado para continuar.</label>
                    }
                </div>
            </main>
        );
    }
    statusPayment = (id) => {
        var status;
        if (id == null || id == 1 || id == 6 || id == 8 || id == 0) {
            status = "Pendente";
        } else if (id == 2 || id == 4 || id == 5 || id == 7) {
            status = "Não Efetuado";
        } else if (id == 3) {
            status = "Efetuado";
        } else {
            status = null;
        }
        return status;

    }
    getEquipamiento(id){
        switch (id) {
            case 1:
                return "Movil";
            case 2:
                return "Tablet";
            case 3:
                return "SmartWatch";
            case 4:
                return "Notebook";
            case 5:
                return "Fone de ouvido";
            default:
                return '';
        }

    }

    showCreditCardPopUp = () => {
        if (this.state.resultPayment === true) {
            return <GenericPopup close={this.closeCreditPopUp}
                title='Cartão de crédito'
                message='O cartão de crédito foi registrado corretamente' />;
        } else {
            return <GenericPopup close={this.closeCreditPopUp}
                title='Cartão de crédito'
                message='O cartão de crédito não pôde ser registrado' />;
        }

    }
    closeCreditPopUp = () => {
        this.setState({ resultPayment: undefined })
    }

    onClickSendPayment = () => {
        var cardType = 0;
        const path = window.location.pathname.split('/');
        // let url = window.location.origin + '/' + path[1]; //UAT
        let url = window.location.origin; //PRO
        
        let urlOK = "";
        const urlKO = url + "/failPayment.jsp";

        if (this.state.paymentMethod == 1 || this.state.paymentMethod == 3) {
            urlOK = url + "/correctPayment.jsp";
        } else if (this.state.paymentMethod == 2 || this.state.paymentMethod == 4) {
            urlOK = url + "/correctPayment.jsp?zeroDollar=true";
        } else {
            return;
        }

        if (this.state.paymentMethod == 3 || this.state.paymentMethod == 4) {
            cardType = 1;
        }

        rpc({
            type: 'SetUpdatePolicyCreditCardType',
            idPolicy: this.props.idPolicy,
            cardType,
            creationDate: new Date()
        }).then((response) => {
        });

        this.sendPayment(this.state.urlPaymentGateWay, {
            zeroDollar: true, isRetry: 'false', policyNumber: this.state.policy.policyNumber, amount: this.state.policy.grossPremium, customerTrust: '02',
            customerData: this.state.client.name + " " + this.state.client.surname, customerEmail: this.state.client.emailAddress,
            urlOK: urlOK,
            urlKO: urlKO
        }, 'post', this.state.paymentMethod == 2 || this.state.paymentMethod == 4);
    }

    onChangePaymentMethod = (e) => {
        this.setState({ paymentMethod: e.target.value });
    }

    sendPayment = (path, params, method = 'post', zeroDollar) => {
        rpc({
            type: 'AreaPrivadaGetPaymentTokenURL',
            dataSubmitted: JSON.stringify(params)
        }).then((result) => {
            params.urlOK = result.urlOK;
            params.urlKO = result.urlKO;
            rpc({
                type: 'InsertExternalPaymentMsgHistory',
                urlSubmitted: path,
                dataSubmitted: JSON.stringify(params),
                policyNumberSubmitted: params.policyNumber,
                zeroDollarSubmitted: zeroDollar ? 1 : 0,
                tokenSubmitted: result.token
            }).then((response) => {
            });

            const form = document.createElement('form');
            form.method = method;
            form.action = path;
            for (const key in params) {
                if (params.hasOwnProperty(key)) {
                    const hiddenField = document.createElement('input');
                    hiddenField.type = 'hidden';
                    hiddenField.name = key;
                    hiddenField.value = params[key];
                    form.appendChild(hiddenField);
                }
            }

            document.body.appendChild(form);
            form.submit();
        });
    }

    /*nameProduct = (id) => {
        if (id == 1) {
            return "Vivo Seguro Celular Danos";
        } else if (id == 2) {
            return "Vivo Seguro Celular Roubo";
        } else if (id == 3) {
            return "Vivo Seguro Celular Roubo + Danos";
        } else {
            return "Unknown";
        }
    }*/
    getProductName=()=>{
        if(this.props.productName!=undefined){
            return this.props.productName;
        }else if(this.state.policies!=undefined){
            return this.state.policies.listPolicies[0].productName=="Vivo Multiproteção"? 
            "Vivo Seguro Celular Roubo + Danos":this.state.policies.listPolicies[0].productName;
        }else{
            return "";
        }
    }
}

export default MeusPagamentos;
