import React, { PureComponent } from 'react';
import { Redirect} from 'react-router-dom';
import rpc, { rpcWithParams }  from './our/rpc';

export default class SmsPageRedirect extends PureComponent {

    componentDidMount(){
        this.props.onChangeOffer(2);
         const value = {
            dateOcurrence: new Date(),
            pageVisited: 'Entra por la parte de SMS',
            idOffert: 2
        };
        rpc({
            type: 'SaveContrationStepsHistory',
            value
        }).then((result) => {
            this.props.onChangeContratationStep(result);
            console.log(result);
        });
    }

    render() {
        return (
            <Redirect to="/Contratation" />
        )
    }
}
