import React, { Component } from 'react';
import IcoDanos from '../img/danos_new.jpg';
import IcoRobo from '../img/robo-hurto_new.jpg'

export class CondicoesGerais_new extends Component {
    componentDidMount(){
        window.s = window.s || {};
        window.s.pageName = 'vivo:br:seguro movil:condiciones';
        window.s.products = 'vivo:br:seguro movil:';

        window.dataLayer.push ({
            'event' : 'asyncGTM.step',
            'GAcategory' : 'vivo:br:seguro movil',
            'GAaction' : 'paso:01:condiciones:acceso',
            'GAlabel' :'vivo:br:seguro movil:'});
    }
    
    render() {
        return (
            <main className="" id="customrenov">
                <section className="row vivo-interior-condiciones">
                    <div className="col-xs-12">
                        <div className="container">
                            <div className="row row-text">
                                <div className="col-xs-12">
                                    <h2>Condições Gerais</h2>
                                </div>
                            </div>
                            <div className="row tipos-de-condiciones">
                                <div className="col-lg-2 col-sm-12 col-xs-12"></div>
                                <div className="col col-lg-4 col-sm-6 col-xs-12">
                                    <img src={IcoRobo}/>
                                    <h3>Roubo e Furto</h3>
                                    <a onClick={this.onClickAppearDanos} className="btn-vivo shadow">Acesse aqui</a>
                                </div>
                                <div className="col col-lg-4 col-sm-6 col-xs-12">
                                    <img src={IcoDanos}/>
                                    <h3>Danos acidentais</h3>
                                    <a onClick={this.onClickAppearAccident} className="btn-vivo shadow">Acesse aqui</a>
                                </div>
                                <div className="col-lg-2 col-sm-12 col-xs-12"></div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            );
    }

    onClickAppearDanos = () => {
        //Estos dos campos vienen rellenados para que pasen las verificaciones
        window.dataLayer.push ({
            'event' : 'asyncGTM',
            'GAcategory' : 'vivo:br:seguro movil',
            'GAaction' : 'condiciones:roubo e furto qualificado',
            'GAlabel' :'vivo:br:seguro movil:'});
        window.open(process.env.PUBLIC_URL + '/FileDownload?tokken='+ 0 + '&generalconditions=' + 3, '_blank').print();

    }

    onClickAppearAccident = () => {
        //Estos dos campos vienen rellenados para que pasen las verificaciones
        window.dataLayer.push ({
            'event' : 'asyncGTM',
            'GAcategory' : 'vivo:br:seguro movil',
            'GAaction' : 'condiciones:danos acidentais',
            'GAlabel' :'vivo:br:seguro movil:'});
        window.open(process.env.PUBLIC_URL + '/FileDownload?tokken='+ 0 + '&generalconditions=' + 4, '_blank').print();

    }

    onClickAppearOldDanos = () => {
        window.open(window.location.origin + process.env.PUBLIC_URL + '/FileDownload?tokken='+ 0 + '&generalconditions=' + 1, '_blank').print();
    }

    onClickAppearOldRoubo = () => {
        window.open(window.location.origin + process.env.PUBLIC_URL + '/FileDownload?tokken='+ 0 + '&generalconditions=' + 2, '_blank').print();
    }
}

export default CondicoesGerais_new;