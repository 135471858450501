import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import ThemeSelector from './ThemeSelector.jsx';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <Router basename={process.env.PUBLIC_URL}>
      <ThemeSelector>
        <App/>
      </ThemeSelector>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);