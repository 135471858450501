import React, { Component } from 'react';

export class DropdownSearch extends Component {

    /**
     * Props necesarios
     *  options -> lista de opciones recibida: { id, name }
     *  onChangeSearchWord -> función de cambio en la selección onChangeSelected(id)
     *  searchWord -> valor del input
     * 
     * Props opcionales
     *  placeHolder -> placeHolder del input, si no viene es ''
     *  disabled -> booleano, si no viene es false
     *  divClassName -> nombre de la clase del div global
     *  inputClassName -> nombre de la clase del input
     *  listDivClassName -> nombre de la clase del div de la lista
     *  listItemClassName -> nombre de la clase de los items de la lista
     *  optionId -> nombre de la variable que contiene el id del option, si no viene es 'id'
     *  optionName -> nombre de la variable que contiene el nombre del option, si no viene es 'name'
     */

    constructor(props) {
        super(props);
        this.state = {
          filteredList: this.props.options,
          showFilteredList: false,
          optionId: this.props.optionId !== undefined ? this.props.optionId : 'id',
          optionName: this.props.optionName !== undefined ? this.props.optionName : 'name',
          inList: false
        };
    }

    componentDidMount() {
        this.setState({filteredList: this.props.options});
    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.options === undefined && this.props.options !== undefined) || (prevProps.options !== undefined && this.props.options === undefined)) {
            this.setState({filteredList: this.props.options});
        }
        
        if (prevProps.searchWord !== this.props.searchWord) {
            let searchWordAux = this.props.searchWord;
            if (searchWordAux === undefined) searchWordAux = '';
            if (searchWordAux !== '') {
                this.setState({filteredList: this.props.options.filter((opt) => { return opt[this.state.optionName].toUpperCase().includes(this.props.searchWord) })});
            } else {
                this.setState({filteredList: this.props.options, searchWord: searchWordAux});
            }
        }
    }

    render() {
        const disabled = this.props.disabled !== undefined && this.props.disabled;
        const placeHolder = this.props.placeHolder !== undefined ? this.props.placeHolder : '';
        const dropdownSearchDiv = this.props.divClassName !== undefined ? this.props.divClassName : '';
        const dropdownSearchInput = this.props.inputClassName !== undefined ? this.props.inputClassName : '';
        const dropdownSearchListDiv = this.props.listDivClassName !== undefined ? this.props.listDivClassName : '';
        const dropdownSearchListItem = this.props.listItemClassName !== undefined ? this.props.listItemClassName : '';
        return (
            <div className={dropdownSearchDiv}>
                <input 
                    className={dropdownSearchInput}
                    type='text'
                    disabled={disabled}
                    placeholder={placeHolder}
                    onChange={this.onChangeSearchWord}
                    value={this.props.searchWord}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                />
                {!disabled && (this.state.showFilteredList || this.state.inList) && this.state.filteredList !== undefined && this.state.filteredList.length > 0 && (
                    <div className={dropdownSearchListDiv} onMouseEnter={this.inList} onMouseLeave={this.outList}>
                        {this.state.filteredList.map((opt) => {
                            return (
                                <a className={dropdownSearchListItem} key={opt[this.state.optionId]} onClick={() => {this.onClickOption(opt)}}>{opt[this.state.optionName]}</a>
                            )
                        })}
                    </div>
                    )}
            </div>
        );
    }

    onChangeSearchWord = (e) => {
        /*if (e.target.value === undefined || e.target.value === "") {
            this.setState({filteredList: this.props.options});
            return;
        }
        const newSearchWord = e.target.value.toUpperCase();
        const newFilteredList = this.props.options.filter((opt) => { return opt[this.state.optionName].toUpperCase().includes(newSearchWord) });

        this.setState({filteredList: newFilteredList});*/

        this.props.onChangeSearchWord(e);
    }

    onClickOption = (opt) => {
        const newSearchWord = opt[this.state.optionName].toUpperCase();
        const newFilteredList = this.props.options.filter((opt) => { return opt[this.state.optionName].toUpperCase().includes(newSearchWord) });

        this.setState({filteredList: newFilteredList});

        const e = {
            target: {
                value: opt[this.state.optionName]
            }
        }

        this.onChangeSearchWord(e);
        this.outList();
    } 

    onFocus = () => {
        this.setState({showFilteredList: true});
    }

    onBlur = () => {
        this.setState({showFilteredList: false});
    }

    inList = () => {
        this.setState({inList: true});
    }

    outList = () => {
        this.setState({inList: false});
    }

}

export default DropdownSearch;