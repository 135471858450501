import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import rpc, { rpcWithParams } from '../our/rpc';
import VivoLogo from '../img/vivo-logo-new.svg';
import LoginLogo from '../img/ico_login_new.svg';
import IcoTelMenu from '../img/ico-tel-menu_new.svg';
import Loading from '../img/loading.svg';
import ReCAPTCHA from "react-google-recaptcha";
import Measure from 'react-measure';
import classNames from 'classnames';
import GenericPopup_new from '../popUps/GenericPopUp_new.jsx';
import VerifyLoginPopUp from '../popUps/VerifyLoginPopUp_new.jsx';

class Header_new extends Component {
    constructor(props, ...args) {
        super(props, ...args);
        this.state = {
            phoneNumber: '', CPF: '', menuClicked: false, loginClicked: false, errorMessage:'', smsPopUp: false, captcha: undefined,
            dimensions: {
                width: -1,
                height: -1,
            },
            clients: false,
            visible: false,
            dropdownHover: false,
            scrollLimit: false
        };
        this._reCaptchaRef = React.createRef();
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScrollHeader);
    }

    handleScrollHeader = () => {
        if (window.scrollY > 130) {
            this.setState({scrollLimit: true});
        } else {
            this.setState({scrollLimit: false});
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.userName !== this.props.userName){
            this.setState({
                userName: this.props.userName,
                CPF: this.props.CPF,
                phoneNumber: this.props.phoneNumber,
                id: this.props.id,
            });
        }
    }

    render() {
        const { width, height } = this.state.dimensions;
        //const className = classNames(width < 400 && 'small-width-modifier');
        const loadingLayer = this.state.loading ? <div className="modal-backdrop fade-in" style={{opacity: '0.4', zIndex: 9999}}><img src={Loading} style={{position: 'fixed', top: 'calc(50% - (64px / 2))', left: 'calc(50% - (64px / 2))', zIndex: 9999}}></img></div> : <></>;

        return (
            <div style={{height: '100%'}}>
                <header ref={this._headerRef} className={this.state.scrollLimit ? "on" : ""}>
                    {loadingLayer}
                    {this.state.errorMessage!=='' && this.showErrorSuscriberPopUp()}
                    {this.state.smsPopUp &&
                            <>
                                <VerifyLoginPopUp
                                    {...this.props}
                                    {...this.state}
                                    onChangeValueByName={this.onChangeValueByName}
                                    close={this.closeLogin}
                                    sendSMS={this.sendSMSCode}
                                    validatePin={this.validatePin} />
                                <div className="modal-backdrop fade in"></div>
                            </>}
                    <div className="main-header-desktop container">
                        <div className="main-header-desktop__logo">
                            <a onClick={this.onClickHome} style={{cursor: 'pointer'}}>
                                <img src={VivoLogo} className="svg-inject vivo-logo mb-10"/>
                                <h1 className="element-invisible">Seguro Celular</h1>
                            </a>
                        </div>
                        <div className="main-header-desktop__menu">
                            <div className="main-header-mobile__menu__top" onClick={this.activateTop}></div>
                            <div className="main-header-mobile__menu__bottom" onClick={this.activateBottom}></div>
                            <div className={ this.props.topActive ? "main-header-desktop__menu__top active" : "main-header-desktop__menu__top" }>
                                <div className="main-header-desktop__menu__top__login">
                                    <div className="main-header-mobile__close" onClick={this.closeMenu}></div>
                                    <div className="main-header-desktop__menu__top__login__title">
                                        <img src={LoginLogo}/>
                                        Área do cliente {this.state.userName ? '- ' + this.state.userName : '' }
                                    </div>
                                    {this.state.id ?
                                        <div className="arrow-link" href="#ModalLoginError" name="modal">
                                            <input type="submit" name="Acessar" onClick={this.showCaptcha} value="Acessar" id="login-menu-entrar" className=""/>
                                            <ReCAPTCHA badge="bottomright" size='invisible' ref={this._reCaptchaRef} sitekey='6LfRvNYZAAAAAEd6dtEkgQp3SZu0I3xlM6UC9ZSN' onChange={this.loginPrivado} hl='pt-BR' />
                                        </div>
                                    :
                                    this.state.userName ?
                                        <></>
                                    :
                                        <>
                                            <input type="text" name="phoneNumber" placeholder="Linha Vivo: 1197297862" value={this.state.phoneNumber} onChange={this.onChangeValueByName} maxLength="60" id="login-menu-linea" className="texto"/>
                                            <input type="text" name="CPF" placeholder="CPF/CNPJ" value={this.state.CPF} onChange={this.onChangeValueByName} maxLength="50" id="login-menu-cpf" className="texto"/>
                                            <div className="arrow-link" href="#ModalLoginError" name="modal">
                                                <input type="submit" name="Acessar" onClick={this.showCaptcha} value="Acessar" id="login-menu-entrar" className=""/>
                                                <ReCAPTCHA badge="bottomright" size='invisible' ref={this._reCaptchaRef} sitekey='6LfRvNYZAAAAAEd6dtEkgQp3SZu0I3xlM6UC9ZSN' onChange={this.loginPrivado} hl='pt-BR' />
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className="main-header-desktop__menu__top__tel">
                                    <a href="tel:0800 580 0277" onClick={this.onClickPhone} target='_blank' className='' style={{cursor: 'pointer'}}>
                                        <img src={IcoTelMenu}/>
                                        0800 580 0277
                                    </a>
                                </div>
                            </div>
                            <div className={ this.props.bottomActive ? "main-header-desktop__menu__bottom active" : "main-header-desktop__menu__bottom" }>
                                <div className="main-header-mobile__close" onClick={this.closeMenu}></div>
                                <nav className="nav-header">
                                    <ul>
                                        <li><a onClick={this.onClickContratation} style={{cursor: 'pointer'}}>Calcule seu seguro</a></li>
                                        <li><a onClick={this.onClickPlanos} style={{cursor: 'pointer'}}>Coberturas</a></li>
                                        <li><a onClick={this.onClickComoUsar} style={{cursor: 'pointer'}}>Como usar o seguro</a></li>
                                        <li><a onClick={this.onClickPreguntas} style={{cursor: 'pointer'}}>Perguntas Frequentes</a></li>
                                        <li><a onClick={this.onClickCondiciones} style={{cursor: 'pointer'}}>Condições Gerais</a></li>
                                        <li className="has-submenu">
                                            <a>Soluções Financeiras</a>
                                            <ul className="nav-submenu" onMouseEnter={this.onHoverSoluciones}>
                                                <li><a onClick={() => this.onClickSoluciones(1)} href="https://www.vivomoney.com.br/?utm_source=referral&utm_medium=portal_seguro_celular&utm_content=menu" target="_blank">Vivo Money</a></li>
                                                {/*<li><a onClick={() => this.onClickSoluciones(2)} href="https://www.vivo.com.br/para-voce/produtos-e-servicos/ofertas/vivo-pay?utm_source=seguro-celular&utm_medium=LP&utm_campaign=lp-seguros-vivo-pay">Vivo Pay</a></li>*/}
                                                <li><a onClick={() => this.onClickSoluciones(3)} href="https://www.itau.com.br/cartoes/escolha/formulario/vivo-itaucard-cashback-visa-platinum.html?utm_source=vivo&utm_medium=parceria&utm_campaign=portalseguros&cpg_s=viv_prc">Cartão Vivo Itaú</a></li>
                                                <li><a onClick={() => this.onClickSoluciones(4)} href="https://appstore.vivo.com.br/sc/br/vivostore/detalle/vivo-credito-antecipado/58136/1992?fkp=&p=flow&w=internal">Crédito Antecipado</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                    <div className="main-header-desktop__menu__bottom__tel">
                                        <a href="tel:0800 580 0277" onClick={this.onClickPhone} target='_blank' className='' style={{cursor: 'pointer'}}>
                                            <img src={IcoTelMenu}/>
                                            0800 580 0277
                                        </a>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>

                    {/*<Measure
                        bounds
                        onResize={contentRect => {
                            if (contentRect.bounds.width === 980 || contentRect.bounds.width < 990) {
                                this.setState({ dimensions: contentRect.bounds, mobile: true });
                            } else {
                                this.setState({ dimensions: contentRect.bounds, mobile: false });
                            }
                        }}
                    >
                        {({ measureRef }) => (
                            <div ref={measureRef} className={className}>
                            </div>
                        )}
                    </Measure>*/}
                </header>
            </div>
        );
    }

    activateTop = () => {
        this.props.activateHeaderTop();
    }

    activateBottom = () => {
        this.props.activateHeaderBottom();
    }

    closeMenu = () => {
        this.props.deactivateHeader();
    }

    onChangeValueByName = (e) => {
        this.setState({ ...this.state, [e.target.name]: e.target.value });
        this.props.onChangeValueByName(e);
    }

    onClickHome = () => {
        this.props.history.push('/');
        window.dataLayer.push ({
            'event' : 'asyncGTM',
            'GAcategory' : 'vivo:br:seguro movil',
            'GAaction' : 'elementos fijos:logo:volver home',
            'GAlabel' :'vivo:br:seguro movil:'});
    }

    onClickPhone = () =>{
        window.dataLayer.push ({
            'event' : 'asyncGTM',
            'GAcategory' : 'vivo:br:seguro movil',
            'GAaction' : 'elementos fijos:menu:telefono',
            'GAlabel' :'vivo:br:seguro movil:'});
    }
    onClickVivoMoney = () => {
        this.props.history.push('/');
        window.dataLayer.push ({
            'event' : 'asyncGTM',
            'GAcategory' : 'vivo:br:seguro movil',
            'GAaction' : 'elementos fijos:soluciones financieras:vivo money',
            'GAlabel' :'vivo:br:seguro movil:'});
    }
    onClickVivoPay = () => {
        this.props.history.push('/');
        window.dataLayer.push ({
            'event' : 'asyncGTM',
            'GAcategory' : 'vivo:br:seguro movil',
            'GAaction' : 'elementos fijos:soluciones financieras:vivo pay',
            'GAlabel' :'vivo:br:seguro movil:'});
    }
    onClickVivoItaucard = () => {
        this.props.history.push('/');
        window.dataLayer.push ({
            'event' : 'asyncGTM',
            'GAcategory' : 'vivo:br:seguro movil',
            'GAaction' : 'elementos fijos:soluciones financieras:vivo itucard',
            'GAlabel' :'vivo:br:seguro movil:'});
    }
    onClickCreditoAntecipado = () => {
        this.props.history.push('/');
        window.dataLayer.push ({
            'event' : 'asyncGTM',
            'GAcategory' : 'vivo:br:seguro movil',
            'GAaction' : 'elementos fijos:soluciones financieras:credito antecipado',
            'GAlabel' :'vivo:br:seguro movil:'});
    }
    onClickHome = () => {
        this.props.history.push('/');
        window.dataLayer.push ({
            'event' : 'asyncGTM',
            'GAcategory' : 'vivo:br:seguro movil',
            'GAaction' : 'elementos fijos:logo:volver home',
            'GAlabel' :'vivo:br:seguro movil:'});
    }

    onClickContratation = () => {
        this.props.history.push('/Contratation');
        if (this.state.mobile) {
            this.setState({ menuClicked: !this.state.menuClicked, loginClicked: false });
        }
        window.dataLayer.push ({
            'event' : 'asyncGTM',
            'GAcategory' : 'vivo:br:seguro movil',
            'GAaction' : 'elementos fijos:menu:contratar',
            'GAlabel' :'vivo:br:seguro movil:'});
    }

    onClickPlanos = () => {
        this.props.history.push('/planos');
        if (this.state.mobile) {
            this.setState({ menuClicked: !this.state.menuClicked, loginClicked: false });
        }
        window.dataLayer.push ({
            'event' : 'asyncGTM',
            'GAcategory' : 'vivo:br:seguro movil',
            'GAaction' : 'elementos fijos:menu:coberturas',
            'GAlabel' :'vivo:br:seguro movil:'});
    }
    onClickComoUsar = () => {
        this.props.history.push('/como-usar-o-seguro');
        if (this.state.mobile) {
            this.setState({ menuClicked: !this.state.menuClicked, loginClicked: false });
        }
        window.dataLayer.push ({
            'event' : 'asyncGTM',
            'GAcategory' : 'vivo:br:seguro movil',
            'GAaction' : 'elementos fijos:menu:como usar o seguro',
            'GAlabel' :'vivo:br:seguro movil:'});
    }
    onClickCondiciones = () => {
        this.props.history.push('/condicoes-gerais');
        if (this.state.mobile) {
            this.setState({ menuClicked: !this.state.menuClicked, loginClicked: false });
        }
        window.dataLayer.push ({
            'event' : 'asyncGTM',
            'GAcategory' : 'vivo:br:seguro movil',
            'GAaction' : 'elementos fijos:menu:condiciones',
            'GAlabel' :'vivo:br:seguro movil:'});
    }
    onClickPreguntas = () => {
        this.props.history.push('/preguntas-frequentes');
        this.setState({ menuClicked: !this.state.menuClicked, loginClicked: false });
        window.dataLayer.push ({
            'event' : 'asyncGTM',
            'GAcategory' : 'vivo:br:seguro movil',
            'GAaction' : 'elementos fijos:menu:faq',
            'GAlabel' :'vivo:br:seguro movil:'});
    }

    onHoverSoluciones = () => {
        window.dataLayer.push ({
            'event' : 'asyncGTM',
            'GAcategory' : 'vivo:br:seguro movil',
            'GAaction' : 'elementos fijos:soluciones financieras',
            'GAlabel' :'vivo:br:seguro movil:'});
    }

    onClickSoluciones = (item) => {
        switch(item) {
            case 1:
                window.dataLayer.push ({
                    'event' : 'asyncGTM',
                    'GAcategory' : 'vivo:br:seguro movil',
                    'GAaction' : 'elementos fijos:soluciones financieras:vivo money',
                    'GAlabel' :'vivo:br:seguro movil:'});
                break;
            case 2:
                window.dataLayer.push ({
                    'event' : 'asyncGTM',
                    'GAcategory' : 'vivo:br:seguro movil',
                    'GAaction' : 'elementos fijos:soluciones financieras:vivo pay',
                    'GAlabel' :'vivo:br:seguro movil:'});
                break;
            case 3:
                window.dataLayer.push ({
                    'event' : 'asyncGTM',
                    'GAcategory' : 'vivo:br:seguro movil',
                    'GAaction' : 'elementos fijos:soluciones financieras:vivo itucard',
                    'GAlabel' :'vivo:br:seguro movil:'});
                break;
            case 4:
                window.dataLayer.push ({
                    'event' : 'asyncGTM',
                    'GAcategory' : 'vivo:br:seguro movil',
                    'GAaction' : 'elementos fijos:soluciones financieras:credito antecipado',
                    'GAlabel' :'vivo:br:seguro movil:'});
                break;
            default:
                break;
        }
    }

    onClickMenu = () => {
        this.setState({ menuClicked: !this.state.menuClicked, loginClicked: false });
    }

    showCaptcha = () => {
        if (this.state.phoneNumber !== undefined && this.state.phoneNumber !== '' && this.state.CPF !== undefined && this.state.CPF !== '') {
            this._reCaptchaRef.current.execute();
        }
    }

    loginPrivado = (captcha) => {

        console.log('Value ' + captcha);
        if ((captcha !== null || captcha !== undefined) && typeof captcha === 'string' && this.state.phoneNumber !== undefined && this.state.phoneNumber !== '' && this.state.CPF !== undefined && this.state.CPF !== '') {
            this.setState({ visible: false, captcha, loading: true});
            this.validateClientTis();
        }
        this._reCaptchaRef.current.reset();

    }

    validateClientTis = () => {

        rpc({
            type: 'CheckClientByphoneNumberDeviceAndDocument',
            phoneNumber: this.formatPhoneNumber(this.state.phoneNumber),
            document: this.state.CPF,
        }).then( (response) => {
            if(response!==null && response!==undefined){
                this.sendSMSCode();
            }else{
                console.log('TEST');
                this.setState({errorMessage: 'O número inserido não é uma linha Vivo ou o cpf inserido não corresponde.', loading: false});
            }
        })
    }

    formatPhoneNumber = (phoneNumber) => {
        if(phoneNumber && phoneNumber !== null) {
            if( phoneNumber.includes('(') && phoneNumber.includes(')')){
                phoneNumber =(phoneNumber.substring(1,3)+phoneNumber.substring(4));
                phoneNumber = phoneNumber.length <12 ? '55' + phoneNumber : phoneNumber;
            }else{
                phoneNumber = phoneNumber.length <12 ? '55' + phoneNumber : phoneNumber;
            }
        }

        return phoneNumber;
    }

    sendSMSCode = () => {
        this.setState({showPinPopUp: true}, () => {
            rpc({
                type: 'SendPinCodeSMS',
                cpf: this.state.CPF,
                phoneNumber: this.formatPhoneNumber(this.state.phoneNumber),
                origin: 'CONEXION'
            }).then( (response) => {
                if(this.state.smsPopUp!==true){
                    this.setState({ smsPopUp: true, loading: false }); //enviar sms
                }
            })
        })
    }

    closeLogin = () => {
        this.setState({ smsPopUp: false, visible: true }); 
    }

    showErrorSuscriberPopUp = () => {
        console.log('TEST 2');
        return <GenericPopup_new close={this.closeErrorSuscriber} 
            title='Ops,não localizamos o seu seguro'
            message={this.state.errorMessage}/>;
    }

    closeErrorSuscriber = () => {
        this.setState({ errorMessage:'', visible: true }); 
    }

    validatePin = () => {
        this.setState({ loading: false }); //enviar sms
        rpc({
            type: 'ValidatePinCode',
            pinCode: this.state.pinCode,
            phoneNumber: this.formatPhoneNumber(this.state.phoneNumber),
            cpf: this.state.CPF,
            origin: 'CONEXION'
        }).then((result) => {
            if(result != null){
                this.logOscp();
            } else {
                this.setState({errorPin: true});
            }
        });
    }

    logOscp = () =>{
        rpc({
            type: 'OscpLogin',
            phoneNumber: this.state.phoneNumber,
            document: this.state.CPF,
            token: this.state.captcha
        }).then((logged) => {
            if (logged) {
                this.props.setloginPrivateArea();
            }else{
                this.setState({errorMessage: 'Por favor confirme seus dados e tente novamente. Se o mesmo após a nova tentativa não for possível o acesso, entre em contato conosco no telefone 0800 580 02 77 ou pelo WhatsApp (11) 97287 8201.', smsPopUp: false});
            }
        });
    }
}

export default withRouter(Header_new);