import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import rpc, { rpcWithParams } from '../our/rpc';
import VivoLogo from '../img/landing-2024/vivo-logo.svg';
import '../css/landing-2024.css';

class Header_redirect extends Component {
    constructor(props, ...args) {
        super(props, ...args);
        this.state = {
            dimensions: {
                width: -1,
                height: -1,
            }
        };
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScrollHeader);
    }

    handleScrollHeader = () => {
        if (window.scrollY > 130) {
            this.setState({scrollLimit: true});
        } else {
            this.setState({scrollLimit: false});
        }
    }

    render() {
        const { width, height } = this.state.dimensions;
        //const className = className(width < 400 && 'small-width-modifier');
        return (
        <div style={{height: '100%'}}>    
            <header className={this.state.scrollLimit ? "on" : ""}>
                <div class="main-header-desktop container">
                <div class="main-header-desktop__logo">
                    <a href="#">
                    <img src={VivoLogo} class="svg-inject vivo-logo landing mb-10"/>
                    <h1 class="element-invisible">Seguro Celular</h1>
                    </a>
                </div>
                </div>

            </header>
        </div>    
        );
    }

    
}

export default withRouter(Header_redirect);