import React, { Component } from 'react';
import IcoRobo from '../img/robo-hurto_new.jpg';
import IcoDanos from '../img/danos_new.jpg';
import IcoMulti from '../img/robo-hurto-danos_new.jpg';

export class planos_new extends Component {

    componentDidMount(){
        window.s = window.s || {};
        window.s.pageName = 'vivo:br:seguro movil:coberturas';
        window.s.products = 'vivo:br:seguro movil:';

        window.dataLayer.push ({
            'event' : 'asyncGTM.step',
            'GAcategory' : 'vivo:br:seguro movil',
            'GAaction' : 'paso:01:coberturas:acceso',
            'GAlabel' :'vivo:br:seguro movil:'});
    }

    render() {
        return (
            <main className="" id="customrenov">
                <section className="row vivo-interior-coberturas">
                    <div className="col-xs-12">
                        <div className="container">
                            <div className="row row-text">
                                <div className="col-xs-12">
                                    <h2>Escolha a melhor cobertura para você</h2>
                                    <p>A Vivo possui a proteção ideal para o seu celular! Confira as opções disponíveis:</p>
                                </div>
                            </div>
                            <div className="row tipos-de-coberturas">
                                <div className="col col-xs-12">
                                    <div className="row">
                                        <div className="col-img col-lg-4 col-sm-5 col-xs-12">
                                            <img src={IcoRobo}/>
                                        </div>
                                        <div className="col-lg-8 col-sm-7 col-xs-12 tipos-de-coberturas__info">
                                            <h3>Roubo e Furto</h3>
                                            <p>Proteção completa em caso de:</p>
                                            <ul className="check verde">
                                                <li>Roubo</li>
                                                <li>Furto simples e qualificado</li>
                                            </ul>
                                            <div className="tipos-de-coberturas__info__precio">
                                                <p>A partir de</p>
                                                <div className="tipos-de-coberturas__info__precio__valoracion">
                                                    <p className="precio-1">R$</p>
                                                    <p className="precio-2">4</p>
                                                    <p className="precio-3">,99<br/>/Mês</p>
                                                </div>
                                                <a onClick={() => this.onClickContratation(1)} className="btn-vivo shadow">Calcular</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-xs-12">
                                    <div className="row">
                                        <div className="col-img col-lg-4 col-sm-5 col-xs-12">
                                            <img src={IcoDanos}/>
                                        </div>
                                        <div className="col-lg-8 col-sm-7 col-xs-12 tipos-de-coberturas__info">
                                            <h3>Danos acidentais</h3>
                                            <p>Proteção completa em caso de:</p>
                                            <ul className="check verde">
                                                <li>Quebra acidental</li>
                                                <li>Imersão em líquidos</li>
                                                <li>Danos elétricos</li>
                                            </ul>
                                            <div className="tipos-de-coberturas__info__precio">
                                                <p>A partir de</p>
                                                <div className="tipos-de-coberturas__info__precio__valoracion">
                                                    <p className="precio-1">R$</p>
                                                    <p className="precio-2">2</p>
                                                    <p className="precio-3">,99<br/>/Mês</p>
                                                </div>
                                                <a onClick={() => this.onClickContratation(2)} className="btn-vivo shadow">Calcular</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-xs-12">
                                    <div className="row">
                                        <div className="col-img col-lg-4 col-sm-5 col-xs-12">
                                            <img src={IcoMulti}/>
                                        </div>
                                        <div className="col-lg-8 col-sm-7 col-xs-12 tipos-de-coberturas__info">
                                            <h3>Roubo e Furto + Danos</h3>
                                            <p>Proteção completa em caso de:</p>
                                            <ul className="check verde">
                                                <li>Roubo</li>
                                                <li>Furto simples e qualificado</li>
                                                <li>Quebra acidental</li>
                                                <li>Imersão em líquidos</li>
                                                <li>Danos elétricos</li>
                                            </ul>
                                            <div className="tipos-de-coberturas__info__precio">
                                                <p>A partir de</p>
                                                <div className="tipos-de-coberturas__info__precio__valoracion">
                                                    <p className="precio-1">R$</p>
                                                    <p className="precio-2">5</p>
                                                    <p className="precio-3">,99<br/>/Mês</p>
                                                </div>
                                                <a onClick={() => this.onClickContratation(3)} className="btn-vivo shadow">Calcular</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="row vivo-interior-situaciones">
                    <div className="col-xs-12">
                        <div className="container">
                            <div className="row row-text">
                                <div className="col-xs-12">
                                    <h2>O Vivo Seguro Celular não cobre as seguintes situações:</h2>
                                    <p>As coberturas são devidas para eventos ocorridos durante a vigência do Seguro individual, descontada a franquia, exceto se decorrentes dos riscos excluídos constantes na Cláusula 5ª - RISCOS EXCLUÍDOS das Condições Gerais do Vivo Seguro Celular - Roubo ou Furto e Danos Acidentais, Cláusula 6ª - RISCOS EXCLUÍDOS das Condições da Cobertura de Danos Materiais.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }

    onClickContratation = (origen) =>{
        switch(origen){
            case 1:
                window.dataLayer.push ({
                    'event' : 'asyncGTM',
                    'GAcategory' : 'vivo:br:seguro movil',
                    'GAaction' : 'coberturas:boton ir a calcular',
                    'GAlabel' :'vivo:br:seguro movil:',
                    'localizacion' : 'roubo e furto'});
                    break;
            case 2:
                window.dataLayer.push ({
                    'event' : 'asyncGTM',
                    'GAcategory' : 'vivo:br:seguro movil',
                    'GAaction' : 'coberturas:boton ir a calcular',
                    'GAlabel' :'vivo:br:seguro movil:',
                    'localizacion' : 'danos acidentais'});
                break;
            case 3:
                window.dataLayer.push ({
                    'event' : 'asyncGTM',
                    'GAcategory' : 'vivo:br:seguro movil',
                    'GAaction' : 'coberturas:boton ir a calcular',
                    'GAlabel' :'vivo:br:seguro movil:',
                    'localizacion' : 'banner robo e furto mas danos'});
                break;
            default: 
            break;
        }

        this.props.history.push('/Contratation');

    }
}

export default planos_new;