import React, { Component } from 'react';
import rpc from './our/rpc';
import VivoHome from './img/vivo-home-s1.svg';
import AvanzarPopUp from '../src/popUps/ErrorPopUp.jsx';
import VoucherEnUso from '../src/popUps/VoucherEnUsoPopUp.jsx';

export class Voucher_new extends Component {

    constructor(props) {
        super(props);
        this.state = { VoucherEnUso: false, avanzarVisible: false };
    }

    render() {
        return (
            <main>
                <section className="row vivo-colaborador">
                    <div className="col-xs-12">
                        <div className="container">
                            <div className="row row-colaborador">
                                <div className="col col-xs-12">
                                    <div className="row">
                                        <div className="col-img col-lg-4 col-sm-5 col-xs-12">
                                            <img src={VivoHome}/>
                                        </div>
                                        <div className="col-lg-8 col-sm-7 col-xs-12 vivo-colaborador-form">
                                            <h3>Oferta exclusiva para você que é cliente do Vivo Valoriza!</h3>
                                            <h2>Agora você pode contratar as coberturas do Vivo Seguro Celular por um preço especial!</h2>
                                            <div className="colaborador-form">
                                                <div>
                                                    <label>Digite os dados do voucher aqui:</label>
                                                    <input name="ctl00$ContentPlaceHolder1$txtTelefone" type="text" maxLength="16" id="ContentPlaceHolder1_txtTelefone" className="form-control texto" onChange={this.props.onChangeVoucherCode} tabindex="0"/>		            
                                                </div>
                                                <div>
                                                    <label>&nbsp;</label>
                                                    <a onClick={this.onClickContratation} className="btn-vivo shadow">Avançar</a>		            
                                                </div>
                                            </div>
                                            <p className="help-block" style={{fontStyle:"italic", fontSize:'13px'}}>
                                                <small>*Importante: A contratação do seguro com o valor promocional não é acumulativa com outras promoções.</small>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {this.state && this.state.avanzarVisible && <AvanzarPopUp close={() => this.setState({avanzarVisible: false})} />}
                {this.state.VoucherEnUso && <VoucherEnUso close={() => this.setState({VoucherEnUso: false})} windowSize = {this.props.windowSize} />}
            </main>
        );
    }

    onClickContratation = () =>{
        if (this.props.voucherCode !== undefined && this.props.voucherCode.trim() !== '') {
            rpc({
                type: 'IsValidVoucherCode',
                code: this.props.voucherCode,
            }).then((IsValidVoucherCode) => {
                if(IsValidVoucherCode==1){
                    this.props.isVoucherCode();
                    this.props.history.push('/Contratation');
                } else {
                    this.setState({VoucherEnUso: true})
                }
            });
        }
    }
}

export default Voucher_new;