import RenderInBody from '../renderInBody';
const React = require('react');

class VoucherEnUso extends React.Component {

    render() {

        const header = <div className="modal-header d-flex">
            <div className="col-xs-11 nopadding ds-vertical-align">
                <h5 className="modal-title">Aviso</h5>
            </div>
            <div className="col-xs-1 nopadding ds-vertical-align">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" tabIndex="0">
                    <span aria-hidden="true" onClick={this.props.close}>×</span>
                </button>
            </div>
        </div>;

        const body =
            <div className="modal-body">
                <p className="vivo-color bold">Esse voucher já foi usado.</p>
            </div>
            ;


        return  (
            <RenderInBody component={
            <div className="modal fade in" tabIndex="-1" role="dialog" id="ModalVerificacion" style={{display: "block", paddingLeft: "0px", width: "400px", marginLeft: "auto", marginRight: "auto"}} aria-hidden="false">
                <div className="modal-dialog modal-dialog-centered" role="document" style={{width: "auto"}}>
                    <div className="modal-content">
                           {header}{body}
                    </div>
                </div>
            </div>
            }/>
    
            );
    }
}

export default VoucherEnUso;