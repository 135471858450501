import React, { Component } from 'react';
import rpc from './our/rpc';
import IcoInfo from './img/landing-2024/favicon.png';
import IcoEscudo from './img/landing-2024/ico_escudo-vivo.svg';
import LoaderWhite from './img/landing-2024/loader-white.svg';
import './css/landing-2024.css';


export class Home_redirect extends Component {
  state = {
    redirect: false,
    time : true
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    if (this.state.time){
      this.timer= setTimeout(() => this.setState({ redirect: true }), 1000)
      if (this.state.redirect) {
        window.location.href = 'https://vivopay.com.br/seguro-celular';
    }
    
      
    }
      return (
                <main class="" id="customrenov">
                  
                  <section class="vivo-landing-2024__section">
                      
                      <div class="container">
                          <div class="vivo-landing-2024__banner">
                              <div class="vivo-landing-2024__banner__content">
                                <img src={IcoEscudo}/>
                                <h2>Agora a contratação e gestão do Seguro Celular e Eletrônicos é no APP Vivo</h2>
                                <p>Vamos te transferir para nossa página do Vivo Pay para maiores informações, mas você também pode baixar o APP da Vivo e fazer a cotação por lá em poucos cliques.</p>
                                <div class="vivo-landing-2024__timmer">
                                  <img class="rotating" src={LoaderWhite}/>
                                  <p>Em alguns instantes, você será redirecionado.</p>
                                </div>
                              </div>
                          </div>
                      </div>      
                  </section>

                </main>
                
      );

  }
}
export default Home_redirect;