import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import Logo5 from '../img/landing-2024/logo-5g.png';
import Icodi from '../img/landing-2024/logo-icodi.png';
import Telefonica from '../img/landing-2024/logo-telefonica.svg';
import '../css/landing-2024.css';


class Footer_redirect extends Component {

    render() {
        return (
            <footer id="footer">
                
                <section class="vivo-info">
                  <div class="container">
                    <div class="vivo-info_col">
                      <h2>Fale conosco</h2>
                      <a href="tel:+0800 001 03150">0800 001 0315</a>
                      <a href="#">Chat online</a>
                      <p class="small">Atendimento de segunda a sábado, das 08h às 20h, excetos feriados nacionais</p>
                    </div>
                    <div class="vivo-info_col">
                      <h2>Empréstimos</h2>
                      <a href="#">Empréstimo Pessoal</a>
                    </div>
                    <div class="vivo-info_col">
                      <h2>Outros serviços</h2>
                      <a target="_blank" href="https://vivopay.com.br/seguro-celular">Seguro Celular</a>
                      <a target="_blank" href="https://appstore.vivo.com.br/sc/br/vivostore/detalle/vivo-credito-antecipado/58136/1992">Crédito Antecipado</a>
                      <a target="_blank" href="https://consorcio.vivo.com.br">Compra Planejada</a>
                    </div>
                    <div class="vivo-info_col">
                      <h2>Mais informações</h2>
                      <a href="#">Nossos Termos e Condições</a>
                      <a href="#">Promoção Especial</a>
                    </div>
                  </div>
                </section>
                

                <section class="row vivo-legales">
                  <div class="col-xs-12">
                    <div class="container">
                      <div class="row row-text">
                        <div class="col-xs-12">
                          <p class="big">Telefônica Brasil S.A CNPJ: 02.558.157/0001- 62. Copyrights © Vivo. Todos os direitos reservados.</p>
                          <p>Seguro garantido pela Zurich Minas Brasil Seguros S.A, CNPJ 17.197.385/0001-21 – Código SUSEP: 05495, Processo SUSEP 15414.005122/2011- 69 (Roubo e Furto Qualificado) e 15.414.005123-2011/11 (Danos Materiais). Consulte a íntegra das Condições Gerais do Seguro no site <a href="https://www.zurich.com.br/" target="_blank">www.zurich.com.br</a>. O registro do produto é automático e não representa aprovação ou recomendação por parte da Susep. Central de atendimento Zurich: SAC: 0800 284 4848, Deficiente Auditivo: 0800 275 8585; Ouvidoria: 0800 770 1061. Link da plataforma digital oficial para registro de reclamações dos consumidores dos mercados supervisionados (<a href="https://www.consumidor.gov.br/pages/principal/?1720094878345" target="_blank">www.consumidor.gov.br)</a>.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section class="row vivo-logos">
                  <div class="col-xs-12">
                    <div class="container">
                      <div class="vivo-logos__lista">
                          <img src={Telefonica}/>
                          <img src={Icodi}/>
                          <img src={Logo5}/>
                      </div>
                    </div>
                  </div>
                </section>
              
            </footer>
        )
    }

    
}

export default withRouter(Footer_redirect);