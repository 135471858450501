function rpc(operation) {
    const result = fetchUrl('JsonService', {
        method: 'POST',
        body: toJson(operation),
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
            Accept: 'application/json',
            'Cache-Control': 'no-cache'
        }
    }).then((response) => {
        return response.text();
    }).then((responseText) => {
        const opResult = fromJson(responseText);
        return opResult;
    }, (error) => {
        throw error;
    });
    return result;
}

function rpcWithParams(operation, params) {
    const result = fetchUrl('JsonService', {
        method: 'POST',
        body: toJson(operation),
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
            Accept: 'application/json',
            'Cache-Control': 'no-cache'
        }
    }).then((response) => {
        return response.text();
    }).then((responseText) => {
        const opResult = fromJson(responseText);
        return {result: opResult, params};
    }, (error) => {
        throw error;
    });
    return result;
}

/*******************************************************************************
 * Fetch URL
 * See: https://developer.mozilla.org/es/docs/Web/API/GlobalFetch/fetch
 * It don't allow one parameter version
 */

function fetchUrl(url, config = {}) {
    let finalUrl = url;
    const isRelativeUrl = !(/^(\/\/|http:|https:).*/.test(url));
    if (isRelativeUrl) {
        if (window._ourServerUrl) { // eslint-disable-line no-underscore-dangle
            finalUrl = window._ourServerUrl + url; // eslint-disable-line no-underscore-dangle
        }
        if (!config.credentials) {
            config.credentials = 'same-origin';
        }

        finalUrl = process.env.PUBLIC_URL + '/' + url;
    }

    const request = new Request(finalUrl, config);

    if (isRelativeUrl) {
        // Only add extra headers for relative url
        const headers = request.headers;
        if (!headers.has('X-CURRENT')) {
            headers.set('X-CURRENT', '@' + btoa(encodeURI(window.location.href)) + '!');
        }
        if (!headers.has('X-XSRF-TOKEN')) {
            const xsrfCookieValue = getCookie('XSRF-TOKEN');
            if  (xsrfCookieValue) {
                headers.set('X-XSRF-TOKEN', xsrfCookieValue);
            }
        }
    }

    const result = fetch(request).then((response) => {
        // status "0" to handle local files fetching (e.g. Cordova/Phonegap etc.)
        if (response.status === 200 || response.status === 0) {
            return response;
        }
        const error = new TypeError('Fail executing a RPC request: unaceptable response code ' + response.status  + ' (' + response.statusText + ')');
        error.response = response;
        throw error;
    }, (error) => {
        throw error;
    });
    return result;
}

/*******************************************************************************
 * toJson & fromJson
 */

function filterKeys(key, value) {
    if (key && key[0] === '$') {
        return undefined;
    }
    return value;
}

function toJson(value) {
    const result = JSON.stringify(value, filterKeys);
    return result;
}

// https://weblog.west-wind.com/posts/2014/jan/06/javascript-json-date-parsing-and-real-dates
const dateFormatISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;
function dateParser(key, value) {
    if (typeof value === 'string') {
        if (dateFormatISO.test(value)) {
            return new Date(value);
        }
    }
    return value;
}

function fromJson(str = '') {
    const PROTECTION_PREFIX = /^\)\]\}',?\n/;
    let result = str.replace(PROTECTION_PREFIX, '');
    result = JSON.parse(result, dateParser);
    return result;
}

/*******************************************************************************
 * Utils
 */

function getCookie(cname) {
    const name = cname + '=';
    const ca = document.cookie.split(';');
    for (let i = 0, length = ca.length; i < length; i++) {
        let c = ca[i];
        while (c.charAt(0)===' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return null;
}

/*******************************************************************************
 * Exports
 */

// rpc(operation);
rpc.fetch = fetchUrl/* (url, config) */;
rpc.rpcWithParams = rpcWithParams/* (url, config) */;
rpc.toJson = toJson/* (object) */;
rpc.fromJson = fromJson/* (str) */;

export default rpc;
