import RenderInBody from '../renderInBody';
const React = require('react');

class ContinuarCancelamentoPopUp extends React.Component {

    render() {
        return (
            <RenderInBody component={<div className='u_dialog_container'><div className='u-dialog u-vertical'>
                <div className="modal-header d-flex">
                    <div className="col-xs-11 nopadding ds-vertical-align">
                        <h5 className="modal-title">{this.props.title}</h5>
                    </div>
                    <div className="col-xs-1 nopadding ds-vertical-align">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" tabIndex="0">
                            <span aria-hidden="true" onClick={this.props.close}>×</span>
                        </button>
                    </div>
                </div>
                <div className="modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <p className="vivo-color bold">{this.props.message}</p>
                            <input type="submit" name="ctl00$ContentPlaceHolder1$btnclose" onClick={this.props.close} value="Manter o seguro ativo" id="ContentPlaceHolder1_btnclose" className="btn btn-vivo btn-roxo" tabIndex="0" />
                            <input type="submit" name="ctl00$ContentPlaceHolder1$btnAvancar1" onClick={this.submit} value="Seguir com o cancelamento do seguro" id="ContentPlaceHolder1_btnAvancar1" className="btn btn-vivo btn-roxo" style={{ float: 'right' }} tabIndex="0" />
                        </div>
                    </div>
                </div>
            </div></div>} />
        );
    }

    submit = () => {
        this.props.resolve(true);
    }

}

export default ContinuarCancelamentoPopUp;