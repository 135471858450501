import React, { Component } from 'react';
import IcoContacto from '../img/ico_contacto_new.svg';
import IcoHorario from '../img/ico_horario_new.svg';
import IcoWeb from '../img/ico_web_new.svg';

export class ComoUsarOSeguro_new extends Component {

    componentDidMount(){
        window.s = window.s || {};
        window.s.pageName = 'vivo:br:seguro movil:como usar o seguro';
        window.s.products = 'vivo:br:seguro movil:';
        
        window.dataLayer.push ({
            'event' : 'asyncGTM.step',
            'GAcategory' : 'vivo:br:seguro movil',
            'GAaction' : 'paso:01:como usar o seguro:acceso',
            'GAlabel' :'vivo:br:seguro movil:'});
    }
    render() {
        return (
            <main>
                <section className="row vivo-interior-usar">
                    <div className="col-xs-12">
                        <div className="container">
                            <div className="row row-text">
                                <div className="col-xs-12">
                                    <h2>Aviso de Sinistro</h2>
                                </div>
                            </div>
                            <div className="row row-usar">
                                <div className="col col-lg-4 col-sm-6 col-xs-12">
                                    <img src={IcoContacto}/>
                                    <h3>Para todas as coberturas:</h3>
                                    <ul className="check verde">
                                        <li><a href="tel:0800 580 0277" onClick={this.onClickPhoneNumber} target="_blank">0800-580-0277</a></li>
                                        <li>WhatsApp: <a href="tel:(11) 97287-8201" onClick={this.onClickMovilePhoneNumber} target="_blank">(11) 97287-8201</a></li>
                                    </ul>
                                </div>
                                <div className="col col-lg-4 col-sm-6 col-xs-12"> 
                                    <img src={IcoHorario}/>
                                    <h3>Horário:</h3>
                                    <p>De segunda à sexta das 8h às 20h<br/>
                                    Aos sábados: das 8h às 15h</p>
                                </div>
                                <div className="col col-lg-4 col-sm-12 col-xs-12"> 
                                    <img src={IcoWeb}/>
                                    <h3>Em nosso site:</h3>
                                    <p>A abertura do sinistro também pode ser feita digitalmente, de forma rápida e prática, pelo site Vivo Seguro Celular. Basta acessar a Área do Cliente, selecionar Abrir Sinistro e preencher os campos solicitados.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
    
    onClickPhoneNumber = () =>{
        window.dataLayer.push ({
            'event' : 'asyncGTM',
            'GAcategory' : 'vivo:br:seguro movil',
            'GAaction' : 'como usar o seguro:telefono',
            'GAlabel' :'vivo:br:seguro movil:'});
    }

    onClickMovilePhoneNumber = () =>{
        window.dataLayer.push ({
            'event' : 'asyncGTM',
            'GAcategory' : 'vivo:br:seguro movil', 
            'GAaction' : 'como usar o seguro:whatsapp',
            'GAlabel' :'vivo:br:seguro movil:'});
    }
}

export default ComoUsarOSeguro_new;