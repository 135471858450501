import React, { PureComponent } from 'react';
import { Redirect} from 'react-router-dom';
import rpc, { rpcWithParams }  from './our/rpc';

export default class ActivacaoChipRedirect extends PureComponent {

    componentDidMount(){
        this.props.onChangeOffer(4);
         const value = {
            dateOcurrence: new Date(),
            pageVisited: 'Entra por la parte de ActivacaoChip',
            idOffert: 4
        };
        rpc({
            type: 'SaveContrationStepsHistory',
            value
        }).then((result) => {
            this.props.onChangeContratationStep(result);
            console.log(result);
        });
    }

    render() {
        return (
            <Redirect to="/Contratation" />
        )
    }
}
